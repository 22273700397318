/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";

// Define obviously wrong colors by default so we immediately catch a mistake
// where a theme isn't applied.
export const colors = stylex.defineVars({
  /* Main */
  backgroundColor: "lime",
  backgroundEmphasisColor: "lime",
  backgroundPlaceholderColor: "lime",
  borderColor: "lime",
  buttonBackgroundColor: "lime",
  buttonColor: "lime",
  buttonEmphasisBackgroundColor: "lime",
  buttonEmphasisColor: "lime",
  color: "lime",
  colorEmphasis: "lime",
  colorMuted: "lime",
  errorBackgroundColor: "lime",
  errorColor: "lime",
  favoriteIconBackgroundColor: "lime",
  favoriteIconBorderColor: "lime",
  favoriteIconSelectedColor: "lime",
  favoriteIconUnselectedColor: "lime",
  headlessImageBackgroundColor: "lime",
  horizontalRuleColor: "lime",
  infoBackgroundColor: "lime",
  linkColor: "lime",
  linkHoverColor: "lime",
  metaBorderColor: "lime",
  modalBackdropBackgroundColor: "lime",
  modalBackgroundColor: "lime",
  modalBorderColor: "lime",
  outline: "2px solid lime",
  successBackgroundColor: "lime",
  successColor: "lime",

  /* Auctions */
  auctionWinningColor: "lime",

  /* Auction Tiles */
  auctionTileDarkColor: "lime",
  auctionTileLightColor: "lime",
  auctionTileStatusBackgroundColor: "lime",
  auctionTileStatusColor: "lime",
  auctionTileStatusDotClosedColor: "lime",
  auctionTileStatusDotOpenColor: "lime",
  auctionTileStatusDotPreviewColor: "lime",

  /* Banners */
  bannerDisabled: "lime",
  bannerError: "lime",
  bannerSuccess: "lime",
  bannerText: "lime",
  bannerWarning: "lime",

  /* Carousel */
  carouselBackgroundColor: "lime",
  carouselColor: "lime",
  carouselDotBorderColor: "lime",
  carouselDotColor: "lime",
  carouselDotCurrentColor: "lime",

  /* Charts */
  chartBackgroundColor: "lime",
  chartBorderColor: "lime",
  chartColor: "lime",
  chartGridLineColor: "lime",
  chartLineColor: "lime",

  /** Feedback */
  feedbackNegative: "lime",
  feedbackPositive: "lime",

  /* FMV ranks */
  fmvRankDefaultBackgroundColor: "lime",
  fmvRankDefaultColor: "lime",
  fmvRankGoodBackgroundColor: "lime",
  fmvRankGoodColor: "lime",
  fmvRankGreatBackgroundColor: "lime",
  fmvRankGreatColor: "lime",
  fmvRankPoorBackgroundColor: "lime",
  fmvRankPoorColor: "lime",

  /* Footer */
  footerBackgroundColor: "lime",
  footerBorderColor: "lime",
  footerBottomLinkColor: "lime",
  footerColor: "lime",
  footerEmphasisColor: "lime",

  /* Home carousel */
  homeCarouselBackgroundColor: "lime",
  homeCarouselColor: "lime",

  /* Inputs */
  inputTextBackgroundColor: "lime",
  inputTextBorderColor: "lime",
  inputTextColor: "lime",
  inputTextIconColor: "lime",
  inputTextOptionBorderColor: "lime",
  inputTextOutline: "2px solid lime",
  inputTextPlaceholderColor: "lime",

  /* Navigation */
  navBorderColor: "lime",
  navBorderCurrentColor: "lime",
  navLinkColor: "lime",
  navLinkCurrentColor: "lime",
  navLinkHoverColor: "lime",

  /* Order Status Tracking */
  orderPayoutStatusFailed: "lime",
  orderPayoutStatusPaid: "lime",
  orderPayoutStatusPending: "lime",
  orderPayoutStatusProcessing: "lime",
  orderPayoutStatusUnpaid: "lime",
  orderStatus: "lime",
  orderStatusCanceled: "lime",
  orderStatusDelivered: "lime",
  orderStatusDeliveredSeller: "lime",
  orderStatusDeliveryException: "lime",
  orderStatusFailed: "lime",
  orderStatusFeedbackRequested: "lime",
  orderStatusInTransit: "lime",
  orderStatusProcessing: "lime",
  orderStatusRefunded: "lime",
  orderStatusUnavailable: "lime",

  /* Sign up */
  signUpLogo: "url('https://www-cdn.shortboxed.com/sbx-logo-black.webp')",

  /* Social icons */
  socialFacebook: "url('https://www-cdn.shortboxed.com/social-fb.webp')",
  socialInstagram: "url('https://www-cdn.shortboxed.com/social-insta.webp')",
  socialX: "url('https://www-cdn.shortboxed.com/social-x.webp')",

  /* SSO buttons */
  ssoAppleBackgroundColor: "lime",
  ssoAppleBorderColor: "lime",
  ssoAppleColor: "lime",
  ssoFacebookBackgroundColor: "lime",
  ssoFacebookBorderColor: "lime",
  ssoFacebookColor: "lime",
  ssoGoogleBackgroundColor: "lime",
  ssoGoogleBorderColor: "lime",
  ssoGoogleColor: "lime",

  /* Tables */
  tableBackgroundColor: "lime",
  tableBorderColor: "lime",
  tableColor: "lime",
  tableEvenBackgroundColor: "lime",
  tableFooterColor: "lime",
  tableOddBackgroundColor: "lime",

  /* Takeovers */
  takeoverBackgroundColor: "lime",
  takeoverBorderColor: "lime",
  takeoverButtonBackgroundColor: "lime",
  takeoverButtonColor: "lime",
  takeoverColor: "lime",
  takeoverMutedColor: "lime",

  /* Tabs */
  tabSelectedBackgroundColor: "lime",
  tabSelectedColor: "lime",

  /* Top navigation */
  topNavigationBackgroundColor: "lime",
  topNavigationColor: "lime",
  topNavigationIconColor: "lime",
  topNavigationLogo: "url('https://www-cdn.shortboxed.com/logo.webp')",
  topNavigationLogoSquare:
    "url('https://www-cdn.shortboxed.com/logo-box.webp')",
  topNavigationMenuBackgroundColor: "lime",
  topNavigationMenuBorderColor: "lime",
  topNavigationMenuColor: "lime",
  topNavigationMenuDotColor: "lime",
  topNavigationMenuProfileBackgroundColor: "lime",
  topNavigationMenuProfileColor: "lime",
  topNavigationMenuShadow: "0 4px 4px lime",
  topNavigationSearchBackgroundColor: "lime",
  topNavigationSearchBorderColor: "lime",
  topNavigationSearchBorderFocusedColor: "lime",
  topNavigationSearchButtonBackgroundColor: "lime",
  topNavigationSearchButtonColor: "lime",
  topNavigationSearchButtonColorMobile: "lime",
  topNavigationSearchClearColor: "lime",
  topNavigationSearchColor: "lime",
  topNavigationSearchPlaceholderColor: "lime",
  topNavigationSearchShadow: "0 4px 4px lime",
  topNavigationShadow: "0 0 4px lime",
  topNavigationTabActiveBackgroundColor: "lime",
  topNavigationTabActiveColor: "lime",
  topNavigationTabBackgroundColor: "lime",
  topNavigationTabColor: "lime",
  topNavigationTabHoverBackgroundColor: "lime",
  topNavigationTabHoverColor: "lime",

  /* Watch icons */
  watchIconSelectedColor: "lime",
  watchIconUnselectedColor: "lime",

  /* Portfolio Chart Snapshot */
  portfolioChartSnapshotNegative: "lime",
  portfolioChartSnapshotPositive: "lime",
});
