/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReactNode } from "react";
import type { AddressFormOnSubmit } from "src/app/components/address-form/AddressForm";
import type { RequestSellerAccessAddress_user$key } from "src/types/__generated__/RequestSellerAccessAddress_user.graphql";
import type { AddressType } from "src/types/__generated__/RequestSellerAccessModalCreateAddressMutation.graphql";
import type { Address } from "src/types/Address";

import * as React from "react";
import { graphql, useFragment } from "react-relay";

import AddressForm from "src/app/components/address-form/AddressForm";
const ADDRESS_TYPE: AddressType = "SHIP_FROM";

const INITIAL_ADDRESS: Address = {
  address1: "",
  address2: null,
  addressId: null,
  addressName: "",
  country: "US",
  familyName: "",
  givenName: "",
  isDefault: true,
  locality: "",
  postalCode: "",
  region: "",
  type: ADDRESS_TYPE,
};

type Props = Readonly<{
  loading: boolean;
  onSubmit?: AddressFormOnSubmit;
  queryKey: RequestSellerAccessAddress_user$key;
}>;

const RequestSellerAccessAddress = ({
  loading,
  onSubmit,
  queryKey,
}: Props): ReactNode => {
  const data = useFragment(
    graphql`
      fragment RequestSellerAccessAddress_user on User {
        __id
        addresses(
          first: 1
          orderBy: { isDefault: DESC }
          where: { type: SHIP_FROM, isDefault: true }
        ) {
          edges {
            node {
              id
              name
              givenName
              familyName
              address1
              address2
              locality
              region
              postalCode
              country
            }
          }
        }
      }
    `,
    queryKey,
  );

  const address = data?.addresses?.edges?.[0]?.node;

  const existingAddress =
    address == null
      ? INITIAL_ADDRESS
      : {
          address1: address.address1 ?? "",
          address2: address.address2 ?? "",
          addressId: address.id,
          addressName: address.name ?? "",
          country: address.country ?? "US",
          familyName: address.familyName ?? "",
          givenName: address.givenName ?? "",
          isDefault: true,
          locality: address.locality ?? "",
          postalCode: address.postalCode ?? "",
          region: address.region ?? "",
          type: ADDRESS_TYPE,
        };

  return (
    <AddressForm
      canSelectCountry={false}
      initialAddress={existingAddress}
      loading={loading}
      onSubmit={onSubmit}
    />
  );
};

export default RequestSellerAccessAddress;
