/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { RelatedProducts_relatedProducts$key } from "src/types/__generated__/RelatedProducts_relatedProducts.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { useFavorite, useUnfavorite } from "src/hooks";
import { HeadingLevel, SBHeading, SBProductTile } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: RelatedProducts_relatedProducts$key;
}>;

const RelatedProducts = ({ queryKey }: Props): React.ReactNode | null => {
  const data = useFragment(
    graphql`
      fragment RelatedProducts_relatedProducts on Query {
        relatedProducts(productId: $productId, first: 4) {
          edges {
            node {
              id
              hasViewerFavorited
              isViewerOwner
              fmvRaw {
                fmvMaxValue
                fmvMinValue
              }
              fmvScore {
                rank
              }
              comicDetails {
                title
                number
                grade
                gradingAuthority
              }
              images {
                edges {
                  node {
                    url(quality: 100, webp: true, width: 500)
                  }
                }
              }
              price
              currency
            }
          }
        }
      }
    `,
    queryKey,
  );

  const relatedProducts = data?.relatedProducts?.edges ?? [];

  const [commitFavorite, isInFlightFavorite] = useFavorite();
  const [commitUnfavorite, isInFlightUnfavorite] = useUnfavorite();

  const { t } = useTranslation();

  const handleClickFavorite =
    (id: string, hasViewerFavorited: boolean) => () => {
      if (isInFlightFavorite || isInFlightUnfavorite) {
        return;
      }
      const variables = {
        input: {
          productId: id,
        },
      };
      hasViewerFavorited
        ? commitUnfavorite({
            optimisticResponse: {
              unfavorite: {
                hasViewerFavorited: false,
                id,
              },
            },
            variables,
          })
        : commitFavorite({
            optimisticResponse: {
              favorite: {
                hasViewerFavorited: true,
                id,
              },
            },
            variables,
          });
    };

  if (relatedProducts.length === 0) {
    return null;
  }

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("product.related-products")}
      </SBHeading>
      <div {...stylex.props(styles.root)}>
        {relatedProducts.map(({ node }) => {
          const {
            id,
            currency,
            images,
            price,
            comicDetails,
            hasViewerFavorited,
            isViewerOwner,
            fmvRaw,
            fmvScore,
          } = node;
          const { number, title, grade, gradingAuthority } = comicDetails ?? {};
          const imageUrl = images?.edges?.[0]?.node?.url ?? "";

          return (
            <SBProductTile
              key={id}
              currency={currency}
              favorite={hasViewerFavorited}
              favoriteLoading={isInFlightFavorite || isInFlightUnfavorite}
              fmvMaxValue={fmvRaw?.fmvMaxValue}
              fmvMinValue={fmvRaw?.fmvMinValue}
              fmvRank={fmvScore?.rank}
              grade={grade ?? 0}
              gradingAuthority={gradingAuthority ?? "CGC"}
              imageUrl={imageUrl}
              isViewerOwner={isViewerOwner}
              number={number ?? ""}
              price={price}
              productId={id}
              salesChannel="SHOP"
              title={title ?? ""}
              onClickFavorite={handleClickFavorite(id, hasViewerFavorited)}
            />
          );
        })}
      </div>
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 32,
    marginTop: 48,
  },
  root: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(4, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginTop: 24,
  },
});

export default RelatedProducts;
