/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { RemoveFromAuctionView_product$key } from "src/types/__generated__/RemoveFromAuctionView_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBErrorMessage,
  SBHeading,
  SBParagraph,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: RemoveFromAuctionView_product$key;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const RemoveFromAuctionView = ({
  queryKey,
  setOpen,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const longDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const [commit, isInFlight] = useMutation(graphql`
    mutation RemoveFromAuctionViewMutation(
      $input: DeleteCommunityAuctionLotProductIdInput!
    ) {
      deleteCommunityAuctionLot(deleteCommunityAuctionLotInput: $input) {
        ...ProductDetailView_product
        ...RemoveFromAuctionView_product
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment RemoveFromAuctionView_product on Product {
        id
        auctionLot {
          auction {
            title
            tagline
            biddingStartsAt
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data?.id;

  const auctionTitle = data?.auctionLot?.auction?.title;
  const auctionTagline = data?.auctionLot?.auction?.tagline;
  const biddingStartsAt = data?.auctionLot?.auction?.biddingStartsAt;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClickRemove = (_event: React.SyntheticEvent) => {
    if (isInFlight) {
      return;
    }
    if (productId == null) {
      return;
    }
    commit({
      onCompleted() {
        setOpen(false);
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          productId,
        },
      },
    });
  };

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(errorMessage != null && styles.errorMessage)}>
        <SBErrorMessage message={errorMessage} />
      </div>
      <div {...stylex.props(styles.row)}>
        <SBHeading level={HeadingLevel.H2} style={styles.label}>
          {t("send-to-auction-modal.select-auction.title")}
        </SBHeading>
        <div {...stylex.props(styles.value)}>
          <SBParagraph style={styles.auctionTitle}>{auctionTitle}</SBParagraph>
          {Boolean(auctionTagline) ? (
            <SBParagraph style={styles.tagline}>{auctionTagline}</SBParagraph>
          ) : null}
          {biddingStartsAt != null && (
            <SBParagraph style={styles.biddingStartsAt}>
              {t("send-to-auction-modal.send.bidding-starts", {
                date: longDateFormatter.format(
                  Number.parseInt(biddingStartsAt, 10),
                ),
              })}
            </SBParagraph>
          )}
        </div>
      </div>
      <SBParagraph style={styles.disclaimer}>
        {t("remove-from-auction-modal.disclaimer")}
      </SBParagraph>
      <div {...stylex.props(styles.buttons)}>
        <SBButton
          block={true}
          loading={isInFlight}
          title={t("remove-from-auction-modal.buttons.remove")}
          variation={ButtonVariation.Emphasis}
          onClick={handleClickRemove}
        />
      </div>
    </div>
  );
};

const styles = stylex.create({
  auctionTitle: {
    fontWeight: "bold",
    marginBottom: 4,
    textAlign: "left",
  },
  biddingStartsAt: {
    color: colors.colorMuted,
    fontSize: 14,
    marginBottom: 0,
    textAlign: "left",
  },
  buttons: {
    boxSizing: "border-box",
    padding: 16,
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  disclaimer: {
    color: colors.colorMuted,
    fontSize: 14,
    marginBottom: 0,
    paddingInline: 16,
  },
  errorMessage: {
    boxSizing: "border-box",
    marginTop: 16,
    paddingInline: 16,
    width: "100%",
  },
  label: {
    fontSize: 28,
    marginRight: 8,
    width: "25%",
  },
  row: {
    alignItems: "flex-start",
    borderBottomColor: colors.borderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderTopColor: colors.borderColor,
    borderTopStyle: "solid",
    borderTopWidth: 0,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    padding: 16,
    width: "100%",
  },
  tagline: {
    marginBottom: 4,
    textAlign: "left",
  },
  value: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

export default RemoveFromAuctionView;
