/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesHomeQuery } from "src/types/__generated__/QueriesHomeQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { SHOP_PATH } from "src/app/router/Router";
import {
  HeadingLevel,
  SBButton,
  SBErrorBoundary,
  SBFallback,
  SBHeading,
} from "src/sbxui";

import HomeContent from "./HomeContent";

const Home = (): React.ReactNode => {
  const { t } = useTranslation();

  const pageContext = useContext(PageContext);

  const queryRef = useLoaderData() as PreloadedQuery<QueriesHomeQuery>;

  useEffect(() => {
    pageContext?.setTitle(t("home.document-title"));
  }, [t, pageContext]);

  if (queryRef == null) {
    return null;
  }

  return (
    <div {...stylex.props(styles.root)}>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <HomeContent queryRef={queryRef} />
          <div {...stylex.props(styles.showMore)}>
            <SBHeading level={HeadingLevel.H3} style={styles.showMoreHeading}>
              {t("home.explore-more")}
            </SBHeading>
            <SBButton title={t("home.show-more-button")} to={SHOP_PATH} />
          </div>
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

const styles = stylex.create({
  root: {
    height: "100%",
    minHeight: 400,
  },
  showMore: {
    marginBottom: 96,
    marginTop: 32,
    textAlign: "center",
  },
  showMoreHeading: {
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 32,
    lineHeight: 0.875,
    margin: 0,
    marginBottom: 32,
    textTransform: "uppercase",
  },
});

export default Home;
