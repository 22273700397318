/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { SBActivityIndicator } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

export enum TableCellAlignment {
  End,
  Start,
  Middle,
}

export enum TableSortOrder {
  Ascending = "ASC",
  Descending = "DESC",
}

export interface TableHeader {
  align?: TableCellAlignment;
  hidden?: boolean;
  // Whether or not the column is hidden on mobile
  id: string;
  isSortedBy?: boolean;
  label: React.ReactNode;
  onClickSort?: (event: React.SyntheticEvent) => void;
  sort?: TableSortOrder;
}

export interface TableDataCell {
  align?: TableCellAlignment;
  bgColor?: StyleXStyles;
  bold?: boolean;
  hidden?: boolean;
  id: string;
  label: React.ReactNode;
  mobile?: DataList[];
  noRowClick?: boolean;
  // Alternate presentation for the mobile table
  numbers?: boolean;
  primary?: boolean;
}

interface DataList {
  dd: React.ReactNode;
  dt: React.ReactNode;
  id: string;
  numbers?: boolean;
}

export interface TableConfig {
  columns: TableHeader[];
  rowClick?: (id: string) => void;
  showHeader?: boolean;
}

type Props = Readonly<{
  compact?: boolean;
  config: TableConfig;
  loading?: boolean;
  rows: (React.ReactNode | null)[];
  style?: StyleXStyles;
}>;

export const SBTable = React.forwardRef<HTMLDivElement | null, Props>(
  (
    { style, config, loading = false, rows, compact = false },
    ref,
  ): React.ReactNode => {
    const { columns, showHeader = true } = config;

    return (
      <>
        <div {...stylex.props(styles.root, style)}>
          <table {...stylex.props(styles.table)}>
            {showHeader ? (
              <thead {...stylex.props(styles.thead)}>
                <tr {...stylex.props(styles.row)}>
                  {columns.map((th, ii) => (
                    <th
                      key={th.id}
                      {...stylex.props(
                        styles.th,
                        compact && styles.compact,
                        th.hidden && styles.thHidden,
                        th.align === TableCellAlignment.Middle
                          ? styles.alignMiddle
                          : TableCellAlignment.End
                            ? styles.alignRight
                            : styles.alignLeft,
                        ii === 0 ? styles.firstColumn : styles.innerColumns,
                        // didn't port this to stylex yet...
                        // ii + 1 === columns.length ? "pr-4 sm:pr-6" : "pr-3"
                      )}
                      scope="col"
                    >
                      {th.label}
                    </th>
                  ))}
                </tr>
              </thead>
            ) : null}
            <tbody {...stylex.props(styles.tbody)}>
              {rows.filter(Boolean).map((tr) => tr)}
            </tbody>
          </table>
        </div>
        <div ref={ref} {...stylex.props(styles.loader)}>
          {loading ? <SBActivityIndicator /> : null}
        </div>
      </>
    );
  },
);

const styles = stylex.create({
  alignLeft: {
    textAlign: "left",
  },
  alignMiddle: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
  compact: {
    padding: 2,
    // text-xs
  },
  firstColumn: {
    paddingLeft: 4,
  },
  innerColumns: {
    paddingBlock: "0",
    paddingInline: "3px",
  },
  loader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingBlock: "6px",
    paddingInline: "0",
  },
  root: {
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  },
  row: {
    alignItems: "center",
    backgroundColor: colors.tableOddBackgroundColor,
    borderBottomWidth: 0,
    borderColor: colors.tableBorderColor,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderTopWidth: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "space-between",
    paddingBlock: 12,
    paddingInline: 16,
  },
  table: {
    borderColor: colors.tableBorderColor,
    borderStyle: "solid",
    borderWidth: 0,
    display: "block",
  },
  tbody: {
    borderBottomWidth: 0,
    borderColor: colors.tableBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  th: {
    color: "rgb(17 24 39)",
    paddingBlock: "3px",
    paddingInline: "0",
  },
  thHidden: {
    display: "table-cell",
  },
  thead: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});
