/**
 * @generated SignedSource<<a33652a4a26e1a496ad2516af3112f60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendToAuctionView_auctionSearch$data = {
  readonly auctionSearch: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly biddingStartsAt: string;
        readonly id: string;
        readonly tagline: string | null | undefined;
        readonly title: string;
        readonly userAuctionStatus: {
          readonly submittable: boolean;
        } | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "SendToAuctionView_auctionSearch";
};
export type SendToAuctionView_auctionSearch$key = {
  readonly " $data"?: SendToAuctionView_auctionSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendToAuctionView_auctionSearch">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "auctionSearch"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./SendToAuctionViewPaginationQuery.graphql')
    }
  },
  "name": "SendToAuctionView_auctionSearch",
  "selections": [
    {
      "alias": "auctionSearch",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "filter": "OPEN_SUBMISSIONS",
            "type": [
              "COMMUNITY"
            ]
          }
        }
      ],
      "concreteType": "AuctionSearchConnection",
      "kind": "LinkedField",
      "name": "__SendToAuctionView_auctionSearch_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuctionSearchEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Auction",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tagline",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "biddingStartsAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UserAuctionStatusOutput",
                  "kind": "LinkedField",
                  "name": "userAuctionStatus",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "submittable",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__SendToAuctionView_auctionSearch_connection(where:{\"filter\":\"OPEN_SUBMISSIONS\",\"type\":[\"COMMUNITY\"]})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9895a8c2504b22e4f294b13116294fb9";

export default node;
