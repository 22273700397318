/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  PortfolioItem_product$key,
  ProductPublicStatus,
  SalesChannel,
} from "src/types/__generated__/PortfolioItem_product.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";

import PortfolioChartSnapshot from "src/app/components/portfolio-chart-snapshot/PortfolioChartSnapshot";
import { PRODUCT_DETAIL_PATH } from "src/app/router/Router";
import { SBFmvDot, SBIcon, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatGrade, formatMoney, formatTitle } from "src/utils";

const MOBILE = "@media (max-width: 767px)";

const PORTFOLIO_ITEM_HEIGHT = 80;
const PORTFOLIO_ITEM_HEIGHT_MOBILE = 40;

type Props = Readonly<{
  apiUser: boolean;
  canListForSale: boolean;
  last: boolean;
  queryKey: PortfolioItem_product$key;
}>;

const PortfolioItem = ({
  apiUser,
  canListForSale,
  last,
  queryKey,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const shortDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        month: "numeric",
      }),
    [i18n.languages],
  );

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.languages),
    [i18n.languages],
  );

  const data = useFragment(
    graphql`
      fragment PortfolioItem_product on Product {
        id
        publicStatus
        salesChannel
        auctionLot {
          id
          bidCount
          currentBidAmount
          currentBidCurrency
          isClosed
          auction {
            id
            biddingStartsAt
          }
        }
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 500)
            }
          }
        }
        comicDetails {
          title
          number
          grade
          gradingAuthority
        }
        fmvScore {
          currency
          maxValue
          minValue
          rank
        }
        id
        price
        currency
        favoritesCount
        canSell
        historicalSales(first: 10, orderBy: { saleDate: DESC }) {
          edges {
            node {
              saleDate
              price
            }
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data?.id;
  const images = data?.images?.edges ?? [];
  const imageUrl = images[0]?.node?.url ?? "";

  const fmvMax = data?.fmvScore?.maxValue ?? 0;
  const fmvMin = data?.fmvScore?.minValue ?? 0;
  const fmvCurrency = data?.fmvScore?.currency;

  const title = data?.comicDetails?.title;
  const number = data?.comicDetails?.number;
  const grade = data?.comicDetails?.grade;
  const gradingAuthority = data?.comicDetails?.gradingAuthority;

  const name = formatTitle({ number, t, title });
  const gradeValue = formatGrade({ grade, gradingAuthority, t });

  const price = data?.price ?? 0;
  const currency = data?.currency;
  const fmvRank = data?.fmvScore?.rank;
  const productStatus: ProductPublicStatus =
    data?.publicStatus ?? "PENDING_AUCTION_CONSIGNMENT_REVIEW";
  const salesChannel: SalesChannel = data?.salesChannel ?? "SHOP";

  const bidCount = data?.auctionLot?.bidCount ?? 0;
  const currentBidAmount = data?.auctionLot?.currentBidAmount ?? 0;
  const currentBidCurrency = data?.auctionLot?.currentBidCurrency;
  const isAuctionLotClosed = data?.auctionLot?.isClosed ?? false;
  const biddingStartsAt = data?.auctionLot?.auction?.biddingStartsAt;

  const historicalSales = data.historicalSales;

  const isPreview =
    biddingStartsAt == null
      ? true
      : Date.now() - Number.parseInt(biddingStartsAt, 10) <= 0;

  const favoritesCount = data?.favoritesCount ?? 0;

  const canSellItem = data?.canSell ?? false;

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setOpacity(1);
    };
    img.src = imageUrl;
  }, [imageUrl]);

  let actions = null;
  if (canListForSale) {
    switch (productStatus) {
      case "PENDING_AUCTION_CONSIGNMENT_REVIEW":
      case "PENDING_AUCTION_CONSIGNMENT_SHIPPING": {
        actions = (
          <div {...stylex.props(styles.actions)}>
            <div {...stylex.props(styles.pending)}>
              <div {...stylex.props(auto, styles.text)}>
                {t(
                  `portfolio.inventory-item.buttons.${kebabCase(productStatus)}`,
                )}
              </div>
            </div>
          </div>
        );
        break;
      }
      case "PENDING_AUCTION_START":
      case "PENDING_AUCTION_END": {
        const text = t(
          isAuctionLotClosed
            ? "portfolio.inventory-item.buttons.auction-closed"
            : `portfolio.inventory-item.buttons.${kebabCase(productStatus)}`,
        );
        let caption: string | null = t("portfolio.inventory-item.bids", {
          count: bidCount,
        });
        if (productStatus === "PENDING_AUCTION_START") {
          caption =
            biddingStartsAt == null
              ? null
              : shortDateFormatter.format(Number.parseInt(biddingStartsAt, 10));
        }
        actions = (
          <div {...stylex.props(styles.actions)}>
            <div {...stylex.props(styles.listPrice)}>
              <div {...stylex.props(auto, styles.text)}>{text}</div>
            </div>
            {caption == null ? null : (
              <div {...stylex.props(styles.bidCount)}>
                <div {...stylex.props(auto, styles.caption)}>{caption}</div>
              </div>
            )}
          </div>
        );
        break;
      }
      case "FOR_SALE": {
        actions = (
          <div {...stylex.props(styles.actions)}>
            <div {...stylex.props(styles.listPrice)}>
              <SBParagraph style={styles.money}>
                {formatMoney(price, currency, i18n.language)}
              </SBParagraph>
              {fmvRank != null && (
                <SBFmvDot rank={fmvRank} style={styles.fmvDot} />
              )}
            </div>
            {favoritesCount > 0 && (
              <div {...stylex.props(styles.favoritesCount)}>
                <SBIcon
                  icon="favorite"
                  size={20}
                  style={styles.favoritesIcon}
                />
                <SBParagraph style={styles.favorites}>
                  {numberFormatter.format(favoritesCount)}
                </SBParagraph>
              </div>
            )}
          </div>
        );
        break;
      }
      case "UNAVAILABLE": {
        actions =
          apiUser || !canSellItem ? null : (
            <div {...stylex.props(styles.actions)}>
              <div {...stylex.props(styles.listForSale)}>
                <div {...stylex.props(auto, styles.text)}>
                  {t(
                    `portfolio.inventory-item.buttons.${kebabCase(productStatus)}`,
                  )}
                </div>
                <SBIcon
                  containerStyle={styles.iconContainer}
                  icon="chevron_right"
                  size={20}
                  style={styles.icon}
                />
              </div>
            </div>
          );
        break;
      }
      case "PENDING_PURCHASE":
      case "PROCESSING_PURCHASE": {
        actions = (
          <div {...stylex.props(styles.actions)}>
            <div {...stylex.props(styles.pending)}>
              <div {...stylex.props(auto, styles.text)}>
                {t(
                  `portfolio.inventory-item.buttons.${kebabCase(productStatus)}`,
                )}
              </div>
            </div>
          </div>
        );
        break;
      }
      default:
        actions = null;
        break;
    }
  }

  return (
    <Link
      {...stylex.props(auto, styles.container, last && styles.containerLast)}
      rel="noopener noreferrer"
      target="_blank"
      to={PRODUCT_DETAIL_PATH.replace(":id", productId)}
    >
      <div {...stylex.props(auto, styles.image(opacity))} aria-hidden="true">
        <img
          {...stylex.props(styles.imageFile)}
          height="100%"
          src={imageUrl}
          width="auto"
        />
        <div {...stylex.props(auto, styles.imageSpacer)}></div>
      </div>
      <div {...stylex.props(auto, styles.summary)}>
        <div {...stylex.props(auto, styles.name)}>{name}</div>
        <SBParagraph style={styles.grade}>{gradeValue}</SBParagraph>
        {fmvMax > 0 && fmvMin > 0 && fmvCurrency != null && (
          <SBParagraph style={styles.fmv}>
            {t("portfolio.inventory-item.fmv", {
              max: formatMoney(fmvMax, fmvCurrency, i18n.language),
              min: formatMoney(fmvMin, fmvCurrency, i18n.language),
            })}
          </SBParagraph>
        )}
        {salesChannel === "AUCTION" &&
          !isPreview &&
          currentBidAmount > 0 &&
          currentBidCurrency != null && (
            <SBParagraph style={styles.bidStatus}>
              {t(
                `portfolio.inventory-item.${isAuctionLotClosed ? "closing" : "current"}-bid`,
                {
                  amount: formatMoney(
                    currentBidAmount,
                    currentBidCurrency,
                    i18n.language,
                  ),
                },
              )}
            </SBParagraph>
          )}
        <PortfolioChartSnapshot data={historicalSales} />
      </div>

      {actions}
    </Link>
  );
};

const styles = stylex.create({
  actions: {
    marginLeft: {
      [MOBILE]: 12,
      default: 24,
    },
  },
  bidCount: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 4,
  },
  bidStatus: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
    marginTop: 2,
  },
  caption: {
    color: colors.colorMuted,
    fontSize: 14,
  },
  container: {
    alignItems: "center",
    borderBottomColor: colors.borderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    marginInline: 8,
    paddingBottom: 16,
    textDecorationLine: "none",
  },
  containerLast: {
    marginBottom: 72,
  },
  favorites: {
    marginBottom: 0,
    marginLeft: 4,
  },
  favoritesCount: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  favoritesIcon: {
    color: colors.favoriteIconSelectedColor,
  },
  fmv: {
    color: colors.colorMuted,
    fontSize: 14,
    marginBottom: 0,
    marginTop: 4,
  },
  fmvDot: {
    marginLeft: 4,
    marginRight: 2,
  },
  grade: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
    marginTop: 2,
  },
  icon: {
    color: colors.color,
  },
  iconContainer: {
    marginLeft: 4,
  },
  image: (opacity: number) => ({
    alignItems: "center",
    backgroundColor: colors.backgroundPlaceholderColor,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderRadius: 8,
    display: "flex",
    height: {
      [MOBILE]: PORTFOLIO_ITEM_HEIGHT_MOBILE,
      default: PORTFOLIO_ITEM_HEIGHT,
    },
    justifyContent: "center",
    opacity,
    overflow: "hidden",
    position: "relative",
    transition: "opacity 250ms",
    width: {
      [MOBILE]: PORTFOLIO_ITEM_HEIGHT_MOBILE,
      default: PORTFOLIO_ITEM_HEIGHT,
    },
  }),
  imageFile: {
    display: "block",
    height: "100%",
    position: "absolute",
  },
  imageSpacer: {
    paddingBottom: "100%",
  },
  listForSale: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    justifyContent: "space-between",
    marginLeft: 12,
  },
  listPrice: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: -4,
    marginLeft: 12,
  },
  money: {
    fontSize: 16,
    marginBottom: 4,
  },
  name: {
    color: colors.color,
    fontFamily: "'Supreme-Regular', sans-serif",
    fontSize: 16,
  },
  pending: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
    justifyContent: "space-between",
    marginLeft: 12,
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginLeft: {
      [MOBILE]: 12,
      default: 24,
    },
    marginTop: 8,
  },
  text: {
    color: colors.color,
    fontSize: 16,
    paddingTop: 2,
  },
});

export default PortfolioItem;
