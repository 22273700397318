/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ProductDetailActionsOwnerAuction_product$key,
  ProductPublicStatus,
} from "src/types/__generated__/ProductDetailActionsOwnerAuction_product.graphql";

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import RemoveFromAuction from "src/app/components/remove-from-auction/RemoveFromAuction";
import { HeadingLevel, SBButton, SBHeading, SBParagraph } from "src/sbxui";

const AUCTION_STATUSES = [
  "PENDING_AUCTION_START",
  "PENDING_AUCTION_END",
  "PENDING_AUCTION_CONSIGNMENT_REVIEW",
  "PENDING_AUCTION_CONSIGNMENT_SHIPPING",
];

enum Modal {
  Remove,
  None,
}

type Props = Readonly<{
  employee: boolean;
  queryKey: ProductDetailActionsOwnerAuction_product$key;
}>;

const ProductDetailActionsOwnerAuction = ({
  queryKey,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsOwnerAuction_product on Product {
        id
        publicStatus
        isViewerApiUser
        auctionLot {
          auction {
            title
            tagline
            type
            biddingStartsAt
            biddingClosesAt
            lotSubmissionsLockAt
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data?.id;
  const publicStatus: ProductPublicStatus = data?.publicStatus ?? "UNAVAILABLE";

  const isViewerApiUser = data?.isViewerApiUser ?? false;

  const auctionTitle = data?.auctionLot?.auction?.title;
  const auctionTagline = data?.auctionLot?.auction?.tagline;
  const auctionType = data?.auctionLot?.auction?.type;

  const biddingStartsAt = data?.auctionLot?.auction?.biddingStartsAt;
  const startingTimestamp =
    biddingStartsAt == null ? null : Number.parseInt(biddingStartsAt, 10);

  const biddingClosesAt = data?.auctionLot?.auction?.biddingClosesAt;
  const closingTimestamp =
    biddingClosesAt == null ? null : Number.parseInt(biddingClosesAt, 10);

  const lotSubmissionsLockAt = data?.auctionLot?.auction?.lotSubmissionsLockAt;
  const lockedTimestamp =
    lotSubmissionsLockAt == null
      ? Date.now()
      : Number.parseInt(lotSubmissionsLockAt, 10);

  const canRemoveFromAuction =
    publicStatus === "PENDING_AUCTION_START" &&
    auctionType === "COMMUNITY" &&
    lockedTimestamp >= Date.now();

  const [modal, setModal] = useState<Modal>(Modal.None);

  const longDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        month: "numeric",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const handleClickRemove = (_event: React.SyntheticEvent) => {
    setModal(Modal.Remove);
  };

  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  if (productId == null) {
    return null;
  }

  if (isViewerApiUser) {
    return null;
  }

  const biddingStarts =
    startingTimestamp == null ? null : (
      <SBParagraph style={styles.timestampTitle}>
        {t("product.auction.starts.title", {
          timestamp: longDateFormatter.format(startingTimestamp),
        })}
      </SBParagraph>
    );

  const auctionCloses =
    closingTimestamp == null ? null : (
      <SBParagraph style={styles.timestampTitle}>
        {t("product.auction.closes.title", {
          timestamp: longDateFormatter.format(closingTimestamp),
        })}
      </SBParagraph>
    );

  const auctionLocks =
    lockedTimestamp == null ? null : (
      <SBParagraph style={styles.timestampTitle}>
        {t("product.auction.locks.title", {
          timestamp: longDateFormatter.format(lockedTimestamp),
        })}
      </SBParagraph>
    );

  let timestamps =
    startingTimestamp == null || closingTimestamp == null ? null : (
      <>
        {biddingStarts}
        {auctionCloses}
      </>
    );
  if (
    canRemoveFromAuction &&
    startingTimestamp != null &&
    lockedTimestamp != null
  ) {
    timestamps = (
      <>
        {auctionLocks}
        {biddingStarts}
        {auctionCloses}
      </>
    );
  }

  const auctionTitleBar =
    auctionTitle == null ? null : (
      <>
        <SBHeading level={HeadingLevel.H2} style={styles.auctionTitleBar}>
          {auctionTitle}
        </SBHeading>
        {auctionTagline == null ? null : (
          <SBParagraph style={styles.tagline}>{auctionTagline}</SBParagraph>
        )}
      </>
    );

  let response = null;
  if (auctionType === "COMMUNITY") {
    response = (
      <>
        <div {...stylex.props(styles.container)}>
          <div {...stylex.props(styles.columns)}>
            <div {...stylex.props(styles.columnLeft)}>
              {auctionTitleBar}{" "}
              <SBHeading level={HeadingLevel.H3}>
                {t(`product.status.owner.${kebabCase(publicStatus)}`)}
              </SBHeading>
              <SBParagraph style={styles.auctionConsignmentSubtext}>
                {t(`product.status.owner.${kebabCase(publicStatus)}-subtext`)}
              </SBParagraph>
            </div>
            <div {...stylex.props(styles.columnRight)}>{timestamps}</div>
          </div>
          {canRemoveFromAuction ? (
            <SBButton
              block={true}
              style={styles.button}
              title={t("product.buttons.remove-from-auction")}
              onClick={handleClickRemove}
            />
          ) : null}
        </div>
        <RemoveFromAuction
          isOpen={modal === Modal.Remove}
          productId={productId}
          setOpen={handleCloseModal}
        />
      </>
    );
  } else if (AUCTION_STATUSES.includes(publicStatus)) {
    response = (
      <div {...stylex.props(styles.container)}>
        <div {...stylex.props(styles.columns)}>
          <div {...stylex.props(styles.columnLeft)}>
            {auctionTitleBar}
            <SBHeading level={HeadingLevel.H3}>
              {t(`product.status.owner.${kebabCase(publicStatus)}`)}
            </SBHeading>
            <SBParagraph style={styles.auctionConsignmentSubtext}>
              {t(`product.status.owner.${kebabCase(publicStatus)}-subtext`)}
            </SBParagraph>
          </div>
          <div {...stylex.props(styles.columnRight)}>{timestamps}</div>
        </div>
      </div>
    );
  }

  return response;
};

const styles = stylex.create({
  auctionConsignmentSubtext: {
    marginTop: 8,
  },
  auctionTitleBar: {
    marginBottom: 8,
  },
  button: {
    marginTop: 16,
  },
  columnLeft: {
    marginRight: 16,
  },
  columnRight: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    justifyContent: "flex-end",
  },
  columns: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    width: "100%",
  },
  tagline: {
    marginBottom: 8,
  },
  timestampTitle: {
    fontSize: 12,
    margin: 0,
    marginBottom: 4,
    textAlign: "right",
  },
});

export default ProductDetailActionsOwnerAuction;
