/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TermsFixUpFormUpdateTermsMutation } from "src/types/__generated__/TermsFixUpFormUpdateTermsMutation.graphql";
import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { t } from "i18next";
import * as React from "react";
import { useState } from "react";
import { useMutation } from "react-relay";

import {
  ButtonType,
  ButtonVariation,
  SBButton,
  SBErrorMessage,
} from "src/sbxui";

type Props = Readonly<{
  onTermsUpdated: () => void;
}>;

import TermsAcceptFields from "./TermsAcceptFields";

const TermsFixUpForm = ({ onTermsUpdated }: Props): React.ReactNode => {
  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isFaqsChecked, setIsFaqsChecked] = useState<boolean>(false);
  // TODO Will need consent before enabling in EU countries (GDPR).
  const [allowMarketingNotifs, setAllowMarketingNotifs] =
    useState<boolean>(true);
  const [isAcceptingTerms, setIsAcceptingTerms] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleChangeTermsCheckbox = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const handleChangeFaqCheckbox = () => {
    setIsFaqsChecked(!isFaqsChecked);
  };

  const handleChangeMarketingNotifsCheckbox = () => {
    setAllowMarketingNotifs(!allowMarketingNotifs);
  };

  const [commit, isInFlight] = useMutation<TermsFixUpFormUpdateTermsMutation>(
    graphql`
      mutation TermsFixUpFormUpdateTermsMutation(
        $input: UpdateUserTermsInput!
      ) {
        updateTerms(updateUserTermsInput: $input) {
          id
          hasAcceptedPrivacyPolicy
          hasAcceptedTermsOfService
        }
      }
    `,
  );

  const handleAccept = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }

    setIsAcceptingTerms(true);

    commit({
      onCompleted() {
        setIsAcceptingTerms(false);
        onTermsUpdated();
      },
      onError(error: unknown) {
        setIsAcceptingTerms(false);
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ??
          t("terms-fix-up.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("terms-fix-up.errors.http429");
        }
        setErrorMessage(message);
      },
      variables: {
        input: {
          hasAcceptedPrivacyPolicy: true,
          hasAcceptedTermsOfService: true,
        },
      },
    });
  };

  return (
    <div>
      <form method="POST">
        <SBErrorMessage message={errorMessage} style={styles.error} />
        <TermsAcceptFields
          isFaqsChecked={isFaqsChecked}
          isMarketingChecked={allowMarketingNotifs}
          isTermsChecked={isTermsChecked}
          onFaqsChecked={handleChangeFaqCheckbox}
          onMarketingChecked={handleChangeMarketingNotifsCheckbox}
          onTermsChecked={handleChangeTermsCheckbox}
        />
        <SBButton
          block={true}
          disabled={!isTermsChecked || !isFaqsChecked}
          loading={isAcceptingTerms}
          title={t("terms-fix-up.buttons.continue")}
          type={ButtonType.Submit}
          variation={ButtonVariation.Emphasis}
          onClick={handleAccept}
        />
      </form>
    </div>
  );
};

const styles = stylex.create({
  error: {
    marginBottom: 16,
  },
});

export default React.memo(TermsFixUpForm);
