/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { MenuItemConfig } from "src/sbxui";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Login from "src/app/components/login/Login";
import ProfileLink from "src/app/components/profile-link/ProfileLink";
import { UserContext } from "src/app/context/user";
import {
  ACCOUNT_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PORTFOLIO_PATH,
  SELLER_ACCOUNT_PATH,
  SIGNUP_PATH,
} from "src/app/router/Router";
import { MenuPosition, SBIcon, SBMenu, SBTakeover } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import LoginForm from "../login/LoginForm";
import { RequestSellerAccessModal } from "./request-seller-access/RequestSellerAccessModal";

const Auth = (): React.ReactNode => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { logOut, user } = useContext(UserContext);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [isSellerAccessModalOpen, setIsSellerAccessModalOpen] = useState(false);

  const handleClickLogin = (_e: React.SyntheticEvent) => {
    setIsLoginModalOpen(true);
  };

  const handleClickSignup = (_e: React.SyntheticEvent) => {
    navigate(SIGNUP_PATH);
  };

  const handleClickLogout = (_e: React.SyntheticEvent) => {
    setIsLoggingIn(false);
    logOut();
    navigate(HOME_PATH);
  };

  const handleLoginStart = () => {
    setIsLoggingIn(true);
  };

  const handleLoginCancel = () => {
    setIsLoggingIn(false);
    setIsLoginModalOpen(false);
  };

  const handleLoginSuccess = () => {
    setIsLoginModalOpen(false);
    if (window.location.pathname.includes(LOGIN_PATH)) {
      history.pushState({}, "", HOME_PATH);
    } else {
      window.location.reload();
    }
  };

  const viewerIsSeller = user?.sellerStatus != null;

  const loggedInItems: MenuItemConfig[] = [
    {
      id: "account",
      label: t(viewerIsSeller ? "menu.buyer-account" : "menu.account"),
      to: ACCOUNT_PATH,
    },
    viewerIsSeller
      ? {
          id: "sellerAccount",
          label: t("menu.seller-account"),
          to: SELLER_ACCOUNT_PATH,
        }
      : {
          id: "requestSellerAccess",
          label: t("menu.request-seller-access"),
          onClick: () => {
            setIsSellerAccessModalOpen(true);
          },
        },
    {
      id: "portfolio",
      label: t("menu.portfolio"),
      to: PORTFOLIO_PATH,
    },
    {
      id: "logout",
      label: t("menu.logout"),
      onClick: handleClickLogout,
    },
  ];

  const items: MenuItemConfig[] =
    user == null
      ? [
          {
            id: "signup",
            label: t("menu.signup"),
            onClick: handleClickSignup,
          },
          {
            id: "login",
            label: t("menu.login"),
            onClick: handleClickLogin,
          },
        ]
      : loggedInItems;

  return (
    <>
      <SBMenu
        button={
          <>
            <div {...stylex.props(auto, styles.menu)}>
              <SBIcon icon="menu" style={styles.icon} />
            </div>
            <div {...stylex.props(auto, styles.auth)}>
              {user == null ? <Login loading={isLoggingIn} /> : <ProfileLink />}
            </div>
          </>
        }
        buttonLabel={t("menu.label")}
        buttonStyle={styles.buttonStyle}
        items={items}
        position={MenuPosition.LEFT}
      />
      <SBTakeover isOpen={isLoginModalOpen} setOpen={setIsLoginModalOpen}>
        <LoginForm
          onLoginCancel={handleLoginCancel}
          onLoginStart={handleLoginStart}
          onLoginSuccess={handleLoginSuccess}
        />
      </SBTakeover>
      <RequestSellerAccessModal
        isOpen={isSellerAccessModalOpen}
        setOpen={setIsSellerAccessModalOpen}
        onSuccess={() => {
          setIsSellerAccessModalOpen(false);
        }}
      />
    </>
  );
};

const styles = stylex.create({
  auth: {
    height: 28,
    overflow: "hidden",
    width: 28,
  },
  buttonStyle: {
    alignItems: "center",
    backgroundColor: colors.topNavigationBackgroundColor,
    borderColor: colors.topNavigationMenuBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    height: 40,
    justifyContent: "center",
    padding: 4,
  },
  error: {
    alignItems: "flex-start",
    backgroundColor: colors.errorBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 16,
    paddingBlock: 12,
    paddingInline: 16,
  },
  errorIcon: {
    color: colors.errorColor,
    marginRight: 8,
  },
  errorMessage: {
    color: colors.errorColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  form: {
    padding: 96,
    width: 448,
  },
  formWrap: {
    backgroundColor: colors.backgroundEmphasisColor,
  },
  hr: {
    borderBottomWidth: 1,
    borderColor: colors.takeoverBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    marginBlock: 32,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  input: {
    marginBottom: 16,
  },
  loginButton: {
    marginBottom: 16,
  },
  menu: {
    alignItems: "center",
    borderRadius: 40,
    display: "flex",
    height: 40,
    justifyContent: "center",
    textDecorationLine: "none",
    width: 40,
  },
});

export default React.memo(Auth);
