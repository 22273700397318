/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotsMostActivityQuery } from "src/types/__generated__/AuctionLotsMostActivityQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

import AuctionItem from "../../auction/AuctionItem";
import AuctionLotsSeeMore from "./AuctionLotsSeeMore";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const AuctionLotsMostActivity = (): React.ReactNode | null => {
  const { t } = useTranslation();

  const data = useLazyLoadQuery<AuctionLotsMostActivityQuery>(
    graphql`
      query AuctionLotsMostActivityQuery {
        auctionLotsHighlights(first: 4, where: { type: MOST_ACTIVITY }) {
          edges {
            node {
              ...AuctionItem_auctionLot
              id
            }
            cursor
          }
        }
      }
    `,
    {},
  );

  const lots = data?.auctionLotsHighlights.edges ?? [];

  if (lots.length < 1) {
    return null;
  }

  return (
    <>
      <div {...stylex.props(styles.titleTop)}>
        <SBHeading level={HeadingLevel.H3} style={styles.title}>
          {t("home.title.auction-lots-most-activity")}
        </SBHeading>
        <AuctionLotsSeeMore />
      </div>
      <SBParagraph style={styles.subtitle}>
        {t("home.subtitle.auction-lots-most-activity")}
      </SBParagraph>
      <div {...stylex.props(styles.lots)}>
        {lots.map(({ node }) => (
          <AuctionItem key={node.id} queryKey={node} />
        ))}
      </div>
    </>
  );
};

const styles = stylex.create({
  lots: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(4, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginBottom: 24,
    marginTop: 24,
  },
  subtitle: {
    color: colors.colorMuted,
  },
  title: {
    marginBottom: 8,
  },
  titleTop: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default AuctionLotsMostActivity;
