/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Country } from "src/types/__generated__/AccountAddressCard_address.graphql";
import type { SignupFormMutation } from "src/types/__generated__/SignupFormMutation.graphql";
import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";
import { defaultRules, useValidation } from "react-simple-form-validator";

import { AppleSSOButton } from "src/app/components/sso/AppleSSOButton";
import { FacebookSSOButton } from "src/app/components/sso/FacebookSSOButton";
import { GoogleSSOButton } from "src/app/components/sso/GoogleSSOButton";
import { type LoginResponseType, UserContext } from "src/app/context/user";
import { useDefaultValidationErrorMessages } from "src/hooks";
import {
  AppleButtonTheme,
  ButtonType,
  ButtonVariation,
  SBButton,
  SBErrorMessage,
  SBParagraph,
  SBPasswordInput,
  SBTextInput,
  TextAutoCompleteType,
  TextInputType,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { countryFromLanguage } from "src/utils";

import TermsAcceptFields from "../terms-accept/TermsAcceptFields";
import SignupCode from "./SignupCode";
import SignupError from "./SignupError";
import SignupPhone from "./SignupPhone";
import SignupProcessing from "./SignupProcessing";
import SignupSuccess from "./SignupSuccess";
import SignupTermsFixUp from "./SignupTermsFixUp";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const NAME_RE = /^[A-Za-z\u00C0-\u017F\u1E00-\u1EFF '\u2019-]+(?:.)?$/u;
const EMAIL_RE = /^[^ ]+@[^ ]+$/iu;
const PASSWORD_MIN_LENGTH = 8;

enum Step {
  Code = "code",
  Error = "error",
  Info = "info",
  Processing = "processing",
  Success = "success",
  Telephone = "telephone",
  TermsFixUp = "terms-fix-up",
}

type Props = Readonly<{
  isKiosk?: boolean;
}>;

const SignupForm = ({ isKiosk = false }: Props): React.ReactNode | null => {
  const { i18n, t } = useTranslation();

  const { logIn } = React.useContext(UserContext);

  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [isTermsChecked, setIsTermsChecked] = useState<boolean>(false);
  const [isFaqsChecked, setIsFaqsChecked] = useState<boolean>(false);
  // TODO Will need consent before enabling in EU countries (GDPR).
  const [allowMarketingNotifs, setAllowMarketingNotifs] = useState(true);

  const [phoneNumber, setPhoneNumber] = useState("");

  const [country, setCountry] = useState(countryFromLanguage(i18n.language));

  const [errorMessage, setErrorMessage] = useState("");
  const [isSigningUp, setIsSigningUp] = useState(false);

  const [touchedFields, setTouchedFields] = useState({
    email: false,
    familyName: false,
    givenName: false,
    password: false,
    passwordConfirmation: false,
  });

  const [step, setStep] = useState<Step>(Step.Info);

  const defaultValidationErrorMessages = useDefaultValidationErrorMessages();

  const { getErrorsInField, isFieldInError, isFormValid } = useValidation({
    fieldsRules: {
      email: { customEmailRule: true, required: true },
      familyName: { customNameRule: true, required: true },
      givenName: { customNameRule: true, required: true },
      password: { minlength: PASSWORD_MIN_LENGTH, required: true },
      passwordConfirmation: { equalPassword: password, required: true },
    },
    labels: {
      email: t("signup.errors.labels.email"),
      familyName: t("signup.errors.labels.family-name"),
      givenName: t("signup.errors.labels.given-name"),
      password: t("signup.errors.labels.password"),
      passwordConfirmation: t("signup.errors.labels.password-confirmation"),
    },
    messages: {
      // We have our own library for localization, so we just use en here.
      en: {
        ...defaultValidationErrorMessages,
        customEmailRule: t("signup.errors.email"),
        customNameRule: t("signup.errors.name"),
      },
    },
    rules: {
      ...defaultRules,
      customEmailRule: EMAIL_RE,
      customNameRule: NAME_RE,
    },
    state: { email, familyName, givenName, password, passwordConfirmation },
  });

  const [signUpCommit, isInFlightSignup] = useMutation<SignupFormMutation>(
    graphql`
      mutation SignupFormMutation($input: SignUpInput!) {
        signup(signupInput: $input) {
          firstName
          lastName
          emailAddress
        }
      }
    `,
  );

  const signup = () => {
    setIsSigningUp(true);
    signUpCommit({
      onCompleted() {
        setIsSigningUp(false);
        setStep(Step.Success);
      },
      onError(error: unknown) {
        setIsSigningUp(false);
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ?? t("signup.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("signup.errors.http429");
        }
        setStep(Step.Error);
        setErrorMessage(message);
      },
      variables: {
        input: {
          allowMarketingNotifs,
          emailAddress: email.trim(),
          firstName: givenName.trim(),
          lastName: familyName.trim(),
          password,
        },
      },
    });
  };

  const handleBlurField = (
    event: React.FocusEvent<HTMLInputElement>,
    field: string,
  ) => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  const handleChangeGivenName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setGivenName(event.currentTarget.value);
  };

  const handleChangeFamilyName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFamilyName(event.currentTarget.value);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleChangePasswordConfirmation = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPasswordConfirmation(event.currentTarget.value);
  };

  const handleChangeTermsCheckbox = () => {
    setIsTermsChecked(!isTermsChecked);
  };

  const handleChangeFaqCheckbox = () => {
    setIsFaqsChecked(!isFaqsChecked);
  };

  const handleChangeMarketingNotifsCheckbox = () => {
    setAllowMarketingNotifs(!allowMarketingNotifs);
  };

  const handleSubmitPhone = (newPhone: string, newCountry: Country) => {
    setPhoneNumber(newPhone);
    setCountry(newCountry);
    setStep(Step.Code);
  };

  const handleSSOLoginSuccess = (
    loginResponse: LoginResponseType,
    needTermsFixUp: boolean,
  ) => {
    logIn(loginResponse);
    setStep(needTermsFixUp ? Step.TermsFixUp : Step.Success);
  };

  const handleSignup = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlightSignup) {
      return;
    }
    setTouchedFields({
      email: !isFormValid,
      familyName: !isFormValid,
      givenName: !isFormValid,
      password: !isFormValid,
      passwordConfirmation: !isFormValid,
    });
    if (isFormValid) {
      setStep(Step.Telephone);
    }
  };

  const handleSubmitCode = () => {
    signup();
  };

  const handleTermsUpdated = () => {
    setStep(Step.Success);
  };

  if (step === Step.Telephone) {
    return <SignupPhone onSubmit={handleSubmitPhone} />;
  }

  if (step === Step.Code) {
    return (
      <SignupCode
        country={country}
        phoneNumber={phoneNumber}
        onSubmit={handleSubmitCode}
      />
    );
  }

  if (step === Step.Processing) {
    return <SignupProcessing />;
  }

  if (step === Step.TermsFixUp) {
    return <SignupTermsFixUp onTermsUpdated={handleTermsUpdated} />;
  }

  if (step === Step.Success) {
    return (
      <SignupSuccess email={email} isKiosk={isKiosk} password={password} />
    );
  }

  if (step === Step.Error) {
    return <SignupError message={errorMessage} />;
  }

  return (
    <div {...stylex.props(styles.form)}>
      <div {...stylex.props(auto, styles.logo)}></div>
      <form method="post">
        <SBErrorMessage message={errorMessage} style={styles.error} />
        <fieldset {...stylex.props(styles.fieldset)}>
          <div {...stylex.props(styles.nameFields)}>
            <SBTextInput
              disabled={isSigningUp}
              errorMessage={
                touchedFields.givenName
                  ? isFieldInError("givenName") &&
                    getErrorsInField("givenName")[0]
                  : null
              }
              id="givenName"
              label={t("signup.field.given-name")}
              placeholder={t("signup.field.given-name")}
              style={[styles.input, styles.inputFirst]}
              type={TextInputType.Text}
              value={givenName}
              onBlur={(event) => {
                handleBlurField(event, "givenName");
              }}
              onChange={handleChangeGivenName}
            />
            <SBTextInput
              disabled={isSigningUp}
              errorMessage={
                touchedFields.familyName
                  ? isFieldInError("familyName") &&
                    getErrorsInField("familyName")[0]
                  : null
              }
              id="familyName"
              label={t("signup.field.family-name")}
              placeholder={t("signup.field.family-name")}
              style={styles.input}
              type={TextInputType.Text}
              value={familyName}
              onBlur={(event) => {
                handleBlurField(event, "familyName");
              }}
              onChange={handleChangeFamilyName}
            />
          </div>
          <SBTextInput
            autoComplete={TextAutoCompleteType.Email}
            disabled={isSigningUp}
            errorMessage={
              touchedFields.email
                ? isFieldInError("email") && getErrorsInField("email")[0]
                : null
            }
            id="email"
            label={t("signup.field.email")}
            placeholder={t("signup.field.email")}
            style={styles.input}
            type={TextInputType.Email}
            value={email}
            onBlur={(event) => {
              handleBlurField(event, "email");
            }}
            onChange={handleChangeEmail}
          />
          <SBPasswordInput
            current={true}
            disabled={isSigningUp}
            errorMessage={
              touchedFields.password
                ? isFieldInError("password") && getErrorsInField("password")[0]
                : null
            }
            id="password"
            label={t("signup.field.password")}
            placeholder={t("signup.field.password")}
            style={styles.input}
            value={password}
            onBlur={(event) => {
              handleBlurField(event, "password");
            }}
            onChange={handleChangePassword}
          />
          <SBPasswordInput
            current={false}
            disabled={isSigningUp}
            errorMessage={
              touchedFields.passwordConfirmation
                ? isFieldInError("passwordConfirmation") &&
                  getErrorsInField("passwordConfirmation")[0]
                : null
            }
            id="passwordConfirmation"
            label={t("signup.field.password")}
            placeholder={t("signup.field.password-confirmation")}
            style={styles.input}
            value={passwordConfirmation}
            onBlur={(event) => {
              handleBlurField(event, "passwordConfirmation");
            }}
            onChange={handleChangePasswordConfirmation}
          />
        </fieldset>
        <TermsAcceptFields
          isFaqsChecked={isFaqsChecked}
          isMarketingChecked={allowMarketingNotifs}
          isTermsChecked={isTermsChecked}
          onFaqsChecked={handleChangeFaqCheckbox}
          onMarketingChecked={handleChangeMarketingNotifsCheckbox}
          onTermsChecked={handleChangeTermsCheckbox}
        />
        <SBButton
          block={true}
          disabled={!isTermsChecked || !isFaqsChecked}
          loading={isSigningUp}
          style={styles.loginButton}
          title={t("verify.buttons.account")}
          type={ButtonType.Submit}
          variation={ButtonVariation.Emphasis}
          onClick={handleSignup}
        />
        {!isKiosk && (
          <>
            <div {...stylex.props(styles.separator)}>
              <hr {...stylex.props(auto, styles.hr)} aria-hidden={true} />
              <SBParagraph style={styles.text}>{t("signup.or")}</SBParagraph>
              <hr {...stylex.props(auto, styles.hr)} aria-hidden={true} />
            </div>
            <GoogleSSOButton
              signup={true}
              style={styles.loginButton}
              onLoginSuccess={handleSSOLoginSuccess}
            />
            <AppleSSOButton
              signup={true}
              style={styles.loginButton}
              theme={AppleButtonTheme.Light}
              onLoginSuccess={handleSSOLoginSuccess}
            />
            <FacebookSSOButton
              signup={true}
              onLoginSuccess={handleSSOLoginSuccess}
            />
          </>
        )}
      </form>
    </div>
  );
};

const styles = stylex.create({
  buttonStyle: {
    alignItems: "center",
    backgroundColor: colors.topNavigationBackgroundColor,
    borderColor: colors.topNavigationMenuBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    height: 48,
    justifyContent: "center",
    padding: 4,
    width: 96,
  },
  error: {
    marginBottom: 16,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  form: {
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 48,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
  hr: {
    borderBottomWidth: 1,
    borderColor: colors.colorMuted,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    flexGrow: 1,
    margin: 0,
    padding: 0,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  input: {
    marginBottom: 16,
  },
  inputFirst: {
    marginRight: 16,
  },
  loginButton: {
    marginBottom: 16,
  },
  logo: {
    backgroundImage: colors.signUpLogo,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
    height: 32,
    marginBottom: 32,
    marginInline: "auto",
    width: 141,
  },
  nameFields: {
    display: "flex",
    flexDirection: {
      [MOBILE]: "column",
      [TABLET]: "column",
      default: "row",
    },
    justifyContent: "space-between",
  },
  separator: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBlock: 32,
  },
  text: {
    color: colors.colorMuted,
    marginBlock: 0,
    marginInline: 16,
  },
});

export default React.memo(SignupForm);
