/**
 * @generated SignedSource<<a7e1c27e931de5c473b540c2a738420e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveFromAuctionView_product$data = {
  readonly auctionLot: {
    readonly auction: {
      readonly biddingStartsAt: string;
      readonly tagline: string | null | undefined;
      readonly title: string;
    };
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "RemoveFromAuctionView_product";
};
export type RemoveFromAuctionView_product$key = {
  readonly " $data"?: RemoveFromAuctionView_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveFromAuctionView_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveFromAuctionView_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "auctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Auction",
          "kind": "LinkedField",
          "name": "auction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tagline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "biddingStartsAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "5ee516f601a331f59dda3bc64b9a2147";

export default node;
