/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PortfolioFmv_portfolioFmv$key } from "src/types/__generated__/PortfolioFmv_portfolioFmv.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBIcon, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const MOBILE = "@media (max-width: 767px)";

type Props = Readonly<{
  queryKey: PortfolioFmv_portfolioFmv$key;
}>;

const PortfolioFmv = ({ queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.languages),
    [i18n.languages],
  );

  const data = useFragment(
    graphql`
      fragment PortfolioFmv_portfolioFmv on PortfolioFmv {
        portfolioCount
        portfolioFmv
        portfolioFmvCount
      }
    `,
    queryKey,
  );

  const value = data?.portfolioFmv ?? 0;
  const count = data?.portfolioFmvCount ?? 0;
  const total = data?.portfolioCount ?? 0;

  const countValue = numberFormatter.format(count);
  const totalValue = numberFormatter.format(total);

  return (
    <div {...stylex.props(auto, styles.container)}>
      <div {...stylex.props(auto, styles.portfolioValue)}>
        {formatMoney(value, "USD", i18n.language)}
      </div>
      <div {...stylex.props(auto, styles.fmvBlock)}>
        <div {...stylex.props(auto, styles.fmv)}>
          <SBParagraph style={styles.total}>
            {t("portfolio.header.total")}
          </SBParagraph>
          <SBIcon icon="show_chart" size={20} style={styles.line} />
        </div>
        <SBParagraph style={styles.count}>
          {total === 0
            ? t("portfolio.header.count-empty")
            : t("portfolio.header.count", {
                books: countValue,
                total: totalValue,
              })}
        </SBParagraph>
      </div>
    </div>
  );
};

const styles = stylex.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  count: {
    fontSize: {
      [MOBILE]: 12,
      default: 14,
    },
    marginBottom: 0,
    paddingRight: 12,
  },
  fmv: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: 20,
  },
  fmvBlock: {
    paddingLeft: 12,
  },
  line: {
    color: colors.colorMuted,
  },
  portfolioValue: {
    color: colors.color,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: {
      [MOBILE]: 32,
      default: 40,
    },
    textAlign: "right",
  },
  total: {
    fontSize: {
      [MOBILE]: 12,
      default: 14,
    },
    marginBottom: 0,
  },
});

export default PortfolioFmv;
