/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailActionsOwnerDefault_product$key } from "src/types/__generated__/ProductDetailActionsOwnerDefault_product.graphql";
import type { SellerStatus } from "src/types/__generated__/QueriesProductDetailQuery.graphql";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import CreateSubmission from "src/app/components/create-submission/CreateSubmission";
import DeleteInventoryItem from "src/app/components/delete-inventory-item/DeleteInventoryItem";
import EditProduct from "src/app/components/edit-product/EditProduct";
import SendToAuction from "src/app/components/send-to-auction/SendToAuction";
import { PORTFOLIO_PATH } from "src/app/router/Router";
import { ButtonVariation, SBButton } from "src/sbxui";

enum Modal {
  Auction,
  None,
  Delete,
  Edit,
  List,
}

type Props = Readonly<{
  employee: boolean;
  queryKey: ProductDetailActionsOwnerDefault_product$key;
  sellerStatus: SellerStatus | null | undefined;
}>;

const ProductDetailActionsOwnerDefault = ({
  queryKey,
  sellerStatus,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsOwnerDefault_product on Product {
        id
        publicStatus
        canSell
        isViewerApiUser
        favoritesCount
        minOfferPrice
        minOfferPercentage
        canRequestAuctionConsignment
        canSubmitToCommunityAuctions
      }
    `,
    queryKey,
  );

  const productId = data?.id;

  const publicStatus = data?.publicStatus;

  const canRequestAuctionConsignment =
    data?.canRequestAuctionConsignment ?? false;
  const canSubmitToCommunityAuctions =
    data?.canSubmitToCommunityAuctions ?? false;

  const canSellItem = data?.canSell ?? false;
  const isViewerApiUser = data?.isViewerApiUser ?? false;

  const [modal, setModal] = useState(Modal.None);

  const handleClickDelete = () => {
    setModal(Modal.Delete);
  };

  const handleClickEdit = () => {
    setModal(Modal.Edit);
  };

  const handleClickAuction = () => {
    setModal(Modal.Auction);
  };

  const handleClickList = () => {
    setModal(Modal.List);
  };

  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  const handleSuccess = () => {
    history.replaceState({}, "", PORTFOLIO_PATH);
    window.location.reload();
  };

  if (productId == null) {
    return null;
  }

  if (isViewerApiUser) {
    return null;
  }

  const canListForSale =
    sellerStatus === "APPROVED" && publicStatus === "UNAVAILABLE";

  const isSendToAuctionButtonEnabled =
    (canRequestAuctionConsignment || canSubmitToCommunityAuctions) &&
    modal !== Modal.Auction;

  return (
    <>
      <div {...stylex.props(styles.container)}>
        <SBButton
          block={true}
          disabled={modal === Modal.Delete}
          external={true}
          title={t("product.buttons.delete")}
          onClick={handleClickDelete}
        />
        <SBButton
          block={true}
          disabled={modal === Modal.Edit}
          icon="edit"
          style={styles.button}
          title={t("product.buttons.edit")}
          onClick={handleClickEdit}
        />
        {canListForSale && canSellItem ? (
          <>
            <SBButton
              block={true}
              disabled={!isSendToAuctionButtonEnabled}
              style={styles.button}
              title={t("product.buttons.send-to-auction")}
              onClick={handleClickAuction}
            />
            <SBButton
              block={true}
              disabled={modal === Modal.List}
              style={styles.button}
              title={t("product.buttons.list-for-sale")}
              variation={ButtonVariation.Emphasis}
              onClick={handleClickList}
            />
          </>
        ) : null}
      </div>
      <DeleteInventoryItem
        isOpen={modal === Modal.Delete}
        productId={productId}
        setOpen={handleCloseModal}
        onSuccess={handleSuccess}
      />
      <EditProduct
        isOpen={modal === Modal.Edit}
        productId={productId}
        setOpen={handleCloseModal}
      />
      <SendToAuction
        isOpen={modal === Modal.Auction}
        productId={productId}
        setOpen={handleCloseModal}
      />
      <CreateSubmission
        isOpen={modal === Modal.List}
        productId={productId}
        setOpen={handleCloseModal}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  container: {
    width: "100%",
  },
});

export default ProductDetailActionsOwnerDefault;
