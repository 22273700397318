/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";

export const RequestSellerAccessSuccess = () => {
  const { t } = useTranslation();

  return (
    <div>
      <SBHeading level={HeadingLevel.H2}>
        {t("request-seller-access.success.title")}
      </SBHeading>
      <SBParagraph>
        {t("request-seller-access.success.description")}
      </SBParagraph>
    </div>
  );
};
