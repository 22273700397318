/**
 * @generated SignedSource<<d7a4071159f08488036775eede148736>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SellerAccountProductOfferCard_product$data = {
  readonly comicDetails: {
    readonly grade: number | null | undefined;
    readonly gradingAuthority: GradingAuthority;
    readonly number: string;
    readonly title: string;
  } | null | undefined;
  readonly currency: Currency;
  readonly id: string;
  readonly images: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      };
    }>;
  } | null | undefined;
  readonly offerCounts: {
    readonly favorite: number;
    readonly received: number;
  } | null | undefined;
  readonly price: number;
  readonly " $fragmentType": "SellerAccountProductOfferCard_product";
};
export type SellerAccountProductOfferCard_product$key = {
  readonly " $data"?: SellerAccountProductOfferCard_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountProductOfferCard_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountProductOfferCard_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductOfferCounts",
      "kind": "LinkedField",
      "name": "offerCounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "received",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "favorite",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "ImagesConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "quality",
                      "value": 100
                    },
                    {
                      "kind": "Literal",
                      "name": "webp",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 300
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": "url(quality:100,webp:true,width:300)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "images(first:1)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "ea17d41e1d27b03e28426e1fd094c268";

export default node;
