/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PortfolioItem_product$data } from "src/types/__generated__/PortfolioItem_product.graphql";

import * as React from "react";
import { Line, LineChart } from "recharts";

import { colors } from "src/themes/colors.stylex";
import { isFirstHalfAverageGreater } from "src/utils/_utils/isFirstHalfAverageGreater";

const YEAR_EPOCH = 31557600000;

type HistoricalSales = PortfolioItem_product$data["historicalSales"];

type Props = Readonly<{
  data: HistoricalSales;
}>;

const PortfolioChartSnapshot = ({ data }: Props): React.ReactNode | null => {
  const historicalSales = data?.edges ?? [];

  if (historicalSales.length < 5) {
    return null;
  }

  const lastSaleDate = historicalSales[-1]?.node?.saleDate ?? Date.now();
  const oneYearAgo = Date.now() - YEAR_EPOCH;

  // Don't show the chart if the last sale was more than a year ago.
  if (lastSaleDate < oneYearAgo) {
    return null;
  }

  const sales = historicalSales?.map(({ node }) => ({
    x: node ? new Date(node.saleDate).getTime() * 1000 : Date.now(),
    y: node?.price,
  }));

  sales.reverse();

  const salesNumbers = sales.map((salesNumber) => salesNumber.y);

  const strokeColor = isFirstHalfAverageGreater(salesNumbers)
    ? colors.portfolioChartSnapshotNegative
    : colors.portfolioChartSnapshotPositive;

  // Always end on today's date.
  sales.push({
    x: Date.now(),
    // @ts-ignore This does exactly what we want but it's typed strictly as `number`.
    y: null,
  });

  return (
    <LineChart data={sales} height={50} width={50}>
      <Line dataKey="y" dot={false} stroke={strokeColor} type="monotone" />
    </LineChart>
  );
};

export default PortfolioChartSnapshot;
