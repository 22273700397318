/**
 * @generated SignedSource<<f488ac5d78e69f7937c2adcb48a446e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewOffersReceivedCardCounter_offer$data = {
  readonly currency: Currency;
  readonly expiresAt: string;
  readonly id: string;
  readonly price: number;
  readonly product: {
    readonly minOfferPercentage: number;
    readonly minOfferPrice: number | null | undefined;
    readonly offerCounts: {
      readonly pending: number;
      readonly received: number;
      readonly sent: number;
    } | null | undefined;
    readonly price: number;
  };
  readonly status: OfferStatus;
  readonly toUserId: string | null | undefined;
  readonly " $fragmentType": "ReviewOffersReceivedCardCounter_offer";
};
export type ReviewOffersReceivedCardCounter_offer$key = {
  readonly " $data"?: ReviewOffersReceivedCardCounter_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewOffersReceivedCardCounter_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewOffersReceivedCardCounter_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minOfferPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minOfferPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductOfferCounts",
          "kind": "LinkedField",
          "name": "offerCounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pending",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "received",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sent",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};
})();

(node as any).hash = "7efc5ec4a7cdced912a4033d52fae5d9";

export default node;
