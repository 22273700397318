/**
 * @generated SignedSource<<9f93e2ebd01ad823479c1ba9938bbbaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SellerStatus = "APPROVED" | "DEACTIVATED" | "IN_REVIEW" | "SUSPENDED" | "VACATION" | "%future added value";
export type RequestSellerAccessInput = {
  countryCode?: number | null | undefined;
  file?: ReadonlyArray<any> | null | undefined;
  shopName?: string | null | undefined;
  telephone?: number | null | undefined;
  userId: string;
};
export type RequestSellerAccessModalMutation$variables = {
  input: RequestSellerAccessInput;
};
export type RequestSellerAccessModalMutation$data = {
  readonly requestSellerAccess: {
    readonly sellerStatus: SellerStatus | null | undefined;
  };
};
export type RequestSellerAccessModalMutation = {
  response: RequestSellerAccessModalMutation$data;
  variables: RequestSellerAccessModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "requestSellerAccessInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellerStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestSellerAccessModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "requestSellerAccess",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestSellerAccessModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "requestSellerAccess",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abd9de3802c64e91e423363dbc9fadba",
    "id": null,
    "metadata": {},
    "name": "RequestSellerAccessModalMutation",
    "operationKind": "mutation",
    "text": "mutation RequestSellerAccessModalMutation(\n  $input: RequestSellerAccessInput!\n) {\n  requestSellerAccess(requestSellerAccessInput: $input) {\n    sellerStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1355d239ee37cf498ce9f1d51c1a1c8a";

export default node;
