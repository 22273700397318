/**
 * @generated SignedSource<<96cfe6b5296ca59954f3958cde7027bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewOffersReceivedPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  id: string;
};
export type ReviewOffersReceivedPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ReviewOffersReceived_product">;
  } | null | undefined;
};
export type ReviewOffersReceivedPaginationQuery = {
  response: ReviewOffersReceivedPaginationQuery$data;
  variables: ReviewOffersReceivedPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "reason": [
        "NONE",
        "COUNTER_FROM_BUYER"
      ],
      "status": [
        "IN_REVIEW"
      ]
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewOffersReceivedPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              }
            ],
            "kind": "FragmentSpread",
            "name": "ReviewOffersReceived_product"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReviewOffersReceivedPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "receivedOffers",
                "args": (v4/*: any*/),
                "concreteType": "ProductOffersConnection",
                "kind": "LinkedField",
                "name": "offers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductOffersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Offer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "toUserId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "expiresAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minOfferPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minOfferPercentage",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProductOfferCounts",
                                "kind": "LinkedField",
                                "name": "offerCounts",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "pending",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "received",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sent",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "receivedOffers",
                "args": (v4/*: any*/),
                "filters": [
                  "where"
                ],
                "handle": "connection",
                "key": "ReviewOffersReceived_receivedOffers",
                "kind": "LinkedHandle",
                "name": "offers"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da7bd3575a9099389f3a7260215d0721",
    "id": null,
    "metadata": {},
    "name": "ReviewOffersReceivedPaginationQuery",
    "operationKind": "query",
    "text": "query ReviewOffersReceivedPaginationQuery(\n  $count: Int = 10\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ReviewOffersReceived_product_1G22uz\n    id\n  }\n}\n\nfragment ReviewOffersReceivedCardAccept_offer on Offer {\n  id\n  price\n  currency\n  status\n  toUserId\n  product {\n    price\n    offerCounts {\n      pending\n      received\n      sent\n    }\n    id\n  }\n  expiresAt\n}\n\nfragment ReviewOffersReceivedCardCounter_offer on Offer {\n  id\n  price\n  currency\n  status\n  expiresAt\n  toUserId\n  product {\n    price\n    minOfferPrice\n    minOfferPercentage\n    offerCounts {\n      pending\n      received\n      sent\n    }\n    id\n  }\n}\n\nfragment ReviewOffersReceivedCardDecline_offer on Offer {\n  id\n  price\n  currency\n  status\n  expiresAt\n  toUserId\n  product {\n    price\n    offerCounts {\n      pending\n      received\n      sent\n    }\n    id\n  }\n}\n\nfragment ReviewOffersReceivedCard_offer on Offer {\n  id\n  price\n  currency\n  status\n  toUserId\n  expiresAt\n  product {\n    price\n    minOfferPrice\n    minOfferPercentage\n    id\n  }\n  ...ReviewOffersReceivedCardCounter_offer\n  ...ReviewOffersReceivedCardAccept_offer\n  ...ReviewOffersReceivedCardDecline_offer\n}\n\nfragment ReviewOffersReceived_product_1G22uz on Product {\n  id\n  receivedOffers: offers(first: $count, after: $cursor, where: {status: [IN_REVIEW], reason: [NONE, COUNTER_FROM_BUYER]}) {\n    edges {\n      node {\n        id\n        ...ReviewOffersReceivedCard_offer\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "001d43155b744c9f1dde383c0cb8d2b1";

export default node;
