/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

import { SBActivityIndicator, SBModal } from "src/sbxui";

import SendToAuctionContent from "./SendToAuctionContent";

type Props = Readonly<{
  isOpen: boolean;
  productId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const SendToAuction = ({
  isOpen,
  productId,
  setOpen,
}: Props): React.ReactNode => {
  const { t } = useTranslation();
  return (
    <SBModal
      headerText={t("send-to-auction-modal.title")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <Suspense
        fallback={
          <div {...stylex.props(styles.fallback)}>
            <SBActivityIndicator />
          </div>
        }
      >
        <SendToAuctionContent productId={productId} setOpen={setOpen} />
      </Suspense>
    </SBModal>
  );
};

const styles = stylex.create({
  fallback: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 16,
  },
});

export default React.memo(SendToAuction);
