/**
 * @generated SignedSource<<f32332b5727232b921b9d7f72aeed858>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsOwnerDefault_product$data = {
  readonly canRequestAuctionConsignment: boolean;
  readonly canSell: boolean;
  readonly canSubmitToCommunityAuctions: boolean;
  readonly favoritesCount: number;
  readonly id: string;
  readonly isViewerApiUser: boolean;
  readonly minOfferPercentage: number;
  readonly minOfferPrice: number | null | undefined;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly " $fragmentType": "ProductDetailActionsOwnerDefault_product";
};
export type ProductDetailActionsOwnerDefault_product$key = {
  readonly " $data"?: ProductDetailActionsOwnerDefault_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsOwnerDefault_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsOwnerDefault_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canSell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerApiUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoritesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canRequestAuctionConsignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canSubmitToCommunityAuctions",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "939eb1c968878f0b5faeba4c3b9a3a9b";

export default node;
