/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReviewOffersReceived_product$key } from "src/types/__generated__/ReviewOffersReceived_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator, SBParagraph } from "src/sbxui";

import ReviewOffersReceivedCard from "./ReviewOffersReceivedCard";

type Props = Readonly<{
  queryKey: ReviewOffersReceived_product$key;
}>;

const ReviewOffersReceived = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment ReviewOffersReceived_product on Product
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
      )
      @refetchable(queryName: "ReviewOffersReceivedPaginationQuery") {
        id
        receivedOffers: offers(
          first: $count
          after: $cursor
          where: { status: [IN_REVIEW], reason: [NONE, COUNTER_FROM_BUYER] }
        ) @connection(key: "ReviewOffersReceived_receivedOffers") {
          edges {
            node {
              id
              ...ReviewOffersReceivedCard_offer
            }
          }
        }
      }
    `,
    queryKey,
  );

  const offers = data?.receivedOffers?.edges ?? [];
  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(10);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  if (offers.length === 0) {
    return (
      <SBParagraph style={styles.infoMessage}>
        {t("review-offers.offer.no-action")}
      </SBParagraph>
    );
  }

  return (
    <>
      {offers?.map(({ node }) => (
        <ReviewOffersReceivedCard key={node.id} queryKey={node} />
      ))}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  infoMessage: {
    marginTop: 16,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
});

export default ReviewOffersReceived;
