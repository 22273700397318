/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  Currency,
  GradingAuthority,
} from "src/types/__generated__/AuctionSearchItem_auctionLot.graphql";

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserContext } from "src/app/context/user";
import { AUCTION_LOT_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import {
  HeadingLevel,
  SBHeading,
  SBParagraph,
  SBWatchAuctionLotButton,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

type Props = Readonly<{
  auctionLotId: string;
  closesAt?: string | null | undefined;
  currentBidAmount?: number | null | undefined;
  currentBidCurrency: Currency | null | undefined;
  fmvCurrency: Currency;
  fmvMaxValue: number;
  fmvMinValue: number;
  grade: number | null | undefined;
  gradingAuthority: GradingAuthority | null | undefined;
  imageUrl: string;
  isViewerOwner: boolean;
  number: string;
  onClickWatch?: (event: React.SyntheticEvent) => void;
  shortDescription?: string | null;
  startingAmount?: number | null | undefined;
  title: string;
  watching?: boolean;
  watchingLoading?: boolean;
}>;

export const SBAuctionLotTile = React.memo(
  ({
    auctionLotId,
    currentBidCurrency,
    currentBidAmount,
    startingAmount,
    grade,
    gradingAuthority,
    imageUrl,
    number,
    title,
    shortDescription,
    closesAt,
    fmvCurrency,
    fmvMaxValue,
    fmvMinValue,
    isViewerOwner,
    onClickWatch,
    watching,
    watchingLoading,
  }: Props) => {
    const { i18n, t } = useTranslation();

    const [opacity, setOpacity] = useState(0);

    const userContext = React.useContext(UserContext);

    const showWatchButton = userContext.user != null && !isViewerOwner;

    const destination = AUCTION_LOT_PATH.replace(":id", auctionLotId);

    const closingAtTimestamp =
      closesAt == null ? Date.now() : Number.parseInt(closesAt, 10);

    const { color, expiresIn, hasExpired } =
      useCountdownTimer(closingAtTimestamp);

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setOpacity(1);
      };
      img.src = imageUrl;
    }, [imageUrl]);

    const productTitle = formatTitle({ number, t, title });

    const gradeValue =
      grade == null || grade === 0 ? t("raw-grades.0-0") : grade.toFixed(1);

    return (
      <li {...stylex.props(auto, styles.tile)}>
        <Link
          {...stylex.props(auto, styles.link)}
          rel="noopener noreferrer"
          target="_blank"
          to={destination}
        >
          <div
            {...stylex.props(auto, styles.image(opacity))}
            aria-hidden="true"
          >
            <img
              {...stylex.props(styles.imageFile)}
              height="100%"
              src={imageUrl}
              width="auto"
            />
            <div {...stylex.props(auto, styles.imageSpacer)}></div>
          </div>
          <div {...stylex.props(styles.meta)}>
            <SBHeading
              aria-label={t("product.title-alt", {
                authority: t(
                  `grading-authority.${kebabCase(gradingAuthority ?? "unknown")}`,
                ),
                number,
                title,
                value:
                  gradingAuthority === "RAW"
                    ? t(`raw-grades.${kebabCase(gradeValue)}`)
                    : gradeValue,
              })}
              level={HeadingLevel.H3}
            >
              {productTitle}
            </SBHeading>
            <p {...stylex.props(styles.grade)} aria-hidden="true">
              {t("product.grade-number", {
                gradingAuthority: t(
                  `grading-authority.${kebabCase(gradingAuthority ?? "unknown")}`,
                ),
                value:
                  gradingAuthority === "RAW"
                    ? t(`raw-grades.${kebabCase(gradeValue)}`)
                    : gradeValue,
              })}
            </p>
            {fmvMinValue > 0 && fmvMaxValue > 0 && (
              <SBParagraph style={styles.fmvRange}>
                {t("product.fmv-range", {
                  max: formatMoney(fmvMaxValue, fmvCurrency, i18n.language),
                  min: formatMoney(fmvMinValue, fmvCurrency, i18n.language),
                })}
              </SBParagraph>
            )}

            {shortDescription ? (
              <SBParagraph style={styles.shortDescription}>
                {shortDescription}
              </SBParagraph>
            ) : null}

            {currentBidAmount != null && currentBidCurrency != null && (
              <p {...stylex.props(styles.cost)}>
                <span {...stylex.props(styles.bid)}>
                  {currentBidAmount === 0
                    ? t("auction.lot.no-bids", {
                        price: formatMoney(
                          startingAmount || 1,
                          currentBidCurrency,
                          i18n.language,
                        ),
                      })
                    : t("auction.lot.current-bid", {
                        currentBid: formatMoney(
                          currentBidAmount,
                          currentBidCurrency,
                          i18n.language,
                        ),
                      })}
                </span>
              </p>
            )}

            {hasExpired ? (
              <SBParagraph style={styles.expired}>
                {t("auction.lot.ended")}
              </SBParagraph>
            ) : (
              <SBParagraph style={styles.expiresIn(color)}>
                {expiresIn}
              </SBParagraph>
            )}
          </div>
        </Link>
        {showWatchButton &&
        onClickWatch != null &&
        watching != null &&
        watchingLoading != null ? (
          <SBWatchAuctionLotButton
            loading={watchingLoading}
            number={number}
            style={styles.watching}
            title={title}
            watching={watching}
            onClick={onClickWatch}
          />
        ) : null}
      </li>
    );
  },
);

const styles = stylex.create({
  bid: {
    fontSize: 16,
    fontWeight: 600,
  },
  cost: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 0,
    marginTop: 20,
  },
  expired: {
    color: colors.color,
    fontSize: 16,
    marginTop: 8,
  },
  expiresIn: (color: string) => ({
    color,
    fontSize: 14,
    margin: 0,
    marginRight: 24,
    marginTop: 4,
  }),
  fmvRange: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
  },
  grade: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
    marginTop: 5,
  },
  image: (opacity: number) => ({
    alignItems: "center",
    backgroundColor: colors.backgroundPlaceholderColor,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: "flex",
    justifyContent: "center",
    opacity,
    overflow: "hidden",
    position: "relative",
    transition: "opacity 250ms",
  }),
  imageFile: {
    display: "block",
    height: "100%",
    position: "absolute",
  },
  imageSpacer: {
    paddingBottom: "100%",
  },
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  meta: {
    color: colors.color,
    padding: 16,
  },
  shortDescription: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
  },
  tile: {
    backgroundColor: colors.backgroundEmphasisColor,
    backgroundSize: "contain",
    borderRadius: 8,
    listStyleType: "none",
    margin: 0,
    padding: 0,
    position: "relative",
  },
  watching: {
    position: "absolute",
    right: 8,
    top: 8,
  },
});
