/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionItem_auctionLot$key } from "src/types/__generated__/AuctionItem_auctionLot.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";

import { useUnwatchAuctionLot, useWatchAuctionLot } from "src/hooks";
import { SBAuctionLotTile } from "src/sbxui";

const POLL_TIME_MS = 500;

const AuctionItemBidSubscription = graphql`
  subscription AuctionItemBidSubscription(
    $input: BidAcceptedSubscriptionInput!
  ) {
    bidAcceptedSubscription(bidAcceptedSubscriptionInput: $input) {
      ...AuctionItem_auctionLot
    }
  }
`;

const AuctionItemExpirySubscription = graphql`
  subscription AuctionItemExpirySubscription($input: AuctionLotIdInput!) {
    auctionLotExpirySubscription(auctionLotExpirySubscription: $input) {
      ...AuctionItem_auctionLot
    }
  }
`;

type Props = Readonly<{
  queryKey: AuctionItem_auctionLot$key;
}>;

const AuctionItem = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionItem_auctionLot on AuctionLot {
        id
        shortDescription
        isClosed
        closesAt
        currentBidAmount
        currentBidCurrency
        isViewerBidding
        isViewerHighBidder
        isViewerOwner
        isViewerWatching
        startingAmount
        auction {
          biddingStartsAt
          type
        }
        product {
          comicDetails {
            title
            number
            grade
            gradingAuthority
          }
          fmvScore {
            maxValue
            minValue
            currency
          }
          images {
            edges {
              node {
                url(quality: 100, webp: true, width: 1000)
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const [commitWatch, isInFlightWatch] = useWatchAuctionLot();
  const [commitUnwatch, isInFlightUnwatch] = useUnwatchAuctionLot();

  const auctionLotId = data.id;
  const isViewerOwner = data.isViewerOwner;
  const isViewerWatching = data.isViewerWatching;
  const currentBidAmount = data.currentBidAmount;
  const startingAmount = data.startingAmount;
  const currentBidCurrency = data.currentBidCurrency;
  const shortDescription = data.shortDescription;
  const closesAt = data.closesAt;
  const fmvScore = data.product?.fmvScore;
  const fmvMinValue = fmvScore?.minValue ?? 0;
  const fmvMaxValue = fmvScore?.maxValue ?? 0;
  const fmvCurrency = fmvScore?.currency ?? "USD";

  const title =
    data.product?.comicDetails?.title ?? t("product.book.title-missing");
  const number =
    data.product?.comicDetails?.number ?? t("product.book.no-number");
  const grade = data.product?.comicDetails?.grade;
  const gradingAuthority = data.product?.comicDetails?.gradingAuthority;

  const imageUrl = (data.product?.images?.edges ?? [])[0]?.node?.url;

  const bidConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionItemBidSubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  const expiryConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionItemExpirySubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  useSubscription(bidConfig);
  useSubscription(expiryConfig);

  const handleClickWatch = () => {
    if (isInFlightWatch || isInFlightUnwatch || auctionLotId == null) {
      return;
    }

    const variables = {
      input: {
        auctionLotId,
      },
    };
    isViewerWatching
      ? commitUnwatch({
          optimisticResponse: {
            unwatchAuctionLot: {
              isViewerWatching: false,
            },
          },
          variables,
        })
      : commitWatch({
          optimisticResponse: {
            watchAuctionLot: {
              isViewerWatching: true,
            },
          },
          variables,
        });
  };

  return (
    <SBAuctionLotTile
      auctionLotId={auctionLotId}
      closesAt={closesAt}
      currentBidAmount={currentBidAmount}
      currentBidCurrency={currentBidCurrency}
      fmvCurrency={fmvCurrency}
      fmvMaxValue={fmvMaxValue}
      fmvMinValue={fmvMinValue}
      grade={grade}
      gradingAuthority={gradingAuthority}
      imageUrl={imageUrl}
      isViewerOwner={isViewerOwner}
      number={number}
      shortDescription={shortDescription}
      startingAmount={startingAmount}
      title={title}
      watching={isViewerWatching}
      watchingLoading={isInFlightWatch || isInFlightUnwatch}
      onClickWatch={handleClickWatch}
    />
  );
};

export default AuctionItem;
