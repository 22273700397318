/**
 * @generated SignedSource<<6c85ef8cf1afd6dea58b0956f54f0102>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CreateSubmissionView_product$data = {
  readonly comicDetails: {
    readonly gradingAuthority: GradingAuthority;
  } | null | undefined;
  readonly currency: Currency;
  readonly id: string;
  readonly " $fragmentType": "CreateSubmissionView_product";
};
export type CreateSubmissionView_product$key = {
  readonly " $data"?: CreateSubmissionView_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSubmissionView_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateSubmissionView_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "bea692bb546b515a83aa20e0bcc7ff42";

export default node;
