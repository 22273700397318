/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountShipFromAddressView_user$key } from "src/types/__generated__/SellerAccountShipFromAddressView_user.graphql";
import type { SellerAccountShipFromAddressViewCreateAddressMutation } from "src/types/__generated__/SellerAccountShipFromAddressViewCreateAddressMutation.graphql";
import type { Address } from "src/types/Address";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useCallback, useContext } from "react";
import { useMutation, useRefetchableFragment } from "react-relay";

import AccountAddressCard from "src/app/components/account/AccountAddressCard";
import AddressForm from "src/app/components/address-form/AddressForm";
import { UserContext } from "src/app/context/user";

const DEFAULT_ADDRESS_NAME = "DEFAULT SHIPPING";
const ADDRESS_TYPE = "SHIP_FROM";

type Props = Readonly<{
  queryKey: SellerAccountShipFromAddressView_user$key;
}>;

const SellerAccountShipFromAddressView = ({
  queryKey,
}: Props): React.ReactNode => {
  const { user } = useContext(UserContext);

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment SellerAccountShipFromAddressView_user on User
      @refetchable(queryName: "SellerAccountShipFromAddressViewRefreshQuery") {
        id
        addresses(where: { type: SHIP_FROM }) {
          edges {
            node {
              ...AccountAddressCard_address
              address1
              address2
              country
              familyName
              givenName
              id
              locality
              name
              postalCode
              region
              type
            }
          }
        }
      }
    `,
    queryKey,
  );

  const { addresses } = data;

  const shipFromAddress = addresses?.edges[0]?.node;

  const [commitCreate, isInFlightCreate] =
    useMutation<SellerAccountShipFromAddressViewCreateAddressMutation>(graphql`
      mutation SellerAccountShipFromAddressViewCreateAddressMutation(
        $input: CreateAddressInput!
        $connections: [ID!]!
      ) {
        createAddress(createAddressInput: $input)
          @prependNode(
            connections: $connections
            edgeTypeName: "AddressesEdge"
          ) {
          id
          ...AccountAddressCard_address
        }
      }
    `);

  const refresh = useCallback(() => {
    refetch({}, { fetchPolicy: "network-only" });
  }, [refetch]);

  const handleSubmitCreate = (address: Address) => {
    if (isInFlightCreate) {
      return;
    }
    commitCreate({
      onCompleted() {
        refresh();
      },
      variables: {
        connections: [],
        input: {
          address1: address.address1,
          address2: address.address2 === "" ? null : address.address2,
          country: address.country,
          familyName: address.familyName,
          givenName: address.givenName,
          locality: address.locality,
          name: DEFAULT_ADDRESS_NAME,
          postalCode: address.postalCode,
          region: address.region,
          type: ADDRESS_TYPE,
          userId: user?.userId ?? "",
        },
      },
    });
  };

  return (
    <div {...stylex.props(styles.root)}>
      {shipFromAddress ? (
        <AccountAddressCard
          isNameDisabled={true}
          queryKey={shipFromAddress}
          userConnectionId=""
        />
      ) : (
        <AddressForm
          initialAddress={{
            address1: "",
            address2: "",
            addressName: DEFAULT_ADDRESS_NAME,
            country: "US",
            familyName: "",
            givenName: "",
            isDefault: true,
            locality: "",
            postalCode: "",
            region: "",
            type: ADDRESS_TYPE,
          }}
          loading={isInFlightCreate}
          onSubmit={handleSubmitCreate}
        />
      )}
    </div>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  root: {},
  subtext: {
    fontSize: 12,
    marginTop: 8,
  },
});

export default SellerAccountShipFromAddressView;
