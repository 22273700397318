/**
 * @generated SignedSource<<f8fc35be0c211adb7aa75d1d9fc5397a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
export type SalesChannel = "AUCTION" | "SHOP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditProductView_product$data = {
  readonly comicDetails: {
    readonly barcode: string | null | undefined;
    readonly grade: number | null | undefined;
    readonly gradingAuthority: GradingAuthority;
    readonly label: {
      readonly id: string;
    };
  } | null | undefined;
  readonly currency: Currency;
  readonly id: string;
  readonly price: number;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly salesChannel: SalesChannel;
  readonly sellerPrivateNotes: string | null | undefined;
  readonly submissionNotes: string | null | undefined;
  readonly " $fragmentType": "EditProductView_product";
};
export type EditProductView_product$key = {
  readonly " $data"?: EditProductView_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditProductView_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditProductView_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sellerPrivateNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesChannel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "barcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Label",
          "kind": "LinkedField",
          "name": "label",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "011dd5ab2a3b6a6195a27b4329279181";

export default node;
