/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotView_auctionLot$key } from "src/types/__generated__/AuctionLotView_auctionLot.graphql";
import type { AuctionLotView_user$key } from "src/types/__generated__/AuctionLotView_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";

import CensusTable from "src/app/components/census/CensusTable";
import HistoricalSalesTable from "src/app/components/historical-sales/HistoricalSalesTable";
import CopyButton from "src/app/components/sharing/CopyButton";
import ShareButtons from "src/app/components/sharing/ShareButtons";
import { PageContext } from "src/app/context/page";
import { UserContext } from "src/app/context/user";
import {
  usePlatform,
  useUnwatchAuctionLot,
  useWatchAuctionLot,
} from "src/hooks";
import { SBIcon, SBImageCarousel, SBWatchAuctionLotIcon } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatGrade, formatTitle } from "src/utils";

import ReportProductIssueModal from "../product-detail/ReportProductIssueModal";
import AuctionLotDescription from "./AuctionLotDescription";
import AuctionLotHeader from "./AuctionLotHeader";
import AuctionLotMeta from "./AuctionLotMeta";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const POLL_TIME_MS = 500;

const AuctionLotViewBidSubscription = graphql`
  subscription AuctionLotViewBidSubscription(
    $input: BidAcceptedSubscriptionInput!
  ) {
    bidAcceptedSubscription(bidAcceptedSubscriptionInput: $input) {
      ...AuctionLotView_auctionLot
      ...BidNowView_auctionLot
    }
  }
`;

const AuctionLotViewExpirySubscription = graphql`
  subscription AuctionLotViewExpirySubscription($input: AuctionLotIdInput!) {
    auctionLotExpirySubscription(auctionLotExpirySubscription: $input) {
      ...AuctionLotView_auctionLot
      ...BidNowView_auctionLot
    }
  }
`;

type Props = Readonly<{
  queryKey: AuctionLotView_auctionLot$key;
  queryKeyUser: AuctionLotView_user$key | null | undefined;
}>;

const AuctionLotView = ({ queryKey, queryKeyUser }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const platform = usePlatform();

  const [commitWatch, isInFlightWatch] = useWatchAuctionLot();
  const [commitUnwatch, isInFlightUnwatch] = useUnwatchAuctionLot();

  const userData = useFragment(
    graphql`
      fragment AuctionLotView_user on User {
        ...AuctionLotHeader_user
      }
    `,
    queryKeyUser,
  );

  const data = useFragment(
    graphql`
      fragment AuctionLotView_auctionLot on AuctionLot {
        id
        isViewerOwner
        isViewerWatching
        currentBidAmount
        currentBidCurrency
        shareUrl
        ...AuctionLotDescription_auctionLot
        product {
          ...AuctionLotHeader_product
          ...AuctionLotMeta_product
          ...HistoricalSalesTable_product
          ...ReportProductIssueModal_product
          images {
            edges {
              node {
                url(quality: 100, webp: true, width: 1000)
              }
            }
          }
          comicDetails {
            ...CensusTable_comicDetail
            title
            publisher
            year
            number
            gradingAuthority
            grade
            keyComments
            artComments
          }
        }
      }
    `,
    queryKey,
  );

  const { id: auctionLotId, isViewerOwner, isViewerWatching, shareUrl } = data;

  const comicDetails = data.product?.comicDetails;
  const images = data.product?.images;
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const productTitle = `${formatTitle({
    number,
    t,
    title,
  })} ${formatGrade({ grade, gradingAuthority, t })}`;

  const [isReportProblemModalOpen, setIsReportProblemModalOpen] =
    useState(false);

  const pageContext = useContext(PageContext);
  const userContext = useContext(UserContext);

  const showReportButton = userContext.user != null && !isViewerOwner;
  const showWatchButton = userContext.user != null && !isViewerOwner;

  const bidConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionLotViewBidSubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  const expiryConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionLotViewExpirySubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  useSubscription(bidConfig);
  useSubscription(expiryConfig);

  const handleClickReport = (_event: React.SyntheticEvent) => {
    setIsReportProblemModalOpen(true);
  };

  const handleClickWatch = useCallback(() => {
    if (isInFlightWatch || isInFlightUnwatch || auctionLotId == null) {
      return;
    }
    const variables = {
      input: {
        auctionLotId,
      },
    };
    isViewerWatching
      ? commitUnwatch({
          variables,
        })
      : commitWatch({
          variables,
        });
  }, [
    auctionLotId,
    commitUnwatch,
    commitWatch,
    isInFlightUnwatch,
    isInFlightWatch,
    isViewerWatching,
  ]);

  useEffect(() => {
    pageContext?.setTitle(
      t("auctions.lot.document-title", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  const extraButtons = (
    <div {...stylex.props(styles.extraButtons)}>
      {showReportButton ? (
        <>
          <button
            {...stylex.props(styles.iconButton)}
            type="button"
            onClick={handleClickReport}
          >
            <SBIcon fill={false} icon="feedback" style={styles.icon} />
          </button>
          <div {...stylex.props(styles.separator)}></div>
        </>
      ) : null}
      <CopyButton
        icon={
          platform === "ios" || platform === "macos" ? "ios_share" : "share"
        }
        textToCopy={shareUrl}
      />
      <ShareButtons translationKey="product.share" url={shareUrl} />
      {showWatchButton ? (
        <>
          <div {...stylex.props(styles.separator)}></div>
          <div {...stylex.props(styles.watching)}>
            <SBWatchAuctionLotIcon
              loading={isInFlightWatch || isInFlightUnwatch}
              number={number ?? ""}
              style={styles.watching}
              title={title ?? ""}
              watching={isViewerWatching}
              onClick={handleClickWatch}
            />
          </div>
        </>
      ) : null}
    </div>
  );

  return (
    <>
      <div {...stylex.props(styles.product)}>
        <div {...stylex.props(styles.columns)}>
          <div {...stylex.props(styles.columnLeftMobile)}>
            {data.product != null ? (
              <AuctionLotHeader
                queryKey={data.product}
                queryKeyUser={userData}
              />
            ) : null}
            <div {...stylex.props(styles.carousel)}>
              <SBImageCarousel imageUrls={imageUrls} />
              {extraButtons}
            </div>
            {data.product ? <AuctionLotDescription queryKey={data} /> : null}
            {data.product ? <AuctionLotMeta queryKey={data.product} /> : null}
          </div>
          <div {...stylex.props(styles.columnRightMobile)}>
            {data.product ? (
              <HistoricalSalesTable queryKey={data.product} />
            ) : null}
            {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
          </div>
          <div {...stylex.props(styles.columnLeft)}>
            <div {...stylex.props(styles.carousel)}>
              <SBImageCarousel imageUrls={imageUrls} />
              {extraButtons}
            </div>
            {data.product ? <AuctionLotMeta queryKey={data.product} /> : null}
          </div>
          <div {...stylex.props(styles.columnRight)}>
            {data.product ? (
              <AuctionLotHeader
                queryKey={data.product}
                queryKeyUser={userData}
              />
            ) : null}
            {data.product ? <AuctionLotDescription queryKey={data} /> : null}
            {data.product ? (
              <HistoricalSalesTable queryKey={data.product} />
            ) : null}
            {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
          </div>
        </div>
      </div>
      {data.product != null && (
        <ReportProductIssueModal
          isOpen={isReportProblemModalOpen}
          queryKey={data.product}
          setOpen={setIsReportProblemModalOpen}
          onSuccess={() => {
            setIsReportProblemModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const styles = stylex.create({
  backButton: {
    marginBottom: 40,
  },
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  census: {
    marginTop: 40,
  },
  censusTable: {
    marginTop: 10,
  },
  columnLeft: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: {
      default: "1 / 1 / 2 / 6",
    },
    marginBottom: 24,
  },
  columnLeftMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columnRight: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: "1 / 7 / 2 / 11",
    marginBottom: 24,
  },
  columnRightMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columns: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(10, 1fr)",
      default: "repeat(10, 1fr)",
    },
    margin: 0,
  },
  extraButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 8,
  },
  icon: {
    color: colors.color,
  },
  iconButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginLeft: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 32,
    },
    padding: 0,
  },
  product: {
    marginBottom: 96,
  },
  separator: {
    borderBottomWidth: 0,
    borderColor: colors.colorMuted,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderTopWidth: 0,
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "block",
    },
    marginLeft: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 32,
    },
  },
  watching: {
    marginLeft: 12,
    marginRight: 12,
  },
});

export default AuctionLotView;
