/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReactNode } from "react";
import type { RequestSellerAccessModalCreateAddressMutation } from "src/types/__generated__/RequestSellerAccessModalCreateAddressMutation.graphql";
import type { RequestSellerAccessModalQuery } from "src/types/__generated__/RequestSellerAccessModalQuery.graphql";
import type { Address } from "src/types/Address";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery, useMutation } from "react-relay";

import SignupCode from "src/app/components/signup/SignupCode";
import SignupPhone from "src/app/components/signup/SignupPhone";
import { UserContext } from "src/app/context/user";
import {
  ButtonType,
  ModalWidth,
  SBButton,
  SBIcon,
  SBLink,
  SBModal,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import RequestSellerAccessAddress from "./RequestSellerAccessAddress";
import { RequestSellerAccessSuccess } from "./RequestSellerAccessSuccess";

const MOBILE = "@media (max-width: 767px)";

const FAQ_URL = "https://shortboxed.com/faq";
const PHONE_RE = /\D/gu;

type TIncomplete = Record<string, boolean>;
enum Step {
  address = "address",
  code = "code",
  main = "main",
  phone = "phone",
  success = "success",
}

const INCOMPLETE: TIncomplete = {
  address: false,
  phone: false,
};

const SECTIONS: Step[] = [Step.address, Step.phone];

type Props = Readonly<{
  isOpen: boolean;
  onSuccess: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

export const RequestSellerAccessModal = ({
  isOpen,
  setOpen,
  onSuccess: _,
}: Props): React.ReactNode => {
  const { user } = React.useContext(UserContext);
  const { t } = useTranslation();

  const userId = user?.userId;

  const [commitCreateAddress, isInFlightCreateAddress] =
    useMutation<RequestSellerAccessModalCreateAddressMutation>(graphql`
      mutation RequestSellerAccessModalCreateAddressMutation(
        $input: CreateAddressInput!
      ) {
        createAddress(createAddressInput: $input) {
          id
          name
          givenName
          familyName
          address1
          address2
          locality
          region
          postalCode
          country
          isDefault
        }
      }
    `);

  const [commitUpdateAddress, isInFlightUpdateAddress] = useMutation(graphql`
    mutation RequestSellerAccessModalUpdateAddressMutation(
      $input: UpdateAddressInput!
    ) {
      updateAddress(updateAddressInput: $input) {
        id
        name
        givenName
        familyName
        address1
        address2
        locality
        region
        postalCode
        country
        isDefault
      }
    }
  `);

  const [commitRequest, isInFlightRequest] = useMutation(graphql`
    mutation RequestSellerAccessModalMutation(
      $input: RequestSellerAccessInput!
    ) {
      requestSellerAccess(requestSellerAccessInput: $input) {
        sellerStatus
      }
    }
  `);

  const data = useLazyLoadQuery<RequestSellerAccessModalQuery>(
    graphql`
      query RequestSellerAccessModalQuery {
        viewer {
          user {
            ...RequestSellerAccessAddress_user
            id
            countryCode
            telephone
          }
        }
      }
    `,
    {},
  );

  const existingCountryCode = data.viewer?.user?.countryCode;
  const existingTelephone = data.viewer?.user?.telephone;

  const [step, setStep] = useState<Step>(Step.main);
  const [state, setState] = useState({
    countryCode: existingCountryCode == null ? "1" : `${existingCountryCode}`,
    phoneNumber: existingTelephone == null ? "" : `${existingTelephone}`,
  });
  const [completed, setCompleted] = useState(INCOMPLETE);
  const [isPhoneVerified, setIsPhoneVerified] = useState(
    existingCountryCode != null && existingTelephone != null,
  );
  const { countryCode, phoneNumber } = state;

  const onSavePhoneNumber = (
    newPhoneNumber: string,
    newCountryCode: string,
  ) => {
    setStep(Step.code);
    setState((prevState) => ({
      ...prevState,
      countryCode: newCountryCode,
      phoneNumber: newPhoneNumber,
    }));
  };

  const onSubmitCode = () => {
    setStep(Step.main);
    setIsPhoneVerified(true);
    setCompleted((prevState) => ({
      ...prevState,
      phone: phoneNumber !== "",
    }));
  };

  const onCreateAddress = ({
    address1,
    address2,
    addressId,
    addressName,
    country,
    familyName,
    givenName,
    isDefault,
    locality,
    postalCode,
    region,
    type,
  }: Address) => {
    if (addressId == null) {
      commitCreateAddress({
        onCompleted() {
          setStep(Step.main);
          setCompleted((prevState) => ({
            ...prevState,
            address: true,
          }));
        },
        variables: {
          input: {
            address1,
            address2: address2 === "" ? null : address2,
            country,
            familyName,
            givenName,
            isDefault,
            locality,
            name: addressName,
            postalCode,
            region,
            type,
            userId: userId ?? "",
          },
        },
      });
    } else {
      commitUpdateAddress({
        onCompleted() {
          setStep(Step.main);
          setCompleted((prevState) => ({
            ...prevState,
            address: true,
          }));
        },
        variables: {
          input: {
            address1,
            address2: address2 === "" ? null : address2,
            addressId,
            country,
            familyName,
            givenName,
            isDefault,
            locality,
            name: addressName,
            postalCode,
            region,
            type,
          },
        },
      });
    }
  };

  const onPressStep = (nextStep: Step) => () => {
    setStep(nextStep);
  };

  const onPressBackStep = () => {
    setStep(Step.main);
  };

  const onPressBackCode = () => {
    setStep(Step.phone);
  };

  const submitSellerRequest = () => {
    if (userId == null) {
      return;
    }

    const input =
      existingTelephone == null
        ? {
            countryCode: parseInt(countryCode, 10),
            telephone: parseInt(phoneNumber.replace(PHONE_RE, ""), 10),
            userId: userId ?? "",
          }
        : {
            userId: userId ?? "",
          };
    commitRequest({
      onCompleted() {
        setStep(Step.success);
      },
      updater(proxyStore) {
        const record = userId == null ? null : proxyStore.get(userId);
        record?.invalidateRecord();
      },
      variables: {
        input,
      },
    });
  };

  const allFieldsComplete = () => {
    return isPhoneVerified && completed[Step.address] === true;
  };

  const getModalContent = (whichStep: Step): ReactNode => {
    switch (whichStep) {
      case Step.address:
        return (
          data?.viewer?.user && (
            <RequestSellerAccessAddress
              loading={isInFlightCreateAddress || isInFlightUpdateAddress}
              queryKey={data?.viewer?.user}
              onSubmit={onCreateAddress}
            />
          )
        );
      case Step.phone:
        return <SignupPhone onSubmit={onSavePhoneNumber} />;
      case Step.code:
        return (
          <SignupCode
            country={state.countryCode}
            phoneNumber={state.phoneNumber}
            onSubmit={onSubmitCode}
          />
        );
      case Step.success:
        return <RequestSellerAccessSuccess />;
      case Step.main:
      default:
        return (
          <div>
            <div style={styles.body}>
              <SBParagraph>
                {t("request-seller-access.description")}
              </SBParagraph>
              <SBParagraph style={styles.disclaimer}>
                {t("request-seller-access.disclaimer.start")}
                <SBLink external={true} href={FAQ_URL}>
                  {t("request-seller-access.disclaimer.link")}
                </SBLink>
                {t("request-seller-access.disclaimer.end")}
              </SBParagraph>
              {SECTIONS.map(
                (item) =>
                  ((item === "phone" && shouldShowPhone) ||
                    item !== "phone") && (
                    <div
                      key={item}
                      {...stylex.props(styles.step)}
                      onClick={onPressStep(item as Step)}
                    >
                      <SBParagraph>
                        {t(`request-seller-access.step.${item}`)}
                      </SBParagraph>
                      <SBIcon
                        icon={
                          completed[item]
                            ? "check_circle"
                            : "add_circle_outline"
                        }
                        style={[
                          styles.icon,
                          completed[item] ? styles.completed : styles.todo,
                        ]}
                      />
                    </div>
                  ),
              )}
            </div>
          </div>
        );
    }
  };

  const shouldShowPhone = existingTelephone == null;

  return (
    <SBModal
      footer={
        ![Step.success].includes(step) && (
          <div {...stylex.props(styles.modalFooter)}>
            <SBButton
              disabled={!allFieldsComplete()}
              loading={isInFlightRequest}
              style={styles.button}
              title={t("request-seller-access.button.sign-up")}
              type={ButtonType.Submit}
              onClick={submitSellerRequest}
            />
          </div>
        )
      }
      headerText={t("request-seller-access.title")}
      isOpen={isOpen}
      setOpen={setOpen}
      width={
        [Step.phone, Step.code].includes(step)
          ? ModalWidth.LARGE
          : ModalWidth.MEDIUM
      }
    >
      <div {...stylex.props(styles.modal)}>
        <div {...stylex.props(styles.root)}>
          <div>
            {step !== Step.main && (
              <div {...stylex.props(styles.backWrap)}>
                <button
                  {...stylex.props(auto, styles.back)}
                  aria-label={t("request-seller-access.back-label")}
                  type="button"
                  onClick={
                    step === Step.code ? onPressBackCode : onPressBackStep
                  }
                >
                  <SBIcon
                    aria-hidden={true}
                    fill={false}
                    icon="arrow_back"
                    style={styles.icon}
                  />
                </button>
              </div>
            )}
            {getModalContent(step)}
          </div>
        </div>
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  back: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    marginRight: {
      [MOBILE]: 8,
      default: 0,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  backWrap: {
    marginBottom: 24,
  },
  body: {
    display: "flex",
  },
  button: {
    marginTop: 24,
  },
  buttonContainer: {
    flexDirection: "column",
    marginTop: 10,
  },
  completed: {
    color: colors.successBackgroundColor,
  },
  container: {
    display: "flex",
  },
  disclaimer: {
    marginTop: 12,
    textAlign: "center",
  },
  disclaimerLink: {
    textDecorationLine: "underline",
  },
  heading: {
    display: "flex",
    marginRight: 32,
    textAlign: "center",
  },
  icon: {
    color: colors.color,
    height: 24,
    width: 24,
  },
  inner: {
    display: "flex",
    justifyContent: "space-around",
    padding: 24,
  },
  intro: {
    marginTop: 24,
    textAlign: "center",
  },
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  root: {
    display: "flex",
    justifyContent: "center",
  },
  row: {
    alignItems: "center",
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 24,
    paddingTop: 24,
  },
  step: {
    alignContent: "center",
    backgroundColor: {
      ":hover": colors.infoBackgroundColor,
      default: colors.backgroundEmphasisColor,
    },
    borderColor: colors.borderColor,
    borderStyle: "solid",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    fontWeight: 600,
    justifyContent: "space-between",
    marginBottom: 8,
    padding: 16,
    paddingBottom: 0,
    textDecoration: "none",
  },
  todo: {
    color: colors.color,
  },
});
