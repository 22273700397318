/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  SendToAuctionView_auctionSearch$data,
  SendToAuctionView_auctionSearch$key,
} from "src/types/__generated__/SendToAuctionView_auctionSearch.graphql";
import type { SendToAuctionView_product$key } from "src/types/__generated__/SendToAuctionView_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation, usePaginationFragment } from "react-relay";

import { FAQS_PATH } from "src/app/router/Router";
import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBErrorMessage,
  SBHeading,
  SBIcon,
  SBLink,
  SBParagraph,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

const HELLO_EMAIL = "hello@shortboxed.com";

type Item = NonNullable<
  SendToAuctionView_auctionSearch$data["auctionSearch"]["edges"][0]
>;

enum Step {
  RequestOrList,
  ConfirmRequest,
  ConfirmList,
  SelectAuction,
}

type Props = Readonly<{
  auctionSearchQueryKey: SendToAuctionView_auctionSearch$key;
  queryKey: SendToAuctionView_product$key;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const SendToAuctionView = ({
  auctionSearchQueryKey,
  queryKey,
  setOpen,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const longDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const [commitRequest, isRequestInFlight] = useMutation(graphql`
    mutation SendToAuctionViewRequestMutation(
      $input: CreateProductAuctionConsignmentRequestInput!
    ) {
      createProductAuctionConsignmentRequest(
        createProductAuctionConsignmentRequestInput: $input
      ) {
        product {
          ...ProductDetailView_product
          ...SendToAuctionView_product
        }
      }
    }
  `);

  const [commitList, isListInFlight] = useMutation(graphql`
    mutation SendToAuctionViewListMutation(
      $input: CreateCommunityAuctionLotInput!
    ) {
      createCommunityAuctionLot(createCommunityAuctionLotInput: $input) {
        product {
          ...ProductDetailView_product
          ...SendToAuctionView_product
        }
      }
    }
  `);

  const productData = useFragment(
    graphql`
      fragment SendToAuctionView_product on Product {
        id
        canRequestAuctionConsignment
        canSubmitToCommunityAuctions
      }
    `,
    queryKey,
  );

  const { data } = usePaginationFragment(
    graphql`
      fragment SendToAuctionView_auctionSearch on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 20 }
        cursor: { type: "String" }
      )
      @refetchable(queryName: "SendToAuctionViewPaginationQuery") {
        auctionSearch(
          first: $count
          after: $cursor
          where: { filter: OPEN_SUBMISSIONS, type: [COMMUNITY] }
        ) @connection(key: "SendToAuctionView_auctionSearch") {
          edges {
            node {
              id
              title
              tagline
              biddingStartsAt
              userAuctionStatus {
                submittable
              }
            }
          }
        }
      }
    `,
    auctionSearchQueryKey,
  );

  const productId = productData.id;

  const canRequestAuctionConsignment =
    productData.canRequestAuctionConsignment ?? false;
  const canSubmitToCommunityAuctions =
    productData.canSubmitToCommunityAuctions ?? false;

  const auctions = data.auctionSearch?.edges ?? [];

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [step, setStep] = useState(() => {
    if (canRequestAuctionConsignment && !canSubmitToCommunityAuctions) {
      return Step.ConfirmRequest;
    }
    if (!canRequestAuctionConsignment && canSubmitToCommunityAuctions) {
      return Step.ConfirmList;
    }
    return Step.RequestOrList;
  });

  const [selectedAuction, setSelectedAuction] = useState(
    () =>
      auctions.find(({ node }) => node?.userAuctionStatus?.submittable)?.node,
  );

  const handleClickRequest = (_event: React.SyntheticEvent) => {
    setStep(Step.ConfirmRequest);
  };

  const handleClickSend = (_event: React.SyntheticEvent) => {
    setStep(Step.ConfirmList);
  };

  const handleSelectAuction = (_event: React.SyntheticEvent) => {
    setErrorMessage(null);
    setStep(Step.SelectAuction);
  };

  const handleClickAuctionItem =
    (item: Item) => (_event: React.SyntheticEvent) => {
      if (!item.node.userAuctionStatus?.submittable) {
        return;
      }
      setSelectedAuction(item.node);
      setStep(Step.ConfirmList);
    };

  const handleClickConfirmSendRequest = (_event: React.SyntheticEvent) => {
    if (selectedAuction == null) {
      return;
    }
    if (isRequestInFlight) {
      return;
    }
    commitRequest({
      onCompleted() {
        setOpen(false);
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          productId,
        },
      },
    });
  };

  const handleClickConfirmSend = (_event: React.SyntheticEvent) => {
    if (selectedAuction == null) {
      return;
    }
    if (isListInFlight) {
      return;
    }
    commitList({
      onCompleted() {
        setOpen(false);
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          auctionId: selectedAuction.id,
          productId,
        },
      },
    });
  };

  switch (step) {
    case Step.ConfirmRequest:
      return (
        <div {...stylex.props(styles.confirm)}>
          <SBParagraph style={styles.communityHelp}>
            {t("send-to-auction-modal.help.start")}
            <SBLink external={true} to={`${FAQS_PATH}#AUCTIONS`}>
              {t("send-to-auction-modal.help.link")}
            </SBLink>
            {t("send-to-auction-modal.help.end")}
          </SBParagraph>
          <SBParagraph style={styles.confirmDisclaimer}>
            {t("send-to-auction-modal.consignment.disclaimer")}
          </SBParagraph>
          <div {...stylex.props(styles.confirmButtons)}>
            <SBButton
              block={true}
              loading={isRequestInFlight}
              title={t("send-to-auction-modal.buttons.request")}
              variation={ButtonVariation.Emphasis}
              onClick={handleClickConfirmSendRequest}
            />
          </div>
        </div>
      );
    case Step.SelectAuction:
      return (
        <>
          {auctions.map((item) => (
            <button
              key={item.node.id}
              {...stylex.props(
                styles.auction,
                !item.node?.userAuctionStatus?.submittable &&
                  styles.auctionDisabled,
              )}
              onClick={handleClickAuctionItem(item)}
            >
              <div {...stylex.props(styles.auctions)}>
                <SBParagraph style={styles.auctionTitle}>
                  {item.node.title}
                </SBParagraph>
                {Boolean(item.node.tagline) ? (
                  <SBParagraph style={styles.tagline}>
                    {item.node.tagline}
                  </SBParagraph>
                ) : null}
                {item.node.biddingStartsAt != null && (
                  <SBParagraph style={styles.biddingStartsAt}>
                    {t("send-to-auction-modal.send.bidding-starts", {
                      date: longDateFormatter.format(
                        Number.parseInt(item.node.biddingStartsAt, 10),
                      ),
                    })}
                  </SBParagraph>
                )}
              </div>
              <SBIcon icon="chevron_right" style={styles.icon} />
            </button>
          ))}
        </>
      );
    case Step.ConfirmList:
      return (
        <div {...stylex.props(styles.container)}>
          <div {...stylex.props(errorMessage != null && styles.errorMessage)}>
            <SBErrorMessage message={errorMessage} />
          </div>
          <SBParagraph style={styles.communityHelp}>
            {t("send-to-auction-modal.help.start")}
            <SBLink external={true} to={`${FAQS_PATH}#AUCTIONS`}>
              {t("send-to-auction-modal.help.link")}
            </SBLink>
            {t("send-to-auction-modal.help.end")}
          </SBParagraph>
          {auctions.length < 1 || selectedAuction == null ? null : (
            <div {...stylex.props(styles.row)}>
              <SBHeading level={HeadingLevel.H2} style={styles.label}>
                {t("send-to-auction-modal.select-auction.title")}
              </SBHeading>
              {selectedAuction != null && (
                <div {...stylex.props(styles.value)}>
                  <SBParagraph style={styles.auctionTitle}>
                    {selectedAuction.title}
                  </SBParagraph>
                  {Boolean(selectedAuction.tagline) ? (
                    <SBParagraph style={styles.tagline}>
                      {selectedAuction.tagline}
                    </SBParagraph>
                  ) : null}
                  {selectedAuction.biddingStartsAt != null && (
                    <SBParagraph style={styles.biddingStartsAt}>
                      {t("send-to-auction-modal.send.bidding-starts", {
                        date: longDateFormatter.format(
                          Number.parseInt(selectedAuction.biddingStartsAt, 10),
                        ),
                      })}
                    </SBParagraph>
                  )}
                </div>
              )}
              <button
                {...stylex.props(styles.editButton)}
                onClick={handleSelectAuction}
              >
                <SBIcon icon="edit" style={styles.icon} />
              </button>
            </div>
          )}
          {auctions.length < 1 || selectedAuction == null ? null : (
            <SBParagraph style={styles.communityDisclaimer}>
              {t("send-to-auction-modal.community.disclaimer")}
            </SBParagraph>
          )}
          {auctions.length < 1 || selectedAuction == null ? (
            <SBParagraph style={styles.communityDisclaimer}>
              {t("send-to-auction-modal.no-community-auctions")}
            </SBParagraph>
          ) : null}
          <SBParagraph style={styles.communityDisclaimer}>
            {t("send-to-auction-modal.host-a-community-auction.line1")}
            <SBLink to={`mailto:${HELLO_EMAIL}`}>
              {t("send-to-auction-modal.host-a-community-auction.line2")}
            </SBLink>
            {t("send-to-auction-modal.host-a-community-auction.line3")}
          </SBParagraph>
          <div {...stylex.props(styles.buttons)}>
            <SBButton
              block={true}
              loading={isListInFlight}
              title={t("send-to-auction-modal.buttons.send")}
              variation={ButtonVariation.Emphasis}
              onClick={handleClickConfirmSend}
            />
          </div>
        </div>
      );
    case Step.RequestOrList:
      return (
        <div {...stylex.props(styles.container)}>
          <div {...stylex.props(styles.buttons)}>
            {canRequestAuctionConsignment ? (
              <SBButton
                block={true}
                loading={isListInFlight}
                title={t("send-to-auction-modal.buttons.request")}
                variation={ButtonVariation.Emphasis}
                onClick={handleClickRequest}
              />
            ) : null}
            {canRequestAuctionConsignment && canSubmitToCommunityAuctions ? (
              <div {...stylex.props(styles.break)}>
                <div {...stylex.props(styles.hr)} />
                <SBParagraph style={styles.or}>
                  {t("send-to-auction-modal.or")}
                </SBParagraph>
                <div {...stylex.props(styles.hr)} />
              </div>
            ) : null}
            {canSubmitToCommunityAuctions ? (
              <SBButton
                block={true}
                loading={isListInFlight}
                title={t("send-to-auction-modal.buttons.send")}
                onClick={handleClickSend}
              />
            ) : null}
          </div>
        </div>
      );
    default:
      return null;
  }
};

const styles = stylex.create({
  auction: {
    alignItems: "center",
    backgroundColor: "transparent",
    borderBottomColor: colors.borderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 16,
    width: "100%",
  },
  auctionDisabled: {
    opacity: 0.6,
  },
  auctionTitle: {
    fontWeight: "bold",
    marginBottom: 4,
    textAlign: "left",
  },
  auctions: {
    width: "100%",
  },
  biddingStartsAt: {
    color: colors.colorMuted,
    fontSize: 14,
    marginBottom: 0,
    textAlign: "left",
  },
  break: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  buttons: {
    width: "100%",
  },
  communityDisclaimer: {
    color: colors.colorMuted,
    fontSize: 14,
  },
  communityHelp: {
    marginBottom: 0,
    paddingBottom: 16,
    textAlign: "center",
    width: "100%",
  },
  confirm: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBlock: 16,
  },
  confirmButtons: {
    boxSizing: "border-box",
    paddingInline: 16,
    width: "100%",
  },
  confirmDisclaimer: {
    borderTopColor: colors.borderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    color: colors.colorMuted,
    fontSize: 14,
    paddingInline: 16,
    paddingTop: 16,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 16,
  },
  editButton: {
    backgroundColor: "transparent",
    borderWidth: 0,
    cursor: "pointer",
  },
  errorMessage: {
    boxSizing: "border-box",
    marginBottom: 16,
    width: "100%",
  },
  eventDisclaimer: {
    borderTopColor: colors.borderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  hr: {
    backgroundColor: colors.borderColor,
    display: "flex",
    flexGrow: 1,
    height: 1,
  },
  icon: {
    color: colors.color,
  },
  inputError: {
    color: colors.colorEmphasis,
    fontSize: 14,
    marginBottom: 4,
  },
  label: {
    fontSize: 28,
    marginRight: 8,
    width: "25%",
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 16,
  },
  or: {
    fontSize: 14,
    marginBlock: 16,
    marginInline: 8,
  },
  row: {
    alignItems: "flex-start",
    borderBottomColor: colors.borderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderTopColor: colors.borderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    padding: 16,
    width: "100%",
  },
  tagline: {
    marginBottom: 4,
    textAlign: "left",
  },
  value: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

export default SendToAuctionView;
