/**
 * @generated SignedSource<<10526498e1b95bbcdd169eee291bf82f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsOwner_product$data = {
  readonly id: string;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsOwnerAuction_product" | "ProductDetailActionsOwnerDefault_product">;
  readonly " $fragmentType": "ProductDetailActionsOwner_product";
};
export type ProductDetailActionsOwner_product$key = {
  readonly " $data"?: ProductDetailActionsOwner_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsOwner_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsOwner_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsOwnerDefault_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsOwnerAuction_product"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "58c71cfd19addeb82a92eb988403729e";

export default node;
