/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { API_CLIENT_ID } from "src/app/constants";
import { UserContext } from "src/app/context/user";
import { HOME_PATH } from "src/app/router/Router";
import { useLogin } from "src/hooks/_hooks/useLogin";
import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBErrorMessage,
  SBHeading,
  SBParagraph,
} from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  email: string;
  isKiosk?: boolean;
  password: string;
}>;

const SignupSuccess = ({
  email,
  password,
  isKiosk = false,
}: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [commit] = useLogin();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { logIn } = useContext(UserContext);

  const handleStartOverClick = () => {
    window.location.reload();
  };

  const handleClickBrowse = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsLoading(true);
    commit({
      onCompleted(loginData) {
        logIn(loginData.login);
        navigate(HOME_PATH);
        setIsLoading(false);
      },
      onError(error: unknown) {
        setIsLoading(false);
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ?? t("signup.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("signup.errors.http429");
        }
        setErrorMessage(message);
      },
      variables: {
        input: {
          clientId: API_CLIENT_ID,
          email,
          password,
        },
      },
    });
  };

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H1} style={styles.title}>
        {t("signup.success.title")}
      </SBHeading>
      <SBErrorMessage message={errorMessage} style={styles.error} />
      <SBParagraph style={styles.description}>
        {t("signup.success.description")}
      </SBParagraph>
      <div>
        {isKiosk ? (
          <SBButton
            block={true}
            loading={isLoading}
            title={t("signup.success.start-over")}
            type={ButtonType.Button}
            variation={ButtonVariation.Emphasis}
            onClick={handleStartOverClick}
          />
        ) : (
          <SBButton
            block={true}
            loading={isLoading}
            title={t("signup.success.browse")}
            type={ButtonType.Button}
            variation={ButtonVariation.Emphasis}
            onClick={handleClickBrowse}
          />
        )}
      </div>
    </div>
  );
};

const styles = stylex.create({
  description: {
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
  error: {
    marginBottom: 16,
  },
  root: {
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 48,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
  title: {
    fontSize: 24,
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
});

export default SignupSuccess;
