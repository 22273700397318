/**
 * @generated SignedSource<<515408aee3ba350d458af1531a6f6935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RequestSellerAccessModalQuery$variables = Record<PropertyKey, never>;
export type RequestSellerAccessModalQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly countryCode: number | null | undefined;
      readonly id: string;
      readonly telephone: number | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"RequestSellerAccessAddress_user">;
    } | null | undefined;
  };
};
export type RequestSellerAccessModalQuery = {
  response: RequestSellerAccessModalQuery$data;
  variables: RequestSellerAccessModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countryCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telephone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestSellerAccessModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RequestSellerAccessAddress_user"
              },
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RequestSellerAccessModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": {
                      "isDefault": "DESC"
                    }
                  },
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "isDefault": true,
                      "type": "SHIP_FROM"
                    }
                  }
                ],
                "concreteType": "AddressesConnection",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "givenName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "familyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locality",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "region",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "addresses(first:1,orderBy:{\"isDefault\":\"DESC\"},where:{\"isDefault\":true,\"type\":\"SHIP_FROM\"})"
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              },
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "829f53234c18a5834e63ace1518a9828",
    "id": null,
    "metadata": {},
    "name": "RequestSellerAccessModalQuery",
    "operationKind": "query",
    "text": "query RequestSellerAccessModalQuery {\n  viewer {\n    user {\n      ...RequestSellerAccessAddress_user\n      id\n      countryCode\n      telephone\n    }\n  }\n}\n\nfragment RequestSellerAccessAddress_user on User {\n  addresses(first: 1, orderBy: {isDefault: DESC}, where: {type: SHIP_FROM, isDefault: true}) {\n    edges {\n      node {\n        id\n        name\n        givenName\n        familyName\n        address1\n        address2\n        locality\n        region\n        postalCode\n        country\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2db38dc08e672e95639b8e02d63a502e";

export default node;
