/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountAddressCard_address$key } from "src/types/__generated__/AccountAddressCard_address.graphql";
import type { AccountAddressCardDeleteAddressMutation } from "src/types/__generated__/AccountAddressCardDeleteAddressMutation.graphql";
import type { AccountAddressCardEditAddressMutation } from "src/types/__generated__/AccountAddressCardEditAddressMutation.graphql";
import type { Address } from "src/types/Address";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionHandler, useFragment, useMutation } from "react-relay";

import AddressForm from "src/app/components/address-form/AddressForm";
import { SBIcon, SBModal } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

import AccountInfoCard from "./AccountInfoCard";

const US_MILITARY_REGIONS = ["APO", "FPO", "DPO"];

type Props = Readonly<{
  isNameDisabled?: boolean;
  queryKey: AccountAddressCard_address$key;
  userConnectionId: string;
}>;

const AccountAddressCard = ({
  queryKey,
  userConnectionId,
  isNameDisabled = false,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = useFragment(
    graphql`
      fragment AccountAddressCard_address on Address {
        address1
        address2
        country
        familyName
        givenName
        id
        isDefault
        locality
        name
        postalCode
        region
        type
      }
    `,
    queryKey,
  );

  const [commitEdit, isInFlightEdit] =
    useMutation<AccountAddressCardEditAddressMutation>(graphql`
      mutation AccountAddressCardEditAddressMutation(
        $input: UpdateAddressInput!
      ) {
        updateAddress(updateAddressInput: $input) {
          id
          isDefault
          ...AccountAddressCard_address
        }
      }
    `);

  const [commitDelete, isInFlightDelete] =
    useMutation<AccountAddressCardDeleteAddressMutation>(graphql`
      mutation AccountAddressCardDeleteAddressMutation(
        $input: DeleteAddressInput!
      ) {
        deleteAddress(deleteAddressInput: $input) {
          id @deleteRecord
        }
      }
    `);

  const {
    address1,
    address2,
    country,
    familyName,
    givenName,
    id,
    isDefault,
    locality,
    name,
    postalCode,
    region,
    type,
  } = data;

  const handleSubmitEdit = (address: Address) => {
    if (address.addressId == null) {
      return;
    }
    commitEdit({
      onCompleted() {
        setIsModalOpen(false);
      },
      onError(_error) {
        // not sure what to do here
      },
      updater(proxyStore, response) {
        const record = proxyStore.get(userConnectionId);
        if (record == null) {
          return;
        }

        const isNewAddressDefault = response?.updateAddress.isDefault ?? false;

        if (isNewAddressDefault) {
          const addressBookConnection = ConnectionHandler.getConnection(
            record,
            "AccountAddressesView_addresses",
            {
              orderBy: {
                isDefault: "DESC",
              },
              where: {
                type: "SHIPPING",
              },
            },
          );
          if (addressBookConnection != null) {
            const sectionsEdge =
              addressBookConnection.getLinkedRecords("edges");
            let hasFoundMatch = false;
            for (const edge of sectionsEdge ?? []) {
              const nodeRecord = edge?.getLinkedRecord("node");
              const previousId = nodeRecord?.getValue("id");
              const isSameAddress = response?.updateAddress.id === previousId;
              if (!isSameAddress) {
                const isPreviousDefault =
                  nodeRecord?.getValue("isDefault") ?? false;
                if (isPreviousDefault) {
                  nodeRecord?.setValue(false, "isDefault");
                  hasFoundMatch = true;
                }
              }
              if (hasFoundMatch) {
                break;
              }
            }
          }
        }
      },
      variables: {
        input: {
          address1: address.address1,
          address2: address.address2 === "" ? null : address.address2,
          addressId: address.addressId,
          country: address.country,
          familyName: address.familyName,
          givenName: address.givenName,
          isDefault: address.isDefault,
          locality: address.locality,
          name: address.addressName,
          postalCode: address.postalCode,
          region: address.region,
          type: address.type,
        },
      },
    });
  };

  const handleClickEdit = () => {
    setIsModalOpen(true);
  };

  const handleClickDelete = () => {
    if (
      window.confirm(
        t("account.addresses.delete.title", {
          addressName: name,
        }),
      )
    ) {
      commitDelete({
        variables: {
          input: {
            addressId: id,
          },
        },
      });
    }
  };

  const hideCountry =
    (country === "US" && US_MILITARY_REGIONS.includes(region)) ||
    i18n.language.includes(country);

  return (
    <div {...stylex.props(styles.root)}>
      <AccountInfoCard
        content={
          <>
            <div {...stylex.props(styles.content)}>
              {t("name.format.full", {
                familyName,
                givenName,
              })}
            </div>
            <div {...stylex.props(styles.content)}>{address1}</div>
            {address2 ? (
              <div {...stylex.props(styles.content)}>{address2}</div>
            ) : null}
            <div {...stylex.props(styles.content)}>
              {t("order-details.address-formatted", {
                locality,
                postalCode,
                region,
              })}
            </div>
            {hideCountry ? null : (
              <div {...stylex.props(styles.content)}>
                {t(`countries.${kebabCase(country)}`)}
              </div>
            )}
          </>
        }
        heading={
          isDefault
            ? t("account.addresses.default", {
                addressName: name,
              })
            : name
        }
      />

      <SBModal headerText={name} isOpen={isModalOpen} setOpen={setIsModalOpen}>
        <div {...stylex.props(styles.modal)}>
          <AddressForm
            anchor="bottom start"
            initialAddress={{
              address1,
              address2,
              addressId: id,
              addressName: name,
              country,
              familyName,
              givenName,
              isDefault,
              locality,
              postalCode,
              region,
              type,
            }}
            initialDefault={isDefault}
            isNameDisabled={isNameDisabled}
            loading={isInFlightEdit}
            onSubmit={handleSubmitEdit}
          />
        </div>
      </SBModal>
      <div {...stylex.props(styles.actions)}>
        {!isDefault ? (
          <button
            {...stylex.props(styles.deleteButton)}
            disabled={isInFlightEdit || isInFlightDelete}
            type="button"
            onClick={handleClickDelete}
          >
            <SBIcon icon="delete" style={styles.icon} />
          </button>
        ) : null}
        <button
          {...stylex.props(styles.editButton)}
          disabled={isInFlightEdit || isInFlightDelete}
          type="button"
          onClick={handleClickEdit}
        >
          <SBIcon icon="edit" style={styles.icon} />
        </button>
      </div>
    </div>
  );
};

const styles = stylex.create({
  actions: {
    alignItems: "flex-end",
    bottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    right: 10,
    zIndex: 10,
  },
  content: {
    marginBottom: 4,
  },
  deleteButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginRight: 16,
    padding: 0,
  },
  editButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  icon: {
    color: colors.color,
  },
  modal: {
    padding: 16,
  },
  root: {
    position: "relative",
  },
});

export default AccountAddressCard;
