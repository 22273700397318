/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { AUCTIONS_PATH } from "src/app/router/Router";
import { SBLink } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

export const AuctionLotsSeeMore = (): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <SBLink href={AUCTIONS_PATH} style={styles.button}>
      {t("home.auctions.see-more")}
    </SBLink>
  );
};

const styles = stylex.create({
  button: {
    color: colors.colorMuted,
    fontSize: 16,
    fontWeight: 400,
    textDecoration: "none",
  },
  icon: {
    color: colors.color,
  },
});

export default AuctionLotsSeeMore;
