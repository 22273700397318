/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ProductDetailActionsOwner_product$key,
  ProductPublicStatus,
} from "src/types/__generated__/ProductDetailActionsOwner_product.graphql";
import type { SellerStatus } from "src/types/__generated__/QueriesProductDetailQuery.graphql";

import * as React from "react";
import { graphql, useFragment } from "react-relay";

import ProductDetailActionsOwnerAuction from "./ProductDetailActionsOwnerAuction";
import ProductDetailActionsOwnerDefault from "./ProductDetailActionsOwnerDefault";

const AUCTION_STATUSES = [
  "PENDING_AUCTION_START",
  "PENDING_AUCTION_END",
  "PENDING_AUCTION_CONSIGNMENT_REVIEW",
  "PENDING_AUCTION_CONSIGNMENT_SHIPPING",
];

type Props = Readonly<{
  employee: boolean;
  queryKey: ProductDetailActionsOwner_product$key;
  sellerStatus: SellerStatus | null | undefined;
}>;

const ProductDetailActionsOwner = ({
  employee,
  queryKey,
  sellerStatus,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment ProductDetailActionsOwner_product on Product {
        id
        publicStatus
        ...ProductDetailActionsOwnerDefault_product
        ...ProductDetailActionsOwnerAuction_product
      }
    `,
    queryKey,
  );

  const productId = data?.id;
  const publicStatus: ProductPublicStatus = data?.publicStatus ?? "UNAVAILABLE";

  if (productId == null) {
    return null;
  }

  if (AUCTION_STATUSES.includes(publicStatus)) {
    return (
      <ProductDetailActionsOwnerAuction employee={employee} queryKey={data} />
    );
  }

  if (publicStatus === "UNAVAILABLE") {
    return (
      <ProductDetailActionsOwnerDefault
        employee={employee}
        queryKey={data}
        sellerStatus={sellerStatus}
      />
    );
  }

  return null;
};

export default ProductDetailActionsOwner;
