/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  APP_STORE_IMG,
  APP_STORE_URL,
  GOOGLE_PLAY_IMG,
  GOOGLE_PLAY_URL,
  QR_CODE_IMG,
} from "src/app/constants";
import { usePlatform } from "src/hooks";
import {
  ButtonVariation,
  SBButton,
  SBImage,
  SBModal,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

enum Modal {
  None,
  AppDownload,
}

const PortfolioSubmit = (): React.ReactNode => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(Modal.None);

  const platform = usePlatform();

  const showAndroid = platform === "android";
  const showIphone = platform === "ios";

  const showDesktop = platform === "macos" || platform === "windows";

  const handleClick = (_event: React.SyntheticEvent) => {
    setModal(Modal.AppDownload);
  };

  const setOpen = () => {
    setModal((prevModal) =>
      prevModal === Modal.None ? Modal.AppDownload : Modal.None,
    );
  };

  return (
    <>
      <SBButton
        title={t("portfolio.buttons.submit-comics")}
        variation={ButtonVariation.Emphasis}
        onClick={handleClick}
      />
      <SBModal
        headerText={t("portfolio.download.title")}
        isOpen={modal === Modal.AppDownload}
        setOpen={setOpen}
      >
        <>
          <SBParagraph style={styles.paragraph}>
            {t("portfolio.download.description")}
          </SBParagraph>
          <div {...stylex.props(styles.downloadButtons)}>
            {showDesktop ? (
              <SBImage
                alt={t("home.qr-code")}
                height={156}
                src={QR_CODE_IMG}
                width={156}
              />
            ) : null}
            {showIphone ? (
              <a
                {...stylex.props(styles.link)}
                aria-label={t("home.download-ios")}
                href={APP_STORE_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SBImage
                  alt={t("home.app-store")}
                  height={64}
                  src={APP_STORE_IMG}
                  width={150}
                />
              </a>
            ) : null}
            {showAndroid ? (
              <a
                {...stylex.props(auto, styles.link)}
                aria-label={t("home.download-android")}
                href={GOOGLE_PLAY_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SBImage
                  alt={t("home.google-play")}
                  height={64}
                  src={GOOGLE_PLAY_IMG}
                  width={165}
                />
              </a>
            ) : null}
          </div>
        </>
      </SBModal>
    </>
  );
};

const styles = stylex.create({
  downloadButtons: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBlock: 16,
    marginInline: 16,
  },
  link: {
    borderRadius: 8,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  paragraph: {
    marginBottom: 0,
    marginInline: 16,
    marginTop: 16,
    textAlign: "center",
  },
});

export default PortfolioSubmit;
