/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// import i18n (needs to be bundled ;))
import "./i18n/i18n";

import HyperDX from "@hyperdx/browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { StrictMode, Suspense, useEffect } from "react";
import { RelayEnvironmentProvider } from "react-relay";

import { AuctionLotSearchProvider } from "src/app/context/auction-lot-search";
import { OrderDetailProvider } from "src/app/context/order-detail";
import { PageProvider } from "src/app/context/page";
import { PortfolioProvider } from "src/app/context/portfolio";
import { UserProvider } from "src/app/context/user";
import { VerticalSearchProvider } from "src/app/context/vertical-search";
import ErrorBoundary from "src/app/pages/error-boundary/ErrorBoundary";
import RelayEnvironment from "src/app/router/RelayEnvironment";
import Router from "src/app/router/Router";
import { SBFallback } from "src/sbxui";

import { SellerStatsProvider } from "./app/context/seller-stats";

// cSpell:disable
const GOOGLE_CLIENT_ID =
  "1087580832466-tidf59h0dgi4igkcutuou0jpssgslcqs.apps.googleusercontent.com";
// cSpell:enable

const App = (): React.ReactNode => {
  useEffect(() => {
    HyperDX.init({
      apiKey: "673ae638-33a9-44ba-917d-798e218bd575",

      // Capture console logs (default false)
      consoleCapture: true,

      // Capture full HTTP request/response headers and bodies (default false)
      advancedNetworkCapture: false,

      service: "shortboxed.com",
      // Set to link traces from frontend to backend requests
      tracePropagationTargets: [/graph.shortboxed.com/iu],

      disableIntercom: true,
      maskAllInputs: true,
      maskAllText: true,
    });
  }, []);

  return (
    <StrictMode>
      <ErrorBoundary>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <VerticalSearchProvider>
            <PortfolioProvider>
              <AuctionLotSearchProvider>
                <OrderDetailProvider>
                  <SellerStatsProvider>
                    <PageProvider>
                      <UserProvider>
                        <RelayEnvironmentProvider
                          environment={RelayEnvironment}
                        >
                          <Suspense fallback={<SBFallback />}>
                            <main {...stylex.props(styles.main)} id="main">
                              <Router />
                            </main>
                          </Suspense>
                        </RelayEnvironmentProvider>
                      </UserProvider>
                    </PageProvider>
                  </SellerStatsProvider>
                </OrderDetailProvider>
              </AuctionLotSearchProvider>
            </PortfolioProvider>
          </VerticalSearchProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

const styles = stylex.create({
  main: {
    fontSize: 16,
    height: "100%",
    textSizeAdjust: "100%",
  },
});

export default App;
