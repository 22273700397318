/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReviewOffersContentQuery } from "src/types/__generated__/ReviewOffersContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import ReviewOffersView from "./ReviewOffersView";

type Props = Readonly<{
  productId: string | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const ReviewOffersContent = ({
  productId,
  setOpen,
}: Props): React.ReactNode | null => {
  const data = useLazyLoadQuery<ReviewOffersContentQuery>(
    graphql`
      query ReviewOffersContentQuery($id: ID!) {
        node(id: $id) {
          ... on Product {
            ...ReviewOffersView_product
          }
        }
      }
    `,
    {
      id: productId ?? "",
    },
  );

  if (data.node == null) {
    return null;
  }

  return <ReviewOffersView queryKey={data.node} setOpen={setOpen} />;
};

export default ReviewOffersContent;
