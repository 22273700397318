/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { HistoricalSalesTable_product$key } from "src/types/__generated__/HistoricalSalesTable_product.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import HistoricalSalesChart from "src/app/components/historical-sales/HistoricalSalesChart";
import { useFootnotes } from "src/hooks";
import { SBCollapsibleTable, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

type Props = Readonly<{
  queryKey: HistoricalSalesTable_product$key;
}>;

const HistoricalSalesTable = ({ queryKey }: Props): React.ReactNode | null => {
  const { i18n, t } = useTranslation();

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        month: "short",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment HistoricalSalesTable_product on Product
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 5 }
      )
      @refetchable(queryName: "HistoricalSalesTablePaginationQuery") {
        comicDetails {
          grade
          gradingAuthority
        }
        historicalSales(
          after: $cursor
          first: $count
          orderBy: { saleDate: DESC }
        ) @connection(key: "HistoricalSalesTable_historicalSales") {
          edges {
            node {
              id
              source
              saleDate
              saleFormat
              price
              currency
            }
          }
        }
      }
    `,
    queryKey,
  );

  const grade = data.comicDetails?.grade;
  const gradingAuthority = data.comicDetails?.gradingAuthority;

  const historicalSales = data.historicalSales?.edges ?? [];

  const { attribution, footnotes } = useFootnotes(
    historicalSales.map(({ node: { source } }) => source),
  );

  const content = historicalSales
    .filter(({ node }) => node != null)
    .map(({ node: { currency, id, price, saleDate, saleFormat, source } }) => ({
      caption: dateFormatter.format(new Date(saleDate * 1000)),
      footnote: attribution[source],
      id,
      label: t("product.previous-sale", {
        saleFormat: t(`sale-format.${kebabCase(saleFormat)}`),
        source: t(`sources.${kebabCase(source)}`),
      }),
      value: formatMoney(price, currency, i18n.language),
    }));

  return (
    <>
      <SBCollapsibleTable
        boldFirstRow={true}
        chart={<HistoricalSalesChart data={data} />}
        content={content}
        footer={footnotes}
        hasNext={hasNext}
        isLoadingNext={isLoadingNext}
        loadNext={loadNext}
        showEmpty={true}
        title={
          content.length === 0
            ? t("product.no-history.title")
            : t("product.previous-sales", {
                grade:
                  grade === 0
                    ? t("raw-grades.0-0")
                    : (grade?.toFixed(1) ?? "ng"),
                gradingAuthority: t("grading-authority.cgc"),
              })
        }
      />
      {gradingAuthority !== "CGC" && historicalSales.length > 0 && (
        <div {...stylex.props(auto, styles.disclaimer)}>
          <SBParagraph>
            {t(`product.disclaimers.${kebabCase(gradingAuthority)}`)}
          </SBParagraph>
        </div>
      )}
    </>
  );
};

const styles = stylex.create({
  disclaimer: {
    borderBottomWidth: 0,
    borderColor: colors.navBorderCurrentColor,
    borderLeftWidth: 2,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    marginTop: 24,
    paddingLeft: 16,
  },
});

export default HistoricalSalesTable;
