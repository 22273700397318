/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountStoreNameField_user$key } from "src/types/__generated__/SellerAccountStoreNameField_user.graphql";
import type { SellerAccountStoreNameFieldMutation } from "src/types/__generated__/SellerAccountStoreNameFieldMutation.graphql";
import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import { ButtonType, SBButton, SBTextInput, TextInputType } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: SellerAccountStoreNameField_user$key;
}>;

const SellerAccountStoreNameField = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountStoreNameField_user on User {
        id
        shopName
      }
    `,
    queryKey,
  );

  const { shopName, id: userId } = data;

  const isShopNameNotSet = Boolean(shopName);

  const [newShopName, setNewShopName] = useState<string>(shopName ?? "");

  const [commit, isInFlight] = useMutation<SellerAccountStoreNameFieldMutation>(
    graphql`
      mutation SellerAccountStoreNameFieldMutation(
        $input: UpdateSellerShopNameInput!
      ) {
        updateSellerShopName(updateSellerShopNameInput: $input) {
          ...SellerAccountStoreNameField_user
        }
      }
    `,
  );

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }
    commit({
      onCompleted() {
        toast.info(t("seller-account.success.shop-name"), {
          position: "top-center",
        });
      },
      onError(error: unknown) {
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ?? t("signup.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("signup.errors.http429");
        }
        toast.error(message, {
          position: "top-center",
        });
      },
      variables: {
        input: {
          shopName: newShopName,
          userId,
        },
      },
    });
  };

  const handleChangeShopName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewShopName(event.currentTarget.value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <fieldset {...stylex.props(styles.formFields)}>
          <div {...stylex.props(styles.nameFields)}>
            <SBTextInput
              disabled={isInFlight || isShopNameNotSet}
              id="newShopName"
              label={t("seller-account.field.store-name")}
              showLabel={true}
              style={[styles.input, styles.inputFirst]}
              type={TextInputType.Text}
              value={newShopName}
              onChange={handleChangeShopName}
            />
          </div>
          <div {...stylex.props(styles.button)}>
            <SBButton
              disabled={isInFlight || isShopNameNotSet}
              loading={isInFlight}
              title={t("seller-account.buttons.store-name")}
              type={ButtonType.Submit}
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  emailField: {
    marginBottom: 16,
  },
  formFields: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  input: {
    color: colors.color,
    marginBottom: 16,
  },
  inputFirst: {
    marginRight: 16,
  },
  message: {
    marginBottom: 16,
  },
  nameFields: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default SellerAccountStoreNameField;
