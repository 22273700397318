/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountOfferSettingsView_user$key } from "src/types/__generated__/SellerAccountOfferSettingsView_user.graphql";
import type { SellerAccountOfferSettingsViewMutation } from "src/types/__generated__/SellerAccountOfferSettingsViewMutation.graphql";
import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import {
  ButtonType,
  SBButton,
  SBParagraph,
  SBRadioButtons,
  SBTextInput,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

const PERCENT_RE = /\D/gu;
const PERCENT_MAX_LENGTH = 2;
const PERCENT_DEFAULT = 75;

// stubbed here for now as i18next doesn't have same method as i18n-js (native)
const numberToPercentage = (
  value: number,
  _opts: { precision: number },
): string => value.toString();

type Props = Readonly<{
  queryKey: SellerAccountOfferSettingsView_user$key;
}>;

const SellerAccountOfferSettingsView = ({
  queryKey,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountOfferSettingsView_user on User {
        id
        minOfferPercentage
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] =
    useMutation<SellerAccountOfferSettingsViewMutation>(graphql`
      mutation SellerAccountOfferSettingsViewMutation(
        $input: UpdateSellerMinOfferPercentageInput!
      ) {
        updateSellerMinOfferPercentage(
          updateSellerMinOfferPercentageInput: $input
        ) {
          ... on User {
            minOfferPercentage
            ...SellerAccountOfferSettingsView_user
          }
        }
      }
    `);

  const userId = data.id;
  const minOfferPercentage = data.minOfferPercentage ?? PERCENT_DEFAULT;

  const [newMinOfferPercentage, setNewMinOfferPercentage] = useState<string>(
    numberToPercentage(minOfferPercentage, {
      precision: 0,
    }),
  );
  const [shouldAllowOffers, setShouldAllowOffers] = useState(
    minOfferPercentage < 100 ? "YES" : "NO",
  );

  const radioOptions = [
    {
      label: t("seller-account.offer-settings.yes"),
      value: "YES",
    },
    {
      label: t("seller-account.offer-settings.no"),
      value: "NO",
    },
  ];

  const onSelectAllowOffers = (option: string) => {
    switch (option) {
      case "YES":
      case "NO":
        setShouldAllowOffers(option);
        setNewMinOfferPercentage(
          minOfferPercentage > 99
            ? numberToPercentage(PERCENT_DEFAULT, {
                precision: 0,
              })
            : numberToPercentage(minOfferPercentage, {
                precision: 0,
              }),
        );
        break;
    }

    if (option === "NO") {
      setNewMinOfferPercentage("100");
    }
  };

  const onChangeMinOfferPercentage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewMinOfferPercentage(event.currentTarget.value.replace(PERCENT_RE, ""));
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (userId == null) {
      return;
    }
    commit({
      onCompleted() {
        toast.info(t("seller-account.success.offer-settings"), {
          position: "top-center",
        });
      },
      onError(error: unknown) {
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ?? t("signup.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("signup.errors.http429");
        }
        toast.error(message, {
          position: "top-center",
        });
      },
      variables: {
        input: {
          minOfferPercentage: parseInt(
            newMinOfferPercentage.replace(PERCENT_RE, ""),
            10,
          ),
          userId,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div {...stylex.props(styles.top)}>
        <SBParagraph style={styles.question}>
          {t("seller-account.offer-settings.allow-offers")}
        </SBParagraph>
        <div>
          <SBRadioButtons
            id="allowOffers"
            options={radioOptions}
            value={shouldAllowOffers}
            onChange={onSelectAllowOffers}
          />
        </div>
      </div>
      {shouldAllowOffers === "YES" && (
        <SBTextInput
          id="minOfferPercentage"
          label={t("seller-account.offer-settings.percentage-input")}
          maxLength={PERCENT_MAX_LENGTH}
          showLabel={true}
          style={styles.input}
          type="number"
          value={newMinOfferPercentage}
          onChange={onChangeMinOfferPercentage}
        />
      )}
      <div {...stylex.props(styles.button)}>
        <SBButton
          disabled={isInFlight}
          loading={isInFlight}
          title={t("seller-account.buttons.offer-settings")}
          type={ButtonType.Submit}
        />
      </div>
    </form>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  hide: {
    display: "none",
  },
  input: {
    color: colors.color,
  },
  message: {
    marginBottom: 16,
  },
  percentageInput: {},
  question: {
    fontWeight: 600,
  },
  show: {
    display: "block",
  },
  top: {
    borderBottomColor: colors.borderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    marginBottom: 24,
    paddingBottom: 24,
  },
});

export default SellerAccountOfferSettingsView;
