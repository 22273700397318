/**
 * @generated SignedSource<<6812108b3677bdf36fba420dae115d23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferReason = "COUNTER_FROM_BUYER" | "COUNTER_FROM_SELLER" | "FAVORITE" | "NONE" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewOffersCompletedCard_offer$data = {
  readonly currency: Currency;
  readonly expiresAt: string;
  readonly id: string;
  readonly price: number;
  readonly reason: OfferReason;
  readonly status: OfferStatus;
  readonly toUserId: string | null | undefined;
  readonly " $fragmentType": "ReviewOffersCompletedCard_offer";
};
export type ReviewOffersCompletedCard_offer$key = {
  readonly " $data"?: ReviewOffersCompletedCard_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewOffersCompletedCard_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewOffersCompletedCard_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

(node as any).hash = "26afaf97663ddf81f48c0c0af1f0aa9e";

export default node;
