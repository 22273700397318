/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ShopView_verticalSearch$key } from "src/types/__generated__/ShopView_verticalSearch.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect, useRef, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import SearchResultCard from "src/app/components/search-result-card/SearchResultCard";
import { VerticalSearchContext } from "src/app/context/vertical-search";
import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator, SBParagraph } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: ShopView_verticalSearch$key;
}>;

export const SHOP_QUERY_LIMIT = 24;

const ShopView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const {
    categoryRollups,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    priceMax,
    priceMin,
    salesChannel,
    setIsSearching,
    shopUrl,
    sort,
    specialCopies,
    searchTerm,
    yearMax,
    yearMin,
  } = useContext(VerticalSearchContext);

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment(
      graphql`
        fragment ShopView_verticalSearch on Query
        @argumentDefinitions(
          categoryRollups: { type: "[CategoryRollup!]" }
          count: { type: "Int", defaultValue: 24 }
          cursor: { type: "String", defaultValue: "" }
          gradeMax: { type: Float, defaultValue: null }
          gradeMin: { type: Float, defaultValue: null }
          gradingAuthorities: { type: "[GradingAuthority!]" }
          priceMax: { type: Int, defaultValue: null }
          priceMin: { type: Int, defaultValue: null }
          salesChannel: {
            type: "[SalesChannel!]"
            defaultValue: [AUCTION, SHOP]
          }
          searchTerm: { type: "String", defaultValue: null }
          shopUrl: { type: "String", defaultValue: null }
          specialCopies: { type: "[SpecialCopy!]" }
          vertical: { type: "Vertical", defaultValue: COMIC }
          yearMax: { type: Int, defaultValue: null }
          yearMin: { type: Int, defaultValue: null }
          orderBy: {
            type: VerticalSearchOrderByInput
            defaultValue: { createdAt: DESC }
          }
        )
        @refetchable(queryName: "ShopPaginationQuery") {
          __id
          verticalSearch(
            vertical: $vertical
            after: $cursor
            first: $count
            orderBy: $orderBy
            where: {
              categoryRollups: $categoryRollups
              gradeMax: $gradeMax
              gradeMin: $gradeMin
              gradingAuthorities: $gradingAuthorities
              priceMax: $priceMax
              priceMin: $priceMin
              salesChannel: $salesChannel
              searchTerm: $searchTerm
              shopUrl: $shopUrl
              specialCopies: $specialCopies
              yearMax: $yearMax
              yearMin: $yearMin
            }
          ) @connection(key: "ShopView_verticalSearch") {
            __id
            edges {
              node {
                id
                ...SearchResultCard_typesenseSearch
              }
            }
          }
        }
      `,
      queryKey,
    );

  const connectionId = data.__id;

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(SHOP_QUERY_LIMIT);
    }
  };

  const [isPending, startTransition] = useTransition();

  useIntersectionObserver(endRef, handleInfiniteScroll);

  useEffect(() => {
    const splitSort = sort.split("-");
    const orderBy = { [splitSort[0]]: splitSort[1].toUpperCase() };

    setIsSearching(true);

    startTransition(() => {
      refetch(
        {
          categoryRollups:
            categoryRollups.length === 0 ? null : categoryRollups,
          gradeMax,
          gradeMin,
          gradingAuthorities:
            gradingAuthorities.length === 0 ? null : gradingAuthorities,
          orderBy,
          priceMax,
          priceMin,
          salesChannel,
          searchTerm,
          shopUrl,
          specialCopies: specialCopies.length === 0 ? null : specialCopies,
          yearMax,
          yearMin,
        },
        {
          fetchPolicy: "network-only",
          onComplete: () => {
            setIsSearching(false);
            window.scrollTo(0, 0);
          },
        },
      );
    });
  }, [
    categoryRollups,
    gradeMin,
    gradeMax,
    gradingAuthorities,
    priceMax,
    priceMin,
    refetch,
    salesChannel,
    setIsSearching,
    sort,
    specialCopies,
    searchTerm,
    shopUrl,
    yearMax,
    yearMin,
  ]);

  const results = data?.verticalSearch?.edges ?? [];

  return (
    <>
      {!isPending && results.length === 0 ? (
        <SBParagraph>{t("shop.no-results")}</SBParagraph>
      ) : (
        <ul {...stylex.props(styles.products)}>
          {results?.map(({ node }) => (
            <SearchResultCard
              key={node.id}
              connectionId={connectionId}
              queryKey={node}
            />
          ))}
        </ul>
      )}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  products: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(4, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,
    padding: 0,
  },
});

export default ShopView;
