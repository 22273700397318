/**
 * @generated SignedSource<<98a2d76da06756700056bb4434aa37f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSubmissionContentQuery$variables = {
  id: string;
};
export type CreateSubmissionContentQuery$data = {
  readonly hotConfigs: {
    readonly data: any | null | undefined;
  };
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CreateSubmissionView_product">;
  } | null | undefined;
};
export type CreateSubmissionContentQuery = {
  response: CreateSubmissionContentQuery$data;
  variables: CreateSubmissionContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "HotConfigForGraphQL",
  "kind": "LinkedField",
  "name": "hotConfigs",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "data",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSubmissionContentQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CreateSubmissionView_product"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSubmissionContentQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComicDetail",
                "kind": "LinkedField",
                "name": "comicDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be84c992c26a9243c6df09de081c5fcb",
    "id": null,
    "metadata": {},
    "name": "CreateSubmissionContentQuery",
    "operationKind": "query",
    "text": "query CreateSubmissionContentQuery(\n  $id: ID!\n) {\n  hotConfigs {\n    data\n  }\n  node(id: $id) {\n    __typename\n    ...CreateSubmissionView_product\n    id\n  }\n}\n\nfragment CreateSubmissionView_product on Product {\n  id\n  comicDetails {\n    gradingAuthority\n    id\n  }\n  currency\n}\n"
  }
};
})();

(node as any).hash = "3add371c1398b3040b5e59209174f0bd";

export default node;
