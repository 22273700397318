/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type * as React from "react";
import type { ReviewOffersReceivedCardCounter_offer$key } from "src/types/__generated__/ReviewOffersReceivedCardCounter_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import { UserContext } from "src/app/context/user";
import { useCountdownTimer } from "src/hooks";
import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBLink,
  SBParagraph,
  SBTextInput,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

import { Step } from "./ReviewOffersReceivedCard";

enum CounterStep {
  Counter,
  Confirm,
  None,
}

type Props = Readonly<{
  queryKey: ReviewOffersReceivedCardCounter_offer$key;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}>;

const ReviewOffersReceivedCardCounter = ({
  queryKey,
  setStep,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const [commit, isInFlight] = useMutation(graphql`
    mutation ReviewOffersReceivedCardCounterMutation(
      $input: CounterOfferInput!
    ) {
      counterOffer(counterOfferInput: $input) {
        ... on Offer {
          ...ReviewOffersReceivedCardCounter_offer
          ...ReviewOffersReceivedCard_offer
          product {
            ...ProductDetailView_product
            ...ReviewOffersSent_product
          }
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment ReviewOffersReceivedCardCounter_offer on Offer {
        id
        price
        currency
        status
        expiresAt
        toUserId
        product {
          price
          minOfferPrice
          minOfferPercentage
          offerCounts {
            pending
            received
            sent
          }
        }
      }
    `,
    queryKey,
  );

  const offer = data;
  const offerId = data.id;
  const offerPrice = offer?.price;
  const askingPrice = offer?.product?.price;
  const minOfferPrice = offer?.product?.minOfferPrice;
  const minOfferPercentage = offer?.product?.minOfferPercentage;
  const toUserId = offer?.toUserId;
  const currency = offer?.currency;
  const status = offer?.status;
  const expiresAt = offer?.expiresAt;
  const expiry = Number(expiresAt);
  const { color, expiresIn } = useCountdownTimer(expiry);

  const [counterStep, setCounterStep] = useState(() => {
    if (status === "IN_REVIEW" && toUserId === userId) {
      return CounterStep.Counter;
    }

    return CounterStep.None;
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newOfferPrice, setNewOfferPrice] = useState("");

  const handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setNewOfferPrice(event.currentTarget.value);
    setErrorMessage(null);
  };

  const handleClickBack = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setNewOfferPrice("");
    setStep(Step.Review);
  };

  const handleClickCounterReview = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (Number(newOfferPrice) >= Number(askingPrice)) {
      setErrorMessage(t("make-offer.errors.too-high"));
      return;
    }
    if (Number(offerPrice) >= Number(newOfferPrice)) {
      setErrorMessage(t("make-offer.errors.too-low-previous"));
      return;
    }
    if (minOfferPrice != null) {
      if (Number(newOfferPrice) < Number(minOfferPrice)) {
        setErrorMessage(
          t("make-offer.errors.too-low.price", {
            value: formatMoney(minOfferPrice, currency, i18n.language),
          }),
        );
        return;
      }
    } else if (minOfferPercentage != null) {
      if (
        Number(newOfferPrice) <
        (Number(minOfferPercentage) / 100) * Number(askingPrice)
      ) {
        setErrorMessage(
          t("make-offer.errors.too-low.percent", {
            value: minOfferPercentage,
          }),
        );
        return;
      }
    }
    setNewOfferPrice(newOfferPrice);
    setCounterStep(CounterStep.Confirm);
  };

  const handleClickConfirm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    commit({
      onCompleted() {
        toast.info(t("make-offer.offer.sent"), {
          position: "top-center",
        });
        setCounterStep(CounterStep.None);
      },
      onError(error) {
        setStep(Step.Counter);
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("offers.submit.errors.default"),
        );
      },
      variables: {
        input: {
          offerId,
          price: Number(newOfferPrice),
        },
      },
    });
  };

  let content = null;
  if (counterStep === CounterStep.Counter) {
    content = (
      <>
        <div {...stylex.props(auto, styles.cardHeader)}>
          <SBHeading level={HeadingLevel.H2}>
            {offerPrice} {currency}
          </SBHeading>
          <div {...stylex.props(auto, styles.offerText)}>
            <SBParagraph>{t("review-offers.offer.text")}</SBParagraph>
          </div>
          <SBParagraph style={styles.expiresIn(color)}>{expiresIn}</SBParagraph>
        </div>
        <SBTextInput
          errorMessage={errorMessage}
          id="offer"
          inputStyle={styles.inputStyle}
          label={t("product.make-offer")}
          maxLength={7}
          placeholder={t("forms.fields.price")}
          type="number"
          value={newOfferPrice}
          onChange={handleOfferChange}
        />
        <SBButton
          block={true}
          disabled={false}
          style={styles.button}
          title={t("review-offers.button.counter-review")}
          variation={ButtonVariation.Default}
          onClick={handleClickCounterReview}
        />
        <SBLink style={styles.back} onClick={handleClickBack}>
          {t("common.buttons.back")}
        </SBLink>
      </>
    );
  } else if (counterStep === CounterStep.Confirm) {
    content = (
      <>
        <div {...stylex.props(auto, styles.cardHeader)}>
          <SBHeading level={HeadingLevel.H2}>
            {newOfferPrice} {currency}
          </SBHeading>
          <div {...stylex.props(auto, styles.offerText)}>
            <SBParagraph>{t("review-offers.offer.counter.text")}</SBParagraph>
          </div>
          <SBParagraph style={styles.oldOffer}>
            {offerPrice} {currency}
          </SBParagraph>
        </div>
        <SBButton
          block={true}
          disabled={false}
          loading={isInFlight}
          style={styles.button}
          title={t("review-offers.button.counter-confirm")}
          variation={ButtonVariation.Default}
          onClick={handleClickConfirm}
        />
        <SBLink style={styles.back} onClick={handleClickBack}>
          {t("common.buttons.back")}
        </SBLink>
      </>
    );
  }

  return content;
};

const styles = stylex.create({
  back: {
    color: colors.color,
    fontSize: 16,
    marginTop: 24,
  },
  button: {
    marginTop: 8,
  },
  cardHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 16,
  },
  expiresIn: (color: string) => ({
    color,
    fontSize: 14,
    margin: 0,
    marginRight: 24,
    marginTop: 4,
  }),

  inputStyle: {
    textAlign: "center",
  },

  offerText: {
    marginTop: 4,
  },
  oldOffer: {
    marginBottom: 0,
    textDecoration: "line-through",
  },
});

export default ReviewOffersReceivedCardCounter;
