/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReviewOffersReceivedCard_offer$key } from "src/types/__generated__/ReviewOffersReceivedCard_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { UserContext } from "src/app/context/user";
import { useCountdownTimer } from "src/hooks";
import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ReviewOffersReceivedCardAccept from "./ReviewOffersReceivedCardAccept";
import ReviewOffersReceivedCardCounter from "./ReviewOffersReceivedCardCounter";
import ReviewOffersReceivedCardDecline from "./ReviewOffersReceivedCardDecline";

export enum Step {
  Review,
  Accept,
  // ConfirmAccept,
  ConfirmDecline,
  Counter,
  None,
}

type Props = Readonly<{
  queryKey: ReviewOffersReceivedCard_offer$key;
}>;

const ReviewOffersReceivedCard = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const data = useFragment(
    graphql`
      fragment ReviewOffersReceivedCard_offer on Offer {
        id
        price
        currency
        status
        toUserId
        expiresAt
        product {
          price
          minOfferPrice
          minOfferPercentage
        }
        ...ReviewOffersReceivedCardCounter_offer
        ...ReviewOffersReceivedCardAccept_offer
        ...ReviewOffersReceivedCardDecline_offer
      }
    `,
    queryKey,
  );

  const offer = data;
  const offerPrice = offer?.price;
  const status = offer?.status;
  const toUserId = offer?.toUserId;
  const currency = offer?.currency;
  const expiresAt = offer?.expiresAt;
  const expiry = Number(expiresAt);
  const { color, expiresIn } = useCountdownTimer(expiry);

  const [step, setStep] = useState(() => {
    if (status === "IN_REVIEW" && toUserId === userId) {
      return Step.Review;
    }

    if (status === "ACCEPTED" && toUserId === userId) {
      return Step.Accept;
    }

    return Step.None;
  });

  const handleClickAcceptReview = (_event: React.SyntheticEvent) => {
    setStep(Step.Accept);
  };

  const handleClickDeclineReview = (_event: React.SyntheticEvent) => {
    setStep(Step.ConfirmDecline);
  };

  const handleClickCounter = (_event: React.SyntheticEvent) => {
    setStep(Step.Counter);
  };

  let content = null;
  if (step === Step.Review) {
    content = (
      <>
        <div {...stylex.props(auto, styles.cardHeader)}>
          <SBHeading level={HeadingLevel.H2}>
            {offerPrice} {currency}
          </SBHeading>
          <div {...stylex.props(auto, styles.offerText)}>
            <SBParagraph>{t("review-offers.offer.text")}</SBParagraph>
          </div>
          <SBParagraph style={styles.expiresIn(color)}>{expiresIn}</SBParagraph>
        </div>
        <SBButton
          block={true}
          style={styles.button}
          title={t("review-offers.button.accept")}
          variation={ButtonVariation.Emphasis}
          onClick={handleClickAcceptReview}
        />
        <SBButton
          block={true}
          style={styles.button}
          title={t("review-offers.button.decline")}
          variation={ButtonVariation.Default}
          onClick={handleClickDeclineReview}
        />
        <SBButton
          block={true}
          style={styles.button}
          title={t("review-offers.button.counter")}
          variation={ButtonVariation.Default}
          onClick={handleClickCounter}
        />
      </>
    );
  } else if (step === Step.Accept) {
    content = (
      <ReviewOffersReceivedCardAccept queryKey={data} setStep={setStep} />
    );
  } else if (step === Step.ConfirmDecline) {
    content = (
      <ReviewOffersReceivedCardDecline queryKey={data} setStep={setStep} />
    );
  } else if (step === Step.Counter) {
    content = (
      <ReviewOffersReceivedCardCounter queryKey={data} setStep={setStep} />
    );
  }

  return (
    <div {...stylex.props(auto, styles.card)}>
      <div {...stylex.props(auto, styles.buttons)}>{content}</div>
    </div>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 8,
  },
  buttons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  card: {
    backgroundColor: colors.backgroundColor,
    borderColor: colors.color,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "block",
    marginBottom: 16,
    padding: 16,
    width: "90%",
  },
  cardHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 16,
  },
  expiresIn: (color: string) => ({
    color,
    fontSize: 14,
    margin: 0,
    marginRight: 24,
    marginTop: 4,
  }),
  offerResolved: {
    marginBottom: 0,
  },
  offerText: {
    marginTop: 4,
  },
});

export default ReviewOffersReceivedCard;
