/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type * as React from "react";
import type { ReviewOffersReceivedCardDecline_offer$key } from "src/types/__generated__/ReviewOffersReceivedCardDecline_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import { UserContext } from "src/app/context/user";
import { useCountdownTimer } from "src/hooks";
import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBLink,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { Step } from "./ReviewOffersReceivedCard";

type Props = Readonly<{
  queryKey: ReviewOffersReceivedCardDecline_offer$key;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
}>;

const ReviewOffersReceivedCardDecline = ({
  queryKey,
  setStep,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const [commit, isInFlight] = useMutation(graphql`
    mutation ReviewOffersReceivedCardDeclineMutation(
      $input: DeclineOfferInput!
    ) {
      declineOffer(declineOfferInput: $input) {
        ... on Offer {
          ...ReviewOffersReceivedCardDecline_offer
          product {
            ...ProductDetailView_product
            ...ReviewOffersCompleted_product
            ...ReviewOffersReceived_product
          }
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment ReviewOffersReceivedCardDecline_offer on Offer {
        id
        price
        currency
        status
        expiresAt
        toUserId
        product {
          price
          offerCounts {
            pending
            received
            sent
          }
        }
      }
    `,
    queryKey,
  );

  const offer = data;
  const offerId = data.id;
  const offerPrice = offer?.price;
  const toUserId = offer?.toUserId;
  const currency = offer?.currency;
  const status = offer?.status;
  const expiresAt = offer?.expiresAt;
  const expiry = Number(expiresAt);
  const { color, expiresIn } = useCountdownTimer(expiry);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClickBack = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setStep(Step.Review);
  };

  const handleClickDecline = (event: React.SyntheticEvent) => {
    event.preventDefault();
    commit({
      onCompleted() {
        toast.info(t("make-offer.offer.declined"), {
          position: "top-center",
        });
      },
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("offers.submit.errors.default"),
        );
      },
      variables: {
        input: {
          offerId,
        },
      },
    });
  };

  let content = null;
  if (status === "IN_REVIEW" && toUserId === userId) {
    content = (
      <>
        <div {...stylex.props(auto, styles.cardHeader)}>
          <SBHeading level={HeadingLevel.H2}>
            {offerPrice} {currency}
          </SBHeading>
          <div {...stylex.props(auto, styles.offerText)}>
            <SBParagraph>{t("review-offers.offer.text")}</SBParagraph>
          </div>
          <SBParagraph style={styles.expiresIn(color)}>{expiresIn}</SBParagraph>
        </div>
        <SBButton
          block={true}
          loading={isInFlight}
          style={styles.button}
          title={t("review-offers.button.decline-confirm")}
          variation={ButtonVariation.Default}
          onClick={handleClickDecline}
        />
        {errorMessage ? (
          <SBParagraph style={styles.errorMessage}>{errorMessage}</SBParagraph>
        ) : null}
        <SBLink style={styles.back} onClick={handleClickBack}>
          {t("common.buttons.back")}
        </SBLink>
      </>
    );
  }

  return content;
};

const styles = stylex.create({
  back: {
    color: colors.color,
    fontSize: 16,
    marginTop: 24,
  },
  button: {
    marginTop: 8,
  },
  buttons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 16,
  },
  errorMessage: {
    color: colors.colorEmphasis,
    paddingTop: 16,
  },
  expiresIn: (color: string) => ({
    color,
    fontSize: 14,
    margin: 0,
    marginRight: 24,
    marginTop: 4,
  }),
  offerText: {
    marginTop: 4,
  },
});

export default ReviewOffersReceivedCardDecline;
