/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountLabelSizeView_user$key } from "src/types/__generated__/SellerAccountLabelSizeView_user.graphql";
import type { SellerAccountLabelSizeViewMutation } from "src/types/__generated__/SellerAccountLabelSizeViewMutation.graphql";
import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import { ButtonType, SBButton, SBRadioButtons } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: SellerAccountLabelSizeView_user$key;
}>;

const SellerAccountLabelSizeView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountLabelSizeView_user on User {
        id
        labelSize
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] = useMutation<SellerAccountLabelSizeViewMutation>(
    graphql`
      mutation SellerAccountLabelSizeViewMutation(
        $input: UpdateViewerShippingLabelInput!
      ) {
        updateUserShippingLabel(updateViewerShippingLabelInput: $input) {
          ... on User {
            labelSize
            ...SellerAccountLabelSizeView_user
          }
        }
      }
    `,
  );

  const { id: userId, labelSize } = data;

  const [newLabelSize, setNewLabelSize] = useState(labelSize);

  const onSelectLabelSize = (option: string) => {
    switch (option) {
      case "PDF_W_PSLIP_8x11":
      case "PDF_4x6":
        setNewLabelSize(option);
        break;
    }
  };

  const radioOptions = [
    {
      caption: t(`seller-account.label-size.subtext.${kebabCase("PDF_4x6")}`),
      label: t(`seller-account.label-size.option.${kebabCase("PDF_4x6")}`),
      value: "PDF_4x6",
    },
    {
      caption: t(
        `seller-account.label-size.subtext.${kebabCase("PDF_W_PSLIP_8x11")}`,
      ),
      label: t(
        `seller-account.label-size.option.${kebabCase("PDF_W_PSLIP_8x11")}`,
      ),
      value: "PDF_W_PSLIP_8x11",
    },
  ];

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (userId == null) {
      return;
    }
    commit({
      onCompleted() {
        toast.info(t("seller-account.success.label-size-update"), {
          position: "top-center",
        });
      },
      onError(error: unknown) {
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ?? t("signup.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("signup.errors.http429");
        }
        toast.error(message, {
          position: "top-center",
        });
      },
      variables: {
        input: {
          labelSize: newLabelSize,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <SBRadioButtons
          id="labelSizes"
          options={radioOptions}
          style={styles.caption}
          value={newLabelSize}
          onChange={(val) => onSelectLabelSize(val)}
        />
      </div>
      <div {...stylex.props(styles.button)}>
        <SBButton
          disabled={isInFlight}
          loading={isInFlight}
          title={t("seller-account.buttons.label-size")}
          type={ButtonType.Submit}
        />
      </div>
    </form>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  caption: {
    color: colors.color,
  },
});

export default SellerAccountLabelSizeView;
