/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PortfolioView_user$key } from "src/types/__generated__/PortfolioView_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect, useRef, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import { PortfolioContext } from "src/app/context/portfolio";
import { useIntersectionObserver } from "src/hooks";
import {
  HeadingLevel,
  SBActivityIndicator,
  SBHeading,
  SBInfoMessage,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import PortfolioFmv from "./PortfolioFmv";
import PortfolioFmvEmpty from "./PortfolioFmvEmpty";
import PortfolioItem from "./PortfolioItem";
import PortfolioSearch from "./PortfolioSearch";
import PortfolioSubmit from "./PortfolioSubmit";

type Props = Readonly<{
  queryKey: PortfolioView_user$key | null | undefined;
}>;

const PortfolioView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment(
      graphql`
        fragment PortfolioView_user on User
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 10 }
          cursor: { type: "String" }
          orderBy: {
            type: "SellerProductsOrderByInput"
            defaultValue: { createdAt: DESC }
          }
          status: {
            type: "[ProductStatus!]"
            defaultValue: [FOR_SALE, PENDING_PURCHASE, SUBMITTED, UNAVAILABLE]
          }
          title: { type: "String" }
          gradingAuthority: { type: GradingAuthority }
          salesChannel: {
            type: "[SalesChannel!]"
            defaultValue: [AUCTION, SHOP]
          }
        )
        @refetchable(queryName: "PortfolioViewPaginationQuery") {
          __id
          portfolioNux
          submissionNux
          sellerStatus
          isApiUser
          bulkActions {
            canSendFavoritesOffers
            canCreateAuctionConsignmentRequests
          }
          products(
            first: $count
            after: $cursor
            orderBy: $orderBy
            where: {
              status: $status
              title: $title
              gradingAuthority: $gradingAuthority
              salesChannel: $salesChannel
            }
          ) @connection(key: "PortfolioView_products") {
            portfolioFmv {
              __id
              ...PortfolioFmv_portfolioFmv
            }
            edges {
              node {
                ...PortfolioItem_product
                id
                publicStatus
                salesChannel
                status
              }
            }
          }
        }
      `,
      queryKey,
    );

  const isApiUser = data?.isApiUser ?? false;

  const portfolioFmv = data?.products?.portfolioFmv;

  const products = data?.products?.edges ?? [];

  const canListForSale = data?.sellerStatus === "APPROVED";

  const isSellerOnVacation = data?.sellerStatus === "VACATION";

  const endRef = useRef<HTMLDivElement>(null);

  const {
    gradingAuthority,
    salesChannel,
    setCanListForSale,
    setIsSearching,
    sort,
    status,
    title,
  } = useContext(PortfolioContext);

  const [isPending, startTransition] = useTransition();

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  useEffect(() => {
    const splitSort = sort.split("-");
    const orderBy = { [splitSort[0]]: splitSort[1].toUpperCase() };

    setIsSearching(true);

    startTransition(() => {
      refetch(
        {
          gradingAuthority,
          orderBy,
          salesChannel,
          status,
          title,
        },
        {
          fetchPolicy: "network-only",
          onComplete: () => {
            setIsSearching(false);
            window.scrollTo(0, 0);
          },
        },
      );
    });
  }, [
    gradingAuthority,
    refetch,
    salesChannel,
    setIsSearching,
    sort,
    status,
    title,
  ]);

  useEffect(() => {
    setCanListForSale(canListForSale);
  }, [canListForSale, setCanListForSale]);

  return (
    <>
      <div {...stylex.props(auto, styles.header)}>
        <div {...stylex.props(auto, styles.headingContainer)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("portfolio.heading")}
          </SBHeading>{" "}
          {portfolioFmv == null ? (
            <PortfolioFmvEmpty />
          ) : (
            <PortfolioFmv queryKey={portfolioFmv} />
          )}
        </div>
        <PortfolioSubmit />
      </div>

      <div {...stylex.props(styles.search)}>
        <PortfolioSearch />
      </div>
      {isSellerOnVacation === true ? (
        <SBInfoMessage
          message={t("portfolio.vacation")}
          style={styles.vacationMessage}
        />
      ) : null}
      {!isPending && products.length === 0 ? (
        <SBParagraph>{t("portfolio.empty.description")}</SBParagraph>
      ) : (
        <ul {...stylex.props(styles.products)}>
          {products?.map(({ node }, ii) => (
            <li {...stylex.props(styles.product)} key={node.id}>
              <PortfolioItem
                apiUser={isApiUser}
                canListForSale={canListForSale}
                last={ii === products.length - 1}
                queryKey={node}
              />
            </li>
          ))}
        </ul>
      )}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  header: {
    alignItems: "center",
    borderBottomColor: colors.borderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    paddingBottom: 16,
  },
  heading: {
    marginBottom: 8,
  },
  headingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  product: {
    margin: 0,
    padding: 0,
  },
  products: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  search: {
    marginBottom: 40,
  },
  vacationMessage: {
    marginBottom: 16,
  },
});

export default PortfolioView;
