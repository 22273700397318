/**
 * @generated SignedSource<<2854ed07837edfa1189d52282ecf9d5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewOffersReceivedCard_offer$data = {
  readonly currency: Currency;
  readonly expiresAt: string;
  readonly id: string;
  readonly price: number;
  readonly product: {
    readonly minOfferPercentage: number;
    readonly minOfferPrice: number | null | undefined;
    readonly price: number;
  };
  readonly status: OfferStatus;
  readonly toUserId: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewOffersReceivedCardAccept_offer" | "ReviewOffersReceivedCardCounter_offer" | "ReviewOffersReceivedCardDecline_offer">;
  readonly " $fragmentType": "ReviewOffersReceivedCard_offer";
};
export type ReviewOffersReceivedCard_offer$key = {
  readonly " $data"?: ReviewOffersReceivedCard_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewOffersReceivedCard_offer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewOffersReceivedCard_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "toUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minOfferPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minOfferPercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewOffersReceivedCardCounter_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewOffersReceivedCardAccept_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewOffersReceivedCardDecline_offer"
    }
  ],
  "type": "Offer",
  "abstractKey": null
};
})();

(node as any).hash = "036fb20cd8892d1982ba8a421cae38cf";

export default node;
