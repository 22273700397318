/**
 * @generated SignedSource<<14603eac1d82689dd58c05667ada58b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendToAuctionView_product$data = {
  readonly canRequestAuctionConsignment: boolean;
  readonly canSubmitToCommunityAuctions: boolean;
  readonly id: string;
  readonly " $fragmentType": "SendToAuctionView_product";
};
export type SendToAuctionView_product$key = {
  readonly " $data"?: SendToAuctionView_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendToAuctionView_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendToAuctionView_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canRequestAuctionConsignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canSubmitToCommunityAuctions",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "d50ae076b8a92318d68babe9d4ff7e93";

export default node;
