/**
 * @generated SignedSource<<d90a7b43bdb0773b26f401b81a3a93ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendToAuctionContentQuery$variables = {
  id: string;
};
export type SendToAuctionContentQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SendToAuctionView_product">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SendToAuctionView_auctionSearch">;
};
export type SendToAuctionContentQuery = {
  response: SendToAuctionContentQuery$data;
  variables: SendToAuctionContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "filter": "OPEN_SUBMISSIONS",
      "type": [
        "COMMUNITY"
      ]
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendToAuctionContentQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SendToAuctionView_auctionSearch"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SendToAuctionView_product"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendToAuctionContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AuctionSearchConnection",
        "kind": "LinkedField",
        "name": "auctionSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuctionSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Auction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagline",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "biddingStartsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserAuctionStatusOutput",
                    "kind": "LinkedField",
                    "name": "userAuctionStatus",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "submittable",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "auctionSearch(first:20,where:{\"filter\":\"OPEN_SUBMISSIONS\",\"type\":[\"COMMUNITY\"]})"
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "SendToAuctionView_auctionSearch",
        "kind": "LinkedHandle",
        "name": "auctionSearch"
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canRequestAuctionConsignment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canSubmitToCommunityAuctions",
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc2b76bf54e0f7be6d62fc8aaab3a2f3",
    "id": null,
    "metadata": {},
    "name": "SendToAuctionContentQuery",
    "operationKind": "query",
    "text": "query SendToAuctionContentQuery(\n  $id: ID!\n) {\n  ...SendToAuctionView_auctionSearch\n  node(id: $id) {\n    __typename\n    ... on Product {\n      ...SendToAuctionView_product\n    }\n    id\n  }\n}\n\nfragment SendToAuctionView_auctionSearch on Query {\n  auctionSearch(first: 20, where: {filter: OPEN_SUBMISSIONS, type: [COMMUNITY]}) {\n    edges {\n      node {\n        id\n        title\n        tagline\n        biddingStartsAt\n        userAuctionStatus {\n          submittable\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment SendToAuctionView_product on Product {\n  id\n  canRequestAuctionConsignment\n  canSubmitToCommunityAuctions\n}\n"
  }
};
})();

(node as any).hash = "f3e624d25b845a94f412ab67ddbd46e0";

export default node;
