/**
 * @generated SignedSource<<51c7967967a4eee7ee5c6172e21e330f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountShipFromAddressViewRefreshQuery$variables = {
  id: string;
};
export type SellerAccountShipFromAddressViewRefreshQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SellerAccountShipFromAddressView_user">;
  } | null | undefined;
};
export type SellerAccountShipFromAddressViewRefreshQuery = {
  response: SellerAccountShipFromAddressViewRefreshQuery$data;
  variables: SellerAccountShipFromAddressViewRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerAccountShipFromAddressViewRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SellerAccountShipFromAddressView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SellerAccountShipFromAddressViewRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                      "type": "SHIP_FROM"
                    }
                  }
                ],
                "concreteType": "AddressesConnection",
                "kind": "LinkedField",
                "name": "addresses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AddressesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Address",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address1",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "address2",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "country",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "familyName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "givenName",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDefault",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locality",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "region",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "addresses(where:{\"type\":\"SHIP_FROM\"})"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c040ba15a31fd67758460e41d777b0c5",
    "id": null,
    "metadata": {},
    "name": "SellerAccountShipFromAddressViewRefreshQuery",
    "operationKind": "query",
    "text": "query SellerAccountShipFromAddressViewRefreshQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SellerAccountShipFromAddressView_user\n    id\n  }\n}\n\nfragment AccountAddressCard_address on Address {\n  address1\n  address2\n  country\n  familyName\n  givenName\n  id\n  isDefault\n  locality\n  name\n  postalCode\n  region\n  type\n}\n\nfragment SellerAccountShipFromAddressView_user on User {\n  id\n  addresses(where: {type: SHIP_FROM}) {\n    edges {\n      node {\n        ...AccountAddressCard_address\n        address1\n        address2\n        country\n        familyName\n        givenName\n        id\n        locality\n        name\n        postalCode\n        region\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d6def49aad2076521540b246716795d";

export default node;
