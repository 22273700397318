/**
 * @generated SignedSource<<9cfd420c0c8e8d054c88db11bf4bdb47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductDetailCarousel_product$data = {
  readonly comicDetails: {
    readonly number: string;
    readonly title: string;
  } | null | undefined;
  readonly hasViewerFavorited: boolean;
  readonly id: string;
  readonly images: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      };
    }>;
  } | null | undefined;
  readonly isViewerOwner: boolean;
  readonly shareUrl: string;
  readonly " $fragmentSpreads": FragmentRefs<"ReportProductIssueModal_product">;
  readonly " $fragmentType": "ProductDetailCarousel_product";
};
export type ProductDetailCarousel_product$key = {
  readonly " $data"?: ProductDetailCarousel_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailCarousel_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailCarousel_product",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReportProductIssueModal_product"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerFavorited",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImagesConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "quality",
                      "value": 100
                    },
                    {
                      "kind": "Literal",
                      "name": "webp",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 1000
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": "url(quality:100,webp:true,width:1000)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "b8aa7cfc640dbd3f34442d44f046c7a6";

export default node;
