/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { CreateSubmissionContentQuery } from "src/types/__generated__/CreateSubmissionContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import CreateSubmissionView from "./CreateSubmissionView";

type Props = Readonly<{
  productId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const CreateSubmissionContent = ({
  productId,
  setOpen,
}: Props): React.ReactNode => {
  const data = useLazyLoadQuery<CreateSubmissionContentQuery>(
    graphql`
      query CreateSubmissionContentQuery($id: ID!) {
        hotConfigs {
          data
        }
        node(id: $id) {
          ...CreateSubmissionView_product
        }
      }
    `,
    {
      id: productId ?? "",
    },
  );

  const gradedBookMinimumValue = Number.parseInt(
    data.hotConfigs.data?.GRADED_BOOK_MINIMUM_VALUE ?? "0",
    10,
  );

  const rawBookMinimumValue = Number.parseInt(
    data.hotConfigs.data?.RAW_BOOK_MINIMUM_VALUE ?? "0",
    10,
  );

  if (data.node == null) {
    return null;
  }

  return (
    <CreateSubmissionView
      gradedBookMinimumValue={gradedBookMinimumValue}
      queryKey={data.node}
      rawBookMinimumValue={rawBookMinimumValue}
      setOpen={setOpen}
    />
  );
};

export default CreateSubmissionContent;
