/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { HomeView_verticalSearch$key } from "src/types/__generated__/HomeView_verticalSearch.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import SearchResultCard from "src/app/components/search-result-card/SearchResultCard";
import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

import AuctionLotsEndingSoon from "./auctions/AuctionLotsEndingSoon";
import AuctionLotsMostActivity from "./auctions/AuctionLotsMostActivity";
import HomeCarousel from "./HomeCarousel";
import MarketplaceSeeMore from "./MarketplaceSeeMore";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: HomeView_verticalSearch$key;
}>;

const HomeView = ({ queryKey }: Props) => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment HomeView_verticalSearch on Query
      @argumentDefinitions(
        categoryRollups: { type: "[CategoryRollup!]" }
        count: { type: "Int", defaultValue: 24 }
        cursor: { type: "String", defaultValue: "" }
        gradeMax: { type: Float, defaultValue: null }
        gradeMin: { type: Float, defaultValue: null }
        gradingAuthorities: { type: "[GradingAuthority!]" }
        priceMax: { type: Int, defaultValue: null }
        priceMin: { type: Int, defaultValue: null }
        salesChannel: { type: "[SalesChannel!]", defaultValue: [AUCTION, SHOP] }
        searchTerm: { type: "String", defaultValue: null }
        shopUrl: { type: "String", defaultValue: null }
        specialCopies: { type: "[SpecialCopy!]" }
        vertical: { type: "Vertical", defaultValue: COMIC }
        yearMax: { type: Int, defaultValue: null }
        yearMin: { type: Int, defaultValue: null }
        orderBy: {
          type: VerticalSearchOrderByInput
          defaultValue: { createdAt: DESC }
        }
      ) {
        __id
        verticalSearch(
          vertical: $vertical
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: {
            categoryRollups: $categoryRollups
            gradeMax: $gradeMax
            gradeMin: $gradeMin
            gradingAuthorities: $gradingAuthorities
            priceMax: $priceMax
            priceMin: $priceMin
            salesChannel: $salesChannel
            searchTerm: $searchTerm
            shopUrl: $shopUrl
            specialCopies: $specialCopies
            yearMax: $yearMax
            yearMin: $yearMin
          }
        ) {
          edges {
            node {
              id
              ...SearchResultCard_typesenseSearch
            }
          }
        }
      }
    `,
    queryKey,
  );

  const connectionId = data.__id;

  const edges = data?.verticalSearch?.edges ?? [];

  return (
    <>
      <div {...stylex.props(styles.carousel)}>
        <HomeCarousel />
      </div>
      <div {...stylex.props(styles.section)}>
        <AuctionLotsEndingSoon />
      </div>
      <div {...stylex.props(styles.section)}>
        <AuctionLotsMostActivity />
      </div>
      <div {...stylex.props(styles.section)}>
        <div {...stylex.props(styles.titleTop)}>
          <SBHeading level={HeadingLevel.H3} style={styles.title}>
            {t("home.title.market")}
          </SBHeading>
          <MarketplaceSeeMore />
        </div>
        <SBParagraph style={styles.subtitle}>
          {t("home.subtitle.market")}
        </SBParagraph>
        <ul {...stylex.props(styles.products)}>
          {edges.map(({ node }) => (
            <SearchResultCard
              key={node.id}
              connectionId={connectionId}
              queryKey={node}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

const styles = stylex.create({
  carousel: { marginBottom: 48 },
  products: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(4, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,
    marginTop: 24,
    padding: 0,
  },
  section: {
    marginBottom: 48,
  },
  subtitle: {
    color: colors.colorMuted,
  },
  title: {
    marginBottom: 8,
  },
  titleTop: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default HomeView;
