/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ResetPasswordInitializeMutationQuery } from "src/types/__generated__/ResetPasswordInitializeMutationQuery.graphql";
import type { ResetPasswordResetMutationQuery } from "src/types/__generated__/ResetPasswordResetMutationQuery.graphql";
import type { ErrorResponse } from "src/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { useContext, useState } from "react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";
import { useLocation } from "react-router-dom";
import { defaultRules, useValidation } from "react-simple-form-validator";

import { PageContext } from "src/app/context/page";
import { useDefaultValidationErrorMessages } from "src/hooks";
import {
  ButtonType,
  SBButton,
  SBErrorMessage,
  SBPageHeader,
  SBSuccessMessage,
  SBTextInput,
  TextInputType,
} from "src/sbxui";

const PASSWORD_MIN_LENGTH = 8;

const ResetPassword = (): React.ReactNode | null => {
  const pageContext = useContext(PageContext);
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hash = queryParams.get("hash") ?? null;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [touchedFields, setTouchedFields] = useState({
    confirmPassword: false,
    password: false,
  });

  const defaultValidationErrorMessages = useDefaultValidationErrorMessages();

  const { getErrorsInField, isFieldInError, isFormValid } = useValidation({
    fieldsRules: {
      confirmPassword: { equalPassword: password, required: true },
      password: { minlength: PASSWORD_MIN_LENGTH, required: true },
    },
    labels: {
      confirmPassword: t("signup.errors.labels.new-password-confirmation"),
      password: t("signup.errors.labels.new-password"),
    },
    messages: {
      // We have our own library for localization, so we just use en here.
      en: {
        ...defaultValidationErrorMessages,
      },
    },
    rules: {
      ...defaultRules,
    },
    state: { confirmPassword, password },
  });

  React.useEffect(() => {
    pageContext?.setTitle(t("reset-password.document-title"));
  }, [t, pageContext]);

  const [commitReset, isInFlightReset] =
    useMutation<ResetPasswordResetMutationQuery>(graphql`
      mutation ResetPasswordResetMutationQuery(
        $input: PasswordResetFinalizeInput!
      ) {
        passwordResetFinalize(passwordResetFinalizeInput: $input) {
          message
          success
        }
      }
    `);

  const [commitInitialize, isInFlightInitialize] =
    useMutation<ResetPasswordInitializeMutationQuery>(graphql`
      mutation ResetPasswordInitializeMutationQuery(
        $input: PasswordResetInitiateInput!
      ) {
        passwordResetInitiate(passwordResetInitiateInput: $input)
      }
    `);

  const handleSubmitInitialize = (event: React.SyntheticEvent) => {
    event.preventDefault();
    commitInitialize({
      onCompleted() {
        setSuccessMessage(t("reset-password.initiate-success"));
      },
      onError(error: unknown) {
        const errorResponse = error as ErrorResponse;
        setErrorMessage(errorResponse?.res?.errors?.[0]?.message);
      },
      variables: {
        input: {
          email: emailAddress,
        },
      },
    });
  };

  const handleSubmitReset = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setTouchedFields({
      confirmPassword: !isFormValid,
      password: !isFormValid,
    });
    if (isFormValid) {
      commitReset({
        onCompleted(data) {
          if (data.passwordResetFinalize?.success === false) {
            setErrorMessage(data.passwordResetFinalize?.message ?? "");
          } else {
            setSuccessMessage(data.passwordResetFinalize?.message ?? "");
          }
        },
        onError(error: unknown) {
          const errorResponse = error as ErrorResponse;
          setErrorMessage(errorResponse?.res?.errors?.[0]?.message);
        },
        variables: {
          input: {
            hash: hash ?? "",
            password,
          },
        },
      });
    }
  };

  const passwordInputType = showPassword
    ? TextInputType.Text
    : TextInputType.Password;

  const confirmPasswordInputType = showConfirmPassword
    ? TextInputType.Text
    : TextInputType.Password;

  const isButtonDisabled = password === "" || confirmPassword === "";
  const isInitiateButtonDisabled = emailAddress === "";

  const resetForm = (
    <form method="POST" onSubmit={handleSubmitReset}>
      <SBPageHeader title={t("reset-password.reset-form-title")} />{" "}
      <SBErrorMessage message={errorMessage} style={styles.message} />
      <SBSuccessMessage message={successMessage} style={styles.message} />
      <fieldset {...stylex.props(styles.formFields)}>
        <div {...stylex.props(styles.passwordField)}>
          <SBTextInput
            errorMessage={
              touchedFields.password
                ? isFieldInError("password") && getErrorsInField("password")[0]
                : null
            }
            id="password"
            label={t("reset-password.field.password")}
            placeholder={t("reset-password.field.password")}
            type={passwordInputType}
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            onClickIcon={(_event) => setShowPassword(!showPassword)}
          />
        </div>

        <div {...stylex.props(styles.passwordField)}>
          <SBTextInput
            errorMessage={
              touchedFields.confirmPassword
                ? isFieldInError("confirmPassword") &&
                  getErrorsInField("confirmPassword")[0]
                : null
            }
            id="confirmPassword"
            label={t("reset-password.field.confirm-password")}
            placeholder={t("reset-password.field.confirm-password")}
            type={confirmPasswordInputType}
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.currentTarget.value)}
            onClickIcon={(_event) =>
              setShowConfirmPassword(!showConfirmPassword)
            }
          />
        </div>

        <div>
          <SBButton
            disabled={isInFlightReset || isButtonDisabled}
            title={t("reset-password.submit")}
            type={ButtonType.Submit}
          />
        </div>
      </fieldset>
    </form>
  );

  const initializationForm = (
    <form method="POST" onSubmit={handleSubmitInitialize}>
      <SBPageHeader title={t("reset-password.page-title")} />
      <SBErrorMessage message={errorMessage} style={styles.message} />
      <SBSuccessMessage message={successMessage} style={styles.message} />
      <fieldset {...stylex.props(styles.formFields)}>
        <div {...stylex.props(styles.emailField)}>
          <SBTextInput
            id="password"
            label={t("reset-password.field.email-address")}
            placeholder={t("reset-password.field.email-address")}
            type={TextInputType.Text}
            value={emailAddress}
            onChange={(event) => setEmailAddress(event.currentTarget.value)}
          />
        </div>

        <div {...stylex.props(styles.button)}>
          <SBButton
            disabled={isInFlightInitialize || isInitiateButtonDisabled}
            title={t("reset-password.initiate")}
            type={ButtonType.Submit}
          />
        </div>
      </fieldset>
    </form>
  );

  return (
    <div {...stylex.props(styles.root)}>
      {hash == null ? initializationForm : resetForm}
    </div>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
  },
  emailField: {
    marginBottom: 16,
  },
  errorMessage: { color: "red", marginBottom: 16 },
  formFields: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
    width: "50%",
  },
  message: {
    marginBottom: 16,
  },
  passwordField: {
    marginBottom: 16,
  },
  root: { padding: "10px" },
  successMessage: {
    marginBlock: 8,
  },
});

export default React.memo(ResetPassword);
