/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReactNode } from "react";
import type { ReviewOffersView_product$key } from "src/types/__generated__/ReviewOffersView_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBLink, SBTabs } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ReviewOffersCompleted from "./ReviewOffersCompleted";
import ReviewOffersReceived from "./ReviewOffersReceived";
import ReviewOffersSent from "./ReviewOffersSent";

type Props = Readonly<{
  queryKey: ReviewOffersView_product$key;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const ReviewOffersView = ({ queryKey, setOpen }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ReviewOffersView_product on Product {
        id
        offerCounts {
          pending
          received
          sent
        }
        price
        ...ReviewOffersReceived_product
        ...ReviewOffersSent_product
        ...ReviewOffersCompleted_product
      }
    `,
    queryKey,
  );

  const pending = data.offerCounts?.pending ?? 0;
  const received = data.offerCounts?.received ?? 0;
  const sent = data.offerCounts?.sent ?? 0;

  const receivedAll = pending + received;

  const [selectedTab, setSelectedTab] = useState<string>("RECEIVED");

  const onPressClose = () => {
    setOpen(false);
  };

  const TABS = [
    {
      id: "RECEIVED",
      name: t("review-offers.tab.received"),
      selected: selectedTab === "RECEIVED",
    },
    {
      id: "SENT",
      name: t("review-offers.tab.sent"),
      selected: selectedTab === "SENT",
    },
    {
      id: "COMPLETED",
      name: t("review-offers.tab.completed"),
      selected: selectedTab === "COMPLETED",
    },
  ];

  const getTabBody = (): ReactNode => {
    switch (selectedTab) {
      case "RECEIVED":
        return receivedAll > 0 ? (
          <ReviewOffersReceived queryKey={data} />
        ) : null;
      case "SENT":
        return sent > 0 ? <ReviewOffersSent queryKey={data} /> : null;
      case "COMPLETED":
        return <ReviewOffersCompleted queryKey={data} />;
      default:
        return null;
    }
  };

  const content = (
    <>
      <div {...stylex.props(auto, styles.tabs)}>
        <SBTabs tabs={TABS} onTabSelected={(tabId) => setSelectedTab(tabId)} />
      </div>
      {getTabBody()}
    </>
  );

  if (data == null) {
    return null;
  }

  return (
    <div {...stylex.props(auto, styles.container)}>
      {content}{" "}
      <SBLink style={styles.close} onClick={onPressClose}>
        {t("common.buttons.close")}
      </SBLink>
    </div>
  );
};

const styles = stylex.create({
  close: {
    color: colors.color,
    fontSize: 16,
    marginTop: 16,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  tabs: {
    marginBottom: 16,
  },
});

export default ReviewOffersView;
