/**
 * @generated SignedSource<<4cd7561428b3490ca865c9927843a9fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
export type SalesChannel = "AUCTION" | "SHOP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortfolioItem_product$data = {
  readonly auctionLot: {
    readonly auction: {
      readonly biddingStartsAt: string;
      readonly id: string;
    };
    readonly bidCount: number;
    readonly currentBidAmount: number;
    readonly currentBidCurrency: Currency;
    readonly id: string;
    readonly isClosed: boolean;
  } | null | undefined;
  readonly canSell: boolean;
  readonly comicDetails: {
    readonly grade: number | null | undefined;
    readonly gradingAuthority: GradingAuthority;
    readonly number: string;
    readonly title: string;
  } | null | undefined;
  readonly currency: Currency;
  readonly favoritesCount: number;
  readonly fmvScore: {
    readonly currency: Currency | null | undefined;
    readonly maxValue: number | null | undefined;
    readonly minValue: number | null | undefined;
    readonly rank: number | null | undefined;
  } | null | undefined;
  readonly historicalSales: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly price: number;
        readonly saleDate: number;
      };
    }>;
  };
  readonly id: string;
  readonly images: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      };
    }>;
  } | null | undefined;
  readonly price: number;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly salesChannel: SalesChannel;
  readonly " $fragmentType": "PortfolioItem_product";
};
export type PortfolioItem_product$key = {
  readonly " $data"?: PortfolioItem_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioItem_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioItem_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesChannel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "auctionLot",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bidCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentBidAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentBidCurrency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isClosed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Auction",
          "kind": "LinkedField",
          "name": "auction",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "biddingStartsAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImagesConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "quality",
                      "value": 100
                    },
                    {
                      "kind": "Literal",
                      "name": "webp",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 500
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": "url(quality:100,webp:true,width:500)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValueScore",
      "kind": "LinkedField",
      "name": "fmvScore",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoritesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canSell",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "saleDate": "DESC"
          }
        }
      ],
      "concreteType": "HistoricalSalesConnection",
      "kind": "LinkedField",
      "name": "historicalSales",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HistoricalSalesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HistoricalSale",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saleDate",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "historicalSales(first:10,orderBy:{\"saleDate\":\"DESC\"})"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "76fe6b5f93991a57a2b997bb393c404a";

export default node;
