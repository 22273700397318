/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { RemoveFromAuctionContentQuery } from "src/types/__generated__/RemoveFromAuctionContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import RemoveFromAuctionView from "./RemoveFromAuctionView";

type Props = Readonly<{
  productId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const RemoveFromAuctionContent = ({
  productId,
  setOpen,
}: Props): React.ReactNode => {
  const data = useLazyLoadQuery<RemoveFromAuctionContentQuery>(
    graphql`
      query RemoveFromAuctionContentQuery($id: ID!) {
        node(id: $id) {
          ...RemoveFromAuctionView_product
        }
      }
    `,
    {
      id: productId ?? "",
    },
  );

  if (data.node == null) {
    return null;
  }

  return <RemoveFromAuctionView queryKey={data.node} setOpen={setOpen} />;
};

export default RemoveFromAuctionContent;
