/**
 * @generated SignedSource<<a7c19fb8d3fd65f66f8b88b0c13c5708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AuctionType = "COMMUNITY" | "CURATED" | "%future added value";
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsOwnerAuction_product$data = {
  readonly auctionLot: {
    readonly auction: {
      readonly biddingClosesAt: string;
      readonly biddingStartsAt: string;
      readonly lotSubmissionsLockAt: string | null | undefined;
      readonly tagline: string | null | undefined;
      readonly title: string;
      readonly type: AuctionType;
    };
  } | null | undefined;
  readonly id: string;
  readonly isViewerApiUser: boolean;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly " $fragmentType": "ProductDetailActionsOwnerAuction_product";
};
export type ProductDetailActionsOwnerAuction_product$key = {
  readonly " $data"?: ProductDetailActionsOwnerAuction_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsOwnerAuction_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsOwnerAuction_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerApiUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "auctionLot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Auction",
          "kind": "LinkedField",
          "name": "auction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tagline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "biddingStartsAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "biddingClosesAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lotSubmissionsLockAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "706ff676ec72dea1fe0550d659c45bc6";

export default node;
