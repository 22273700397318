/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  AccountFavoritesView_user$key,
  GradingAuthority,
} from "src/types/__generated__/AccountFavoritesView_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import { useFavorite, useUnfavorite } from "src/hooks";
import { SBProductTile } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountFavoritesView_user$key;
}>;

const AccountFavoritesView = ({ queryKey }: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment AccountFavoritesView_user on User {
        favorites(orderBy: { createdAt: DESC }) {
          edges {
            node {
              id
              createdAt
              price
              currency
              isViewerOwner
              hasViewerFavorited
              images(first: 1) {
                edges {
                  node {
                    url(quality: 100, width: 500, webp: true)
                  }
                }
              }
              comicDetails {
                title
                number
                variant
                publisher
                year
                gradingAuthority
                grade
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const [commitFavorite, isInFlightFavorite] = useFavorite();
  const [commitUnfavorite, isInFlightUnfavorite] = useUnfavorite();

  const handleClickFavorite =
    (id: string, hasViewerFavorited: boolean) => () => {
      if (isInFlightFavorite || isInFlightUnfavorite) {
        return;
      }
      const variables = {
        input: {
          productId: id,
        },
      };
      hasViewerFavorited
        ? commitUnfavorite({
            optimisticResponse: {
              unfavorite: {
                hasViewerFavorited: false,
                id,
              },
            },
            variables,
          })
        : commitFavorite({
            optimisticResponse: {
              favorite: {
                hasViewerFavorited: true,
                id,
              },
            },
            variables,
          });
    };

  return (
    <ul {...stylex.props(styles.favorites)}>
      {data?.favorites?.edges?.map(({ node }) => {
        const {
          id,
          comicDetails,
          images,
          price,
          currency,
          hasViewerFavorited,
          isViewerOwner,
        } = node;
        const { title, number, gradingAuthority, grade } = comicDetails ?? {};

        return (
          <SBProductTile
            key={id}
            currency={currency}
            favorite={hasViewerFavorited}
            favoriteLoading={isInFlightFavorite || isInFlightUnfavorite}
            grade={grade ?? 0}
            gradingAuthority={gradingAuthority as GradingAuthority}
            imageUrl={images?.edges[0]?.node?.url ?? ""}
            isViewerOwner={isViewerOwner}
            number={number ?? "nn"}
            price={price}
            productId={id}
            salesChannel="SHOP"
            title={title ?? ""}
            onClickFavorite={handleClickFavorite(id, hasViewerFavorited)}
          />
        );
      })}
    </ul>
  );
};

const styles = stylex.create({
  favorites: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(5, 1fr)",
    },
    margin: 0,
    padding: 0,
  },
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
});

export default AccountFavoritesView;
