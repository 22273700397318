/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountPayoutPreferencesView_user$key } from "src/types/__generated__/SellerAccountPayoutPreferencesView_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: SellerAccountPayoutPreferencesView_user$key;
}>;

const SellerAccountPayoutPreferencesView = ({
  queryKey,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment SellerAccountPayoutPreferencesView_user on User {
        id
        paymentPreferencesUrl
      }
    `,
    queryKey,
  );

  return (
    <div {...stylex.props(styles.root)}>
      <iframe
        src={data.paymentPreferencesUrl}
        {...stylex.props(styles.frame)}
      />
    </div>
  );
};

const styles = stylex.create({
  frame: {
    borderColor: colors.colorMuted,
    borderStyle: "solid",
    borderWidth: 1,
    height: "100%",
    width: "100%",
  },
  root: {
    height: 1000,
  },
});

export default SellerAccountPayoutPreferencesView;
