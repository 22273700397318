/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReviewOffersSentCard_offer$key } from "src/types/__generated__/ReviewOffersSentCard_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { UserContext } from "src/app/context/user";
import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: ReviewOffersSentCard_offer$key;
}>;

const ReviewOffersSentCard = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const userId = user?.userId ?? "";

  const data = useFragment(
    graphql`
      fragment ReviewOffersSentCard_offer on Offer {
        id
        price
        currency
        status
        fromUserId
        expiresAt
      }
    `,
    queryKey,
  );

  const offer = data;
  const offerPrice = offer?.price;
  const currency = offer?.currency;
  const fromUserId = offer?.fromUserId;

  let content = null;
  if (fromUserId === userId) {
    content = (
      <div {...stylex.props(auto, styles.cardHeader)}>
        <SBHeading level={HeadingLevel.H2}>
          {offerPrice} {currency}
        </SBHeading>
        <SBParagraph style={styles.offerText}>
          {t("review-offers.offer.text")}
        </SBParagraph>
        <SBParagraph style={styles.offerSubtext}>
          {t("review-offers.offer-sent.subText")}
        </SBParagraph>
      </div>
    );
  }

  return (
    <div {...stylex.props(auto, styles.card)}>
      <div>{content}</div>
    </div>
  );
};

const styles = stylex.create({
  card: {
    backgroundColor: colors.backgroundColor,
    borderColor: colors.color,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "block",
    marginBottom: 16,
    padding: 16,
    width: "90%",
  },
  cardHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  offerSubtext: {
    color: colors.colorMuted,
    marginBottom: 0,
    marginTop: 4,
  },
  offerText: {
    marginBottom: 0,
    marginTop: 4,
  },
});

export default ReviewOffersSentCard;
