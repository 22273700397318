/**
 * @generated SignedSource<<018a089af4ea13e284665ef99ba7eb8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewOffersSentCard_offer$data = {
  readonly currency: Currency;
  readonly expiresAt: string;
  readonly fromUserId: string;
  readonly id: string;
  readonly price: number;
  readonly status: OfferStatus;
  readonly " $fragmentType": "ReviewOffersSentCard_offer";
};
export type ReviewOffersSentCard_offer$key = {
  readonly " $data"?: ReviewOffersSentCard_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewOffersSentCard_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewOffersSentCard_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fromUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

(node as any).hash = "3438246a2d434e5a27a147a9bd88a4f4";

export default node;
