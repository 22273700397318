/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { CreateSubmissionView_product$key } from "src/types/__generated__/CreateSubmissionView_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import {
  ButtonVariation,
  SBButton,
  SBErrorMessage,
  SBTextInput,
} from "src/sbxui";
import { formatMoney } from "src/utils";

const PRICE_MAX_LENGTH = 6;

type Props = Readonly<{
  gradedBookMinimumValue: number;
  queryKey: CreateSubmissionView_product$key;
  rawBookMinimumValue: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const CreateSubmissionView = ({
  gradedBookMinimumValue,
  queryKey,
  rawBookMinimumValue,
  setOpen,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation CreateSubmissionViewUpdateMutation(
      $input: CreateProductSubmissionFromInventoryItemInput!
    ) {
      createProductSubmissionFromInventoryItem(
        createProductSubmissionFromInventoryItemInput: $input
      ) {
        product {
          __id
          fmvScore {
            value
          }
          ...ProductDetailView_product
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment CreateSubmissionView_product on Product {
        id
        comicDetails {
          gradingAuthority
        }
        currency
      }
    `,
    queryKey,
  );

  const productId = data.id;
  const currency = data.currency;

  const gradingAuthority = data.comicDetails?.gradingAuthority;

  const [newPrice, setNewPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPrice(event.currentTarget.value);
    setErrorMessage(null);
  };

  const handleClickCreate = (_event: React.SyntheticEvent) => {
    if (
      !newPrice ||
      Number.isNaN(Number.parseFloat(newPrice)) ||
      Number.parseFloat(newPrice) <= 0
    ) {
      setErrorMessage(t("forms.validation.greater-zero"));
      return;
    }
    if (
      gradingAuthority !== "RAW" &&
      Number.parseInt(newPrice, 10) < gradedBookMinimumValue
    ) {
      setErrorMessage(
        t("forms.validation.graded-book-minimum-value", {
          minimum: formatMoney(gradedBookMinimumValue, currency, i18n.language),
        }),
      );
      return;
    }
    if (
      gradingAuthority === "RAW" &&
      Number.parseInt(newPrice, 10) < rawBookMinimumValue
    ) {
      setErrorMessage(
        t("forms.validation.raw-book-minimum-value", {
          minimum: formatMoney(rawBookMinimumValue, currency, i18n.language),
        }),
      );
      return;
    }
    commit({
      onCompleted() {
        setOpen(false);
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          price: Number.parseInt(newPrice, 10),
          productId,
        },
      },
    });
  };

  let placeholder = "$";
  switch (currency) {
    case "USD":
      placeholder = "$";
      break;
    case "AUD":
    case "CAD":
    case "EUR":
    case "GBP":
    case "%future added value":
      placeholder = "USD";
      break;
  }

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(errorMessage != null && styles.errorMessage)}>
        <SBErrorMessage message={errorMessage} />
      </div>
      <SBTextInput
        disabled={isInFlight}
        id="price"
        inputStyle={styles.inputStyle}
        label={t("portfolio.inventory-item.list-modal.price")}
        maxLength={PRICE_MAX_LENGTH}
        placeholder={placeholder}
        showLabel={true}
        type="number"
        value={newPrice}
        onChange={handleChangePrice}
      />
      <SBButton
        block={true}
        loading={isInFlight}
        style={styles.button}
        title={t("common.buttons.submit")}
        variation={ButtonVariation.Emphasis}
        onClick={handleClickCreate}
      />
    </div>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  confirm: {
    marginBottom: 0,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  current: {
    marginBottom: 0,
    marginTop: 4,
    textAlign: "center",
  },
  errorMessage: {
    marginBottom: 16,
    width: "100%",
  },
  input: {
    marginBottom: 8,
  },
  inputStyle: {
    textAlign: "center",
  },
});

export default CreateSubmissionView;
