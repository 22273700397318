/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailCarousel_product$key } from "src/types/__generated__/ProductDetailCarousel_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useCallback, useContext, useState } from "react";
import { useFragment } from "react-relay";

import CopyButton from "src/app/components/sharing/CopyButton";
import ShareButtons from "src/app/components/sharing/ShareButtons";
import { UserContext } from "src/app/context/user";
import { useFavorite, usePlatform, useUnfavorite } from "src/hooks";
import { SBFavoriteIcon, SBIcon, SBImageCarousel } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ReportProductIssueModal from "./ReportProductIssueModal";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: ProductDetailCarousel_product$key;
}>;

const ProductDetailCarousel = ({ queryKey }: Props): React.ReactNode => {
  const userContext = useContext(UserContext);
  const platform = usePlatform();

  const [commitFavorite, isInFlightFavorite] = useFavorite();
  const [commitUnfavorite, isInFlightUnfavorite] = useUnfavorite();
  const [isReportProblemModalOpen, setIsReportProblemModalOpen] =
    useState(false);

  const data = useFragment(
    graphql`
      fragment ProductDetailCarousel_product on Product {
        ...ReportProductIssueModal_product
        id
        shareUrl
        hasViewerFavorited
        isViewerOwner
        comicDetails {
          title
          number
        }
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 1000)
            }
          }
        }
      }
    `,
    queryKey,
  );

  const {
    comicDetails,
    hasViewerFavorited,
    id,
    images,
    isViewerOwner,
    shareUrl,
  } = data;
  const { title, number } = comicDetails ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const showReportButton = userContext.user != null && !isViewerOwner;
  const showFavoriteButton = userContext.user != null && !isViewerOwner;

  const handleClickFavorite = useCallback(() => {
    if (isInFlightFavorite || isInFlightUnfavorite) {
      return;
    }
    const variables = {
      input: {
        productId: id,
      },
    };
    hasViewerFavorited
      ? commitUnfavorite({
          optimisticResponse: {
            unfavorite: {
              hasViewerFavorited: false,
              id,
            },
          },
          variables,
        })
      : commitFavorite({
          optimisticResponse: {
            favorite: {
              hasViewerFavorited: true,
              id,
            },
          },
          variables,
        });
  }, [
    commitFavorite,
    commitUnfavorite,
    hasViewerFavorited,
    isInFlightFavorite,
    isInFlightUnfavorite,
    id,
  ]);

  return (
    <>
      <div {...stylex.props(styles.carousel)}>
        <SBImageCarousel imageUrls={imageUrls} />
        <div {...stylex.props(styles.extraButtons)}>
          {showReportButton ? (
            <>
              <button
                type="button"
                onClick={() => setIsReportProblemModalOpen(true)}
                {...stylex.props(styles.iconButton)}
              >
                <SBIcon fill={false} icon="feedback" style={styles.icon} />
              </button>
              <div {...stylex.props(auto, styles.separator)}></div>
            </>
          ) : null}
          <CopyButton
            icon={
              platform === "ios" || platform === "macos" ? "ios_share" : "share"
            }
            textToCopy={shareUrl}
          />
          <ShareButtons translationKey="product.share" url={shareUrl} />
          {showFavoriteButton ? (
            <>
              <div {...stylex.props(styles.separator)}></div>
              <div {...stylex.props(styles.favorite)}>
                <SBFavoriteIcon
                  favorite={hasViewerFavorited}
                  loading={isInFlightFavorite || isInFlightUnfavorite}
                  number={number ?? ""}
                  style={styles.favorite}
                  title={title ?? ""}
                  onClick={handleClickFavorite}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <ReportProductIssueModal
        isOpen={isReportProblemModalOpen}
        queryKey={data}
        setOpen={setIsReportProblemModalOpen}
        onSuccess={() => {
          setIsReportProblemModalOpen(false);
        }}
      />
    </>
  );
};

const styles = stylex.create({
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  extraButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 8,
  },
  favorite: {
    marginLeft: 12,
    marginRight: 12,
  },
  icon: {
    color: colors.color,
  },
  iconButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginLeft: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 32,
    },
    padding: 0,
  },
  icons: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
  },
  separator: {
    borderBottomWidth: 0,
    borderColor: colors.colorMuted,
    borderLeftWidth: 0,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderTopWidth: 0,
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "block",
    },
    marginLeft: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 32,
    },
  },
});

export default ProductDetailCarousel;
