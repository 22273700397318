/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ReviewOffersCompletedCard_offer$key } from "src/types/__generated__/ReviewOffersCompletedCard_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: ReviewOffersCompletedCard_offer$key;
}>;

const ReviewOffersCompletedCard = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ReviewOffersCompletedCard_offer on Offer {
        id
        price
        currency
        status
        toUserId
        expiresAt
        reason
      }
    `,
    queryKey,
  );

  const offer = data;
  const offerPrice = offer?.price;
  const currency = offer?.currency;
  const status = offer?.status ?? "";
  const reason = offer?.reason ?? "";

  let content = null;
  content = (
    <div {...stylex.props(auto, styles.cardHeader)}>
      <SBHeading level={HeadingLevel.H2}>
        {offerPrice} {currency}
      </SBHeading>

      {reason === "FAVORITE" ? (
        <SBParagraph style={styles.offerText}>
          {t("review-offers.offer.reason.favorite")}
        </SBParagraph>
      ) : (
        <SBParagraph style={styles.offerText}>
          {t("review-offers.offer.text")}
        </SBParagraph>
      )}
      <SBParagraph style={styles.offerCompletedStatus}>
        {t(`offer.status.${kebabCase(status)}`)}
      </SBParagraph>
    </div>
  );

  return (
    <div {...stylex.props(auto, styles.card)}>
      <div>{content}</div>
    </div>
  );
};

const styles = stylex.create({
  card: {
    backgroundColor: colors.backgroundColor,
    borderColor: colors.color,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "block",
    marginBottom: 16,
    padding: 16,
    width: "90%",
  },
  cardHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  offerCompletedStatus: {
    color: colors.colorEmphasis,
    marginBottom: 0,
    marginTop: 4,
  },
  offerText: {
    marginBottom: 0,
    marginTop: 4,
  },
});

export default ReviewOffersCompletedCard;
