/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

export const isFirstHalfAverageGreater = (data: number[]): boolean => {
  if (data.length < 2) {
    return false;
  }

  const mid = Math.floor(data.length / 2);
  const firstHalf = data.slice(0, mid);
  const secondHalf = data.slice(mid);

  const firstHalfAvg =
    firstHalf.reduce((sum, num) => sum + num, 0) / firstHalf.length;
  const secondHalfAvg =
    secondHalf.reduce((sum, num) => sum + num, 0) / secondHalf.length;

  if (firstHalfAvg > secondHalfAvg) {
    return true;
  } else if (firstHalfAvg < secondHalfAvg) {
    return false;
  }
  return false;
};
