/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import HyperDX from "@hyperdx/browser";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { lazy, useContext, useEffect } from "react";
import { loadQuery } from "react-relay";
import {
  createBrowserRouter,
  createRoutesFromElements,
  NavigationType,
  Route,
  RouterProvider,
} from "react-router-dom";

import { OrderDetailContext } from "src/app/context/order-detail";
import { PageContext } from "src/app/context/page";
import Page from "src/app/layouts/Page";
import {
  AccountAddressesQuery,
  AccountAuctionBidsQuery,
  AccountFavoritesQuery,
  AccountInvoiceDetailQuery,
  AccountInvoicesQuery,
  AccountOffersQuery,
  AccountOrdersQuery,
  AccountPasswordQuery,
  AccountPersonalInformationQuery,
  AccountWatchedLotsQuery,
  ArticleDetailQuery,
  ArticlesQuery,
  AuctionLotQuery,
  AuctionQuery,
  AuctionSearchQuery,
  CareersQuery,
  ContactUsQuery,
  FaqsQuery,
  HomeQuery,
  InvoiceQuery,
  PortfolioQuery,
  PrivacyQuery,
  ProductDetailQuery,
  SellerAccountLabelSizeQuery,
  SellerAccountNeedToShipQuery,
  SellerAccountOfferSettingsQuery,
  SellerAccountOffersQuery,
  SellerAccountPayoutPreferencesQuery,
  SellerAccountShipFromAddressQuery,
  SellerAccountSoldHistoryQuery,
  SellerAccountStatsQuery,
  SellerAccountStoreSettingsQuery,
  SellerAccountVacationModeQuery,
  ShopQuery,
  TermsQuery,
  WhyUseShortboxedQuery,
} from "src/app/router/Queries";

import { UserContext } from "../context/user";
import RelayEnvironment from "./RelayEnvironment";

const AccountInvoiceDetail = lazy(
  () => import("src/app/pages/account-invoice-detail/AccountInvoiceDetail"),
);

const Signup = lazy(() => import("src/app/pages/signup/Signup"));
const SignupKiosk = lazy(() => import("src/app/pages/signup/SignupKiosk"));
const Home = lazy(() => import("src/app/pages/home/Home"));
const AccountPersonalInformation = lazy(
  () => import("src/app/pages/account/general/AccountPersonalInformation"),
);
const AccountPassword = lazy(
  () => import("src/app/pages/account/password/AccountPassword"),
);
const AccountAddresses = lazy(
  () => import("src/app/pages/account/addresses/AccountAddresses"),
);
const AccountOrders = lazy(
  () => import("src/app/pages/account/orders/AccountOrders"),
);
const AccountInvoices = lazy(
  () => import("src/app/pages/account/invoices/AccountInvoices"),
);
const AccountOffers = lazy(
  () => import("src/app/pages/account/offers/AccountOffers"),
);
const AccountFavorites = lazy(
  () => import("src/app/pages/account/favorites/AccountFavorites"),
);
const AccountWatchedLots = lazy(
  () => import("src/app/pages/account/watched-lots/AccountWatchedLots"),
);
const AccountAuctionBids = lazy(
  () => import("src/app/pages/account/auction-bids/AccountAuctionBids"),
);
const Portfolio = lazy(() => import("src/app/pages/portfolio/Portfolio"));
const ComicIndexRoot = lazy(
  () => import("src/app/pages/comic-index/ComicIndexRoot"),
);
const ComicIndexIssue = lazy(
  () => import("src/app/pages/comic-index/ComicIndexIssue"),
);
const ComicIndexTitle = lazy(
  () => import("src/app/pages/comic-index/ComicIndexTitle"),
);
const ResetPassword = lazy(
  () => import("src/app/pages/reset-password/ResetPassword"),
);
const Shop = lazy(() => import("src/app/pages/shop/Shop"));
const ProductDetail = lazy(
  () => import("src/app/pages/product-detail/ProductDetail"),
);
const Invoice = lazy(() => import("src/app/pages/invoice/Invoice"));
const Seller = lazy(() => import("src/app/pages/seller/Seller"));
const Articles = lazy(() => import("src/app/pages/articles/Articles"));
const WhyUseShortboxed = lazy(
  () => import("src/app/pages/why-use-shortboxed/WhyUseShortboxed"),
);
const ContactUs = lazy(() => import("src/app/pages/contact-us/ContactUs"));
const Careers = lazy(() => import("src/app/pages/careers/Careers"));
const Terms = lazy(() => import("src/app/pages/terms/Terms"));
const Privacy = lazy(() => import("src/app/pages/privacy/Privacy"));
const AuctionSearch = lazy(
  () => import("src/app/pages/auction-search/AuctionSearch"),
);
const Auction = lazy(() => import("src/app/pages/auction/Auction"));
const AuctionLot = lazy(() => import("src/app/pages/auction-lot/AuctionLot"));
const Faqs = lazy(() => import("src/app/pages/faqs/Faqs"));
const ArticleDetail = lazy(
  () => import("src/app/pages/article-detail/ArticleDetail"),
);
const NotFound = lazy(() => import("src/app/pages/not-found/NotFound"));
const Login = lazy(() => import("src/app/pages/login/Login"));
const Download = lazy(() => import("src/app/pages/download/Download"));
const Deletion = lazy(() => import("src/app/pages/deletion/Deletion"));

const SellerAccountSoldHistory = lazy(
  () =>
    import(
      "src/app/pages/seller-account/sold-history/SellerAccountSoldHistory"
    ),
);

const SellerAccountOffers = lazy(
  () => import("src/app/pages/seller-account/offers/SellerAccountOffers"),
);

const SellerAccountStats = lazy(
  () => import("src/app/pages/seller-account/stats/SellerAccountStats"),
);

const SellerAccountStoreSettings = lazy(
  () =>
    import(
      "src/app/pages/seller-account/store-settings/SellerAccountStoreSettings"
    ),
);

const SellerAccountOfferSettings = lazy(
  () =>
    import(
      "src/app/pages/seller-account/offer-settings/SellerAccountOfferSettings"
    ),
);

const SellerAccountVacationMode = lazy(
  () =>
    import(
      "src/app/pages/seller-account/vacation-mode/SellerAccountVacationMode"
    ),
);

const SellerAccountShipFromAddress = lazy(
  () =>
    import(
      "src/app/pages/seller-account/ship-from-address/SellerAccountShipFromAddress"
    ),
);

const SellerAccountLabelSize = lazy(
  () =>
    import("src/app/pages/seller-account/label-size/SellerAccountLabelSize"),
);

const SellerAccountNeedToShip = lazy(
  () =>
    import("src/app/pages/seller-account/need-to-ship/SellerAccountNeedToShip"),
);

const SellerAccountPayoutPreferences = lazy(
  () =>
    import(
      "src/app/pages/seller-account/payout-preferences/SellerAccountPayoutPreferences"
    ),
);

export const ARTICLES_PATH = "/blog";
export const ARTICLES_DETAIL_PATH = "/blog/:slug";
export const SHOP_PATH = "/shop";
export const FAQS_PATH = "/faq";
export const AUCTIONS_PATH = "/auctions";
export const DOWNLOAD_PATH = "/download";
export const TERMS_PATH = "/terms";
export const PRIVACY_PATH = "/privacy";
export const PRODUCT_DETAIL_PATH = "/product/:id";
export const SELLER_PATH = "/u/:shopUrl";
export const HOME_PATH = "/";

export const INVOICE_PATH = "/invoice/:id";

export const PORTFOLIO_PATH = "/portfolio";

export const ACCOUNT_PATH = "/account";
export const ACCOUNT_PASSWORD_PATH = "/account/password";
export const ACCOUNT_ORDERS_PATH = "/account/orders";
export const ACCOUNT_ORDER_DETAILS_PATH = "/account/orders/:id";
export const ACCOUNT_FAVORITES_PATH = "/account/favorites";
export const ACCOUNT_OFFERS_PATH = "/account/offers";
export const ACCOUNT_INVOICES_PATH = "/account/invoices";
export const ACCOUNT_INVOICE_DETAILS_PATH = "/account/invoices/:id";
export const ACCOUNT_ADDRESSES_PATH = "/account/addresses";
export const ACCOUNT_WATCHED_LOTS_PATH = "/account/watched-lots";
export const ACCOUNT_AUCTION_BIDS_PATH = "/account/auction-bids";

export const RESET_PASSWORD_PATH = "/reset-password";
export const COMIC_INDEX_BASE_PATH = "/comic-index";
export const COMIC_INDEX_TITLE_PATH = "/comic-index/title/:id";
export const COMIC_INDEX_ISSUE_PATH = "/comic-index/issue/:id";
export const WHY_USE_SHORTBOXED_PATH = "/why-use-shortboxed";
export const CONTACT_US_PATH = "/contact-us";
export const CAREERS_PATH = "/careers";
export const AUCTION_PATH = "/auction/:id";
export const AUCTION_LOT_PATH = "/auction-lot/:id";
export const SIGNUP_PATH = "/signup";
export const SIGNUP_KIOSK_PATH = "/signup/kiosk";
export const LOGIN_PATH = "/login";
export const DELETION_PATH = "/deletion";

export const SELLER_ACCOUNT_PATH = "/seller-account";
export const SELLER_ACCOUNT_SOLD_HISTORY_DETAILS_PATH =
  "/seller-account/sold-history/:id";
export const SELLER_ACCOUNT_SOLD_HISTORY_PATH = "/seller-account/sold-history";
export const SELLER_ACCOUNT_STATS_PATH = "/seller-account/stats";
export const SELLER_ACCOUNT_STORE_SETTINGS_PATH =
  "/seller-account/store-settings";
export const SELLER_ACCOUNT_OFFER_SETTINGS_PATH =
  "/seller-account/offer-settings";
export const SELLER_ACCOUNT_VACATION_MODE_PATH =
  "/seller-account/vacation-mode";
export const SELLER_ACCOUNT_SHIP_FROM_ADDRESS_PATH =
  "/seller-account/ship-from";
export const SELLER_ACCOUNT_LABEL_SIZE_PATH = "/seller-account/label-size";
export const SELLER_ACCOUNT_NEED_TO_SHIP_PATH = "/seller-account/need-to-ship";
export const SELLER_ACCOUNT_PAYOUT_PREFERENCES_PATH =
  "/seller-account/payout-preferences";

enum Vertical {
  COMIC = "COMIC",
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <Page>
            <Home />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            HomeQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={HOME_PATH}
      />
      <Route
        element={
          <Page>
            <ComicIndexRoot />
          </Page>
        }
        path={COMIC_INDEX_BASE_PATH}
      />
      <Route
        element={
          <Page>
            <ComicIndexTitle />
          </Page>
        }
        path={COMIC_INDEX_TITLE_PATH}
      />
      <Route
        element={
          <Page>
            <ComicIndexIssue />
          </Page>
        }
        path={COMIC_INDEX_ISSUE_PATH}
      />
      <Route
        element={
          <Page>
            <Auction />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AuctionQuery,
            {
              id: params.id,
            },
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={AUCTION_PATH}
      />
      <Route
        element={
          <Page>
            <AuctionLot />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AuctionLotQuery,
            {
              id: params.id,
            },
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={AUCTION_LOT_PATH}
      />
      <Route
        element={
          <Page headless={true}>
            <Signup />
          </Page>
        }
        path={SIGNUP_PATH}
      />
      <Route
        element={
          <Page headless={true}>
            <SignupKiosk />
          </Page>
        }
        path={SIGNUP_KIOSK_PATH}
      />
      <Route
        element={
          <Page>
            <AccountPersonalInformation />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountPersonalInformationQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_PATH}
      />
      <Route
        element={
          <Page>
            <AccountPassword />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountPasswordQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_PASSWORD_PATH}
      />
      <Route
        element={
          <Page>
            <AccountFavorites />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountFavoritesQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_FAVORITES_PATH}
      />
      <Route
        element={
          <Page>
            <AccountInvoiceDetail />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountInvoiceDetailQuery,
            { id: parseInt(params.id ?? "", 10) },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_INVOICE_DETAILS_PATH}
      />
      <Route
        element={
          <Page>
            <AccountOrders />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountOrdersQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_ORDERS_PATH}
      />
      <Route
        element={
          <Page>
            <AccountOrders />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountOrdersQuery,
            {},
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_ORDER_DETAILS_PATH}
      />
      <Route
        element={
          <Page>
            <AccountAddresses />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountAddressesQuery,
            {},
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_ADDRESSES_PATH}
      />
      <Route
        element={
          <Page>
            <AccountOffers />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountOffersQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_OFFERS_PATH}
      />
      <Route
        element={
          <Page>
            <AccountInvoices />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountInvoicesQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_INVOICES_PATH}
      />
      <Route
        element={
          <Page>
            <AccountWatchedLots />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountWatchedLotsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_WATCHED_LOTS_PATH}
      />
      <Route
        element={
          <Page>
            <AccountAuctionBids />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountAuctionBidsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_AUCTION_BIDS_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountOffers />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountOffersQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountSoldHistory />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountSoldHistoryQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_SOLD_HISTORY_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountStats />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountStatsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_STATS_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountStoreSettings />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountStoreSettingsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_STORE_SETTINGS_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountOfferSettings />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountOfferSettingsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_OFFER_SETTINGS_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountVacationMode />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountVacationModeQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_VACATION_MODE_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountShipFromAddress />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountShipFromAddressQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_SHIP_FROM_ADDRESS_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountLabelSize />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountLabelSizeQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_LABEL_SIZE_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountNeedToShip />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountNeedToShipQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_NEED_TO_SHIP_PATH}
      />
      <Route
        element={
          <Page>
            <SellerAccountPayoutPreferences />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            SellerAccountPayoutPreferencesQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_ACCOUNT_PAYOUT_PREFERENCES_PATH}
      />
      <Route
        element={
          <Page>
            <Portfolio />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            PortfolioQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={PORTFOLIO_PATH}
      />
      <Route
        element={
          <Page>
            <ResetPassword />
          </Page>
        }
        path={RESET_PASSWORD_PATH}
      />
      <Route
        element={
          <Page headless={true}>
            <Login />
          </Page>
        }
        path={LOGIN_PATH}
      />
      <Route
        element={
          <Page>
            <Download />
          </Page>
        }
        path={DOWNLOAD_PATH}
      />
      <Route
        element={
          <Page>
            <Seller />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            HomeQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_PATH}
      />
      <Route
        element={
          <Page>
            <Shop />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ShopQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SHOP_PATH}
      />
      <Route
        element={
          <Page>
            <Shop />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ShopQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={SHOP_PATH}
      />
      <Route
        element={
          <Page>
            <ProductDetail />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ProductDetailQuery,
            {
              id: params.id,
              productId: params.id,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={PRODUCT_DETAIL_PATH}
      />
      <Route
        element={
          <Page>
            <Invoice />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            InvoiceQuery,
            {
              id: params.id,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={INVOICE_PATH}
      />
      <Route
        element={
          <Page>
            <Articles />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ArticlesQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ARTICLES_PATH}
      />
      <Route
        element={
          <Page>
            <ArticleDetail />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ArticleDetailQuery,
            {
              slug: params.slug,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ARTICLES_DETAIL_PATH}
      />
      <Route
        element={
          <Page>
            <AuctionSearch />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AuctionSearchQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={AUCTIONS_PATH}
      />
      <Route
        element={
          <Page>
            <Faqs />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            FaqsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={FAQS_PATH}
      />
      <Route
        element={
          <Page>
            <Privacy />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            PrivacyQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={PRIVACY_PATH}
      />
      <Route
        element={
          <Page>
            <Terms />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            TermsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={TERMS_PATH}
      />
      <Route
        element={
          <Page>
            <Careers />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            CareersQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={CAREERS_PATH}
      />
      <Route
        element={
          <Page>
            <WhyUseShortboxed />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            WhyUseShortboxedQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={WHY_USE_SHORTBOXED_PATH}
      />
      <Route
        element={
          <Page>
            <ContactUs />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ContactUsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={CONTACT_US_PATH}
      />

      <Route
        element={
          <Page>
            <Deletion />
          </Page>
        }
        path={DELETION_PATH}
      />
      <Route
        element={
          <Page>
            <NotFound />
          </Page>
        }
        path="*"
      />
    </>,
  ),
);

const styles = stylex.create({
  root: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: "100%",
  },
});

const Router = (): React.ReactNode => {
  const pageContext = useContext(PageContext);
  const { setOrderId } = useContext(OrderDetailContext);
  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const title = pageContext?.title;

  useEffect(() => {
    if (userId) {
      HyperDX.setGlobalAttributes({
        userId,
      });
    }
  }, [userId]);

  useEffect(() => {
    if (title && title !== document.title) {
      document.title = title;
      const ogTitle = document.querySelector("meta[property='og:title']");
      if (ogTitle) {
        ogTitle.setAttribute("content", title);
      }
    }
  }, [title]);

  useEffect(() => {
    router.subscribe((state) => {
      if (state.historyAction === NavigationType.Pop) {
        if (
          !state.location.pathname.startsWith(
            ACCOUNT_ORDER_DETAILS_PATH.replace(":id", ""),
          )
        ) {
          setOrderId(null);
        }
      }
    });
  }, [setOrderId]);

  return (
    <div {...stylex.props(styles.root)}>
      <RouterProvider
        future={{
          // eslint-disable-next-line camelcase
          v7_startTransition: true,
        }}
        router={router}
      />
    </div>
  );
};

export default Router;
